import React, { useState, useContext } from 'react'
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 
import LogoWhiteNoAnim from '../../assets/scss/black-dashboard-react/panther/logo-white-none-anim.svg'; 

import LogoBlack from '../../assets/scss/black-dashboard-react/panther/logo.svg'; 
import LogoBlackNoAnim from '../../assets/scss/black-dashboard-react/panther/logoNoAnim.svg'; 

import AuthContext from '../../context/autenticacion/authContext'



import DatosPersonales from './DatosPersonales'
import DepositosPanama from '../DepositosView/DepositosPanama'
import DepositosInternacional from '../DepositosView/DepositosInternacional'

import WindowsDatos from '../ActualizarProfileView/WindowsDatos'

import PasswordReset from '../ActualizarProfileView/PasswordReset'

import CentroVerificacion from '../ActualizarProfileView/CentroVerificacion'

import CuentasBancarias from '../ActualizarProfileView/CuentasBancarias'



const Actualizar = () => {

    const authContext = useContext(AuthContext);
    const {jimf_theme, usuario, status} = authContext;


    const [ layerout, setLayerOut] =useState({
        todos_mis_depositos:true,
        transferencia_nacional:false,
        transferencia_internacional:false,
    })


    const TodosDepositosLay = () => {
        setLayerOut({
            todos_mis_depositos:true,
            transferencia_nacional:false,
            transferencia_internacional:false,
            centro_verficicacion:false,    
        })
    }

    const TransferenciaNacionalLay = () => {
        setLayerOut({
            todos_mis_depositos:false,
            transferencia_nacional:true,
            transferencia_internacional:false,  
            centro_verficicacion:false,  
        })
    }
    
    const TransferenciaInterNacionalLay = () => {
        setLayerOut({
            todos_mis_depositos:false,
            transferencia_nacional:false,
            transferencia_internacional:true,
            centro_verficicacion:false,  
        })
    }

    const CentroKYC = () => {

        setLayerOut({
            todos_mis_depositos:false,
            transferencia_nacional:false,
            transferencia_internacional:false,
            centro_verficicacion:true,  
        })

    }




    const { todos_mis_depositos, transferencia_nacional, transferencia_internacional, centro_verficicacion } = layerout

    const [completar, setCompletar] = useState(false);




    return (  


        <>

        
            <div className="datos-jimf">
                    <div className={`${jimf_theme === "white" ? 'header-deposito-white':'header-deposito' }`}
                            >
                                    <div className=" box-logo-white" >

                                            {jimf_theme === "white" ? 
                                            <>

                                            <img src={LogoBlackNoAnim} className=" logo-size-2 mobile-responsive-jimf" />
                                            <img src={LogoBlack} className=" logo-size-2 desktop-responsive-jimf" />
                                            
                                            </> :
                                            <>
                                            <img src={LogoWhiteNoAnim} className=" logo-size-2 mobile-responsive-jimf" />
                                            <img src={LogoWhite} className=" logo-size-2 desktop-responsive-jimf" />
                                            </>

                                            }
                                    </div>
                                <div>
                                    <h2>Perfil de <strong className="usuario-fill">{usuario.name}</strong></h2>
                                    <p>Plataforma de Crecimiento Financiero</p>
                                    <span> <strong>www.</strong>panther7<strong>.com</strong> </span>
                                </div>
                    </div>

                    { !completar ? <>

                    <div className={`${jimf_theme === "white" ? 'body-deposito-white':'body-deposito' }`}>

                                <div 
                                className={`${jimf_theme === "white" ? 'ultra-deposito-jimfwhite':'ultra-deposito-jimf' }`}
                                >

                                    <button className={`
                                    ${todos_mis_depositos === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                                    ${todos_mis_depositos === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                                    
                                    `
                                    } 
                                            onClick={TodosDepositosLay}>
                                        Mis Datos Personales
                                    </button>

                                    <button className={`
                                        ${transferencia_internacional === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                                        ${transferencia_internacional === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                                    ` } 
                                    onClick={TransferenciaInterNacionalLay}>
                                        Privacidad & Seguridad 
                                    </button>

                                    {
                                        status === 3 ?
                                        null
                                          : 
                                        <> 

                                        <button className={`
                                                ${centro_verficicacion === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                                                ${centro_verficicacion === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                                                `} 
                                                onClick={CentroKYC}>
                                                    Centro de verificación
                                        </button>
                                        
                                        </>




                                    }

                                    

                                    <button className={`
                                    ${transferencia_nacional === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                                    ${transferencia_nacional === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                                    `} 
                                    onClick={TransferenciaNacionalLay}>
                                        Cuentas Bancarias 
                                    </button>

                                    

                                </div>



                                <div className="ultra-deposito-jimf-info">

                                {todos_mis_depositos === true ?
                                <DatosPersonales
                                usuario={usuario}
                                jimf_theme={jimf_theme}
                                setCompletar={setCompletar}
                                
                                /> : null
                                } 

                                {transferencia_internacional === true ?
                                <PasswordReset
                                    jimf_theme={jimf_theme}
                                /> : null
                                } 

                                {transferencia_nacional === true ?
                                <CuentasBancarias
                                    jimf_theme={jimf_theme}
                                /> : null
                                } 


                                {centro_verficicacion === true ?
                                <CentroVerificacion
                                    jimf_theme={jimf_theme}
                                /> : null
                                } 


                                



                                    
                                
                                    
                                </div>

                            </div>


                    </> :
                    <WindowsDatos
                                                setCompletar={setCompletar}
                        />
                    }






                    





                    <div className={`${jimf_theme === "white" ? 'ultradepositowhite':'ultradeposito' }`}>

                    <div className={`${jimf_theme === "white" ? 'ultradeposito-son-white':'ultradeposito-son' }`}>
                    </div>

                    </div>

                    </div>
        
      
        

        

        



        </>




    );
}
 
export default Actualizar;