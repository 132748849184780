import React, { useState, useEffect, useContext } from 'react';
import clienteAxios from '../../config/axios';


import { Route, Redirect, Link } from 'react-router-dom';

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap"; 



const Activation = (props) => {  

  

  const [mensaje, guardarMensaje] = useState({
    message:""
})

const [ succ, guardarSuc] = useState({
  respuesta:""
})

    
    
    useEffect(() => {
        const consultarApiAct = async () => { 


          try {
            const url = `/api/client/activation/${props.match.params.token}`
  
            
            const resultado = await clienteAxios.get(url); 
            
            
            
            guardarSuc(resultado.data.success);
            console.log(resultado.data.message);
            guardarMensaje(resultado.data.message);
            
            

        } catch (error) {
          guardarSuc(error.response.data.success);
          guardarMensaje(error.response.data.message)

        } 
         
        }
  
        consultarApiAct();
  
    },[]);
    return (  





            <div className="container " >
                         <div className="row d-flex justify-content-center verify-box"> 
     
                         { succ === "false" ?
      
                        
                          <Col md="8" className="deposito-col-box"> 
                              <Card>
                                <CardHeader>
                                  <h3 className="title display-4 mb-0">Upps Lo sentimos!</h3>
                                  <hr className="my-3 border-info"/>
                                </CardHeader>
                                <CardBody className="pb-2">
                                    <Row>
                                      <Col  md="12">
                                        
                                        <h2 className="my-1">No hemos podido validar su correo electrónico </h2>
                                        {/* <p> vuelve a intentar </p> */}
                                    
                      
                                      </Col>
                                  
                                      
                                    </Row> 
                                </CardBody>

                                <CardFooter>
                                <Link to="/login">
                                      <button  className="btn btn-primario btn-block btn-panther-power mt-0"
                                              
                                      >
                                        Iniciar Sesion Ahora!
                                      </button>
                                      </Link>
                                  </CardFooter>
                              
                              </Card>
                            </Col> 
                          
                          : null} 



                          
                         { succ === "true" ?
      
                        
                            <Col md="8" className="deposito-col-box"> 
                                <Card>
                                  <CardHeader>
                                    <h3 className="title display-4 mb-0">Felicitaciones!</h3>
                                    <hr className="my-3 border-info"/>
                                  </CardHeader>
                                  <CardBody className="pb-2">
                                      <Row>
                                        <Col  md="12">
                                          <h2 className="my-1">Su correo electrónico ha sido validado exitosamente.</h2>
                                          {/* <p> Estamos muy contentos de tenerte con nosotros</p> */}
                                          
                                      
                        
                                        </Col>
                                    
                                        
                                      </Row> 
                                  </CardBody>
                                  <CardFooter>
                                  <Link  to="/login">
                                      <button  className="btn btn-primario btn-block btn-panther-power mt-0"
                                              
                                      
                                      >
                                        Iniciar Sesion Ahora!
                                      </button>
                                      
                                </Link>
                                  </CardFooter>
                                </Card>
                              </Col> 
                            
                            : null}
                                            
        
        
        
        </div> 

                
            </div>


    );
}
 
export default Activation;