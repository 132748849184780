import React, {useState} from 'react'
import RetiroModal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom'; 
import Spinner from '../../components/spinners/Spinner'
import NumberFormat from 'react-number-format';
import AuthContext from '../../context/autenticacion/authContext'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    Form,
    Input,
    Row,
    Col,
    Container,
    FormGroup
    
  } from "reactstrap"; 
  






const RetirosView = ({open,x_resta, retiro, onSubmitTransferencia, elguardar, modalInfo, handleClose, elGuardarCargando, guardarRetiro, onChange, demo_status,makeStyles,modalStyle, usuario, x_final }) => {



    const [ objetotasas, guardarObjeto] = useState({
        monto_flat: usuario.tasas.monto_flat,
        monto_porcentual: usuario.tasas.monto_porcentual
    });



function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}
  


//     const [tasa, guardarTaza] = useState({
  
//         monto_flat:'',
//         monto_porcentual:'',
        
//         });   

    
// const onChangeTasa = e => {
//     // voy a colocar guardar usuario en el state

  
//     guardarTaza({
//         ...tasa,
//         [e.target.name] : e.target.value
        
//     }) 
    
  
    
//   } 



    return (  


        

        

            <RetiroModal 
                className="bg-modal-retiro retiro-0"
                open={open}

            >

                <div className={` modal-power-up-profile retiro-modal-dinamic`}>

                <form onSubmit={onSubmitTransferencia}>
                            
                            
                                <div className="mb-5">
                                  
                                  {retiro.type === "1" ? <p className="text-fix-retiros-modal">Transferencia nacional</p> : null}  
                                  {retiro.type === "2" ? <p className="text-fix-retiros-modal">Transferencia internacional</p> : null}
                                  {retiro.type === "3" ? <p className="text-fix-retiros-modal">Transferencia remesa</p> : null}
                                    <h5 className="title display-4 mb-0 text-center"> Solicitud de retiro a {modalInfo.bank_client} </h5>
                                  
                             
                                </div>
                              
                                {/* <h3 className="title display-4 text-center mis-inversiones-h5 ">Transferencia ACH a  {modalInfo.bank_client} </h3> */}
                                
                            

                                        <div className="container-fluid row d-flex seudo-hermano">
                                                        <div  className="col-4">
                                                                <div className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario"  onClick={() => (  
                                                                                                                                handleClose(),
                                                                                                                                elGuardarCargando(false),
                                                                                                                                guardarRetiro({
                                                                ...retiro, amount:'',
                                                                
                                                                })

                                                                )}>
                                                                    
                                                                    
                                                                                            <div className="cancel-btn-panther text-fix-btn"> 
                                                                                                <i className="tim-icons icon-simple-remove"/>
                                                                                            </div>
                                                                                        
                                                                </div>
                                                        
                                                        </div>
                                        </div>  

                                        
                                <section className="box-retiro">

                                        

                                  <div className="box-retiro-info">
                        
                        
                                            <div className="form-group form-control-deposito-container animation-icon-in-cuatro">
                                                                    { retiro.type === "1" || retiro.type === "2" ?  
                                
                                
                                                                <div className="retiro-2">
                                                                          
                                                                          
                                        
                                                                          <div>
                                                                          <label className="panther-retiro-label">Tipo de cuenta <span className="text-success  ">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                placeholder="Identificacion Destinatario"
                                                                                id="note"
                                                                                name="note"
                                                                                
                                                                                value={modalInfo.bank_type}
                                                                                
                                                                              
                                                                              className=" form-control" required=""/>
                                                                    
                                                                          </div> 

                                                                          

                                                                          <div>
                                                                          <label className="panther-retiro-label">Titular de la cuenta <span className="text-success  ">*</span> </label>
                                                                              <input 
                                                                                type="text"
                                                                                placeholder="Identificacion Destinatario"
                                                                                id="note"
                                                                                name="note"
                                                                                value={modalInfo.bank_client}
                                                                                
                                                                                
                                                                              
                                                                              className=" form-control" required="" />
                                                                          </div>
                                                                      
                                                                      </div>   
                                        :null }
 

                                                { retiro.type === "2" ? 
                                                
                                                <div className="retiro-3">
                                          
                                          

                                                  <div>
                                                    
                                                      <label className="panther-retiro-label">Pais del banco<span className="text-success  ">*</span> </label>
                                                          <input 
                                                              type="text"
                                                              placeholder="e.g. ABCDE12345"
                                                              id="note"
                                                              name="note"
                                                              value={"Panama"}
                                                              
                                                              
                                                          
                                                          className=" form-control" required="" />
                                                  </div> 

                                                  <div>
                                                  <label className="panther-retiro-label">Swift <span className="text-success  ">*</span> </label>
                                                  <input 
                                                      type="text"
                                                      placeholder="e.g. ABCDE12345"
                                                      id="note"
                                                      name="note" 
                                                      
                                                      value={modalInfo.bank_swift}
                                                      
                                                  
                                                  className=" form-control" required="" />
                                                  </div> 


                                          <div>
                                          <label className="panther-retiro-label">Aba <span className="text-success ">*</span> </label>
                                            <input 
                                              type="text"
                                              placeholder="e.g. ABCDE12345"
                                              id="note"
                                              name="note" 
                                              
                                              value={modalInfo.bank_aba}
                                              
                                            
                                            className=" form-control" required="" />
                                          </div> 
                                      </div>  :  null } 



                                      { retiro.type === "1" ? 
                                      
                                      
                                      
                                      <div className="retiro-2"> 

                                          <div>
                                                  <label className="panther-retiro-label">Número de la cuenta<span className="text-success  ">*</span> </label>
                                                      <input 
                                                      type="text"
                                                      placeholder="e.g. Smith Rhodes"
                                                      id="note"
                                                      name="note"
                                                      value={modalInfo.bank_account}
                                                      className=" form-control" 
                                                      required="" 
                                                      onchange="cambiar(this.value)"/>
                                          </div> 

                                          <div>
                                              <label className="panther-retiro-label">Nombre del Banco<span className="text-success  ">*</span> </label>
                                              <input 
                                              type="text"
                                              placeholder="e.g. ABCDE12345"
                                              id="note"
                                              name="note"
                                              value={modalInfo.bank_name}
                                              className=" form-control" 
                                              required="" />

                                          </div>  

                                          
                                          

                                      {/*                                     
                                          <div >
                                                    
                                                    <label className="panther-retiro-label">Pais del banco<span className="text-success  ">*</span> </label>
                                                        <input 
                                                          type="text"
                                                          placeholder="e.g. ABCDE12345"
                                                          id="note"
                                                          name="note"
                                                          value={"Panama"}
                                                          
                                                          
                                                        
                                                        className=" form-control" required="" />
                                          </div>  */}


                                      </div>  :  null } 


                                     { retiro.type === "3" ? 
 
 
 


                                                    
                                                    <Row className="justify-content-center d-flex">  

                                                    <Col  md="12">
                                                                    <label className="mt-2 tipo-fix-font">Selecciona el tipo de envio <span className="text-danger">*</span></label>
                                                                  <select  className="custom-select form-control "  
                                                                          id="ventanilla"
                                                                          name="ventanilla"
                                                                          onChange={onChange}
                                                                          value={modalInfo.ventanilla} 
                                                                  
                                                                  >
                                                                
                                                                  
                                                                  
                                                                  <option value="SI" >Retiro en taquilla</option>
                                                                  <option value="NO">Deposito a cuenta Bancaria </option>
                                                                  
                                                                  
                                                                  
                                                              
                                                                </select>
                                                                  </Col>


                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Nombres <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.bank_client
                                                                          }
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col> 

                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Apellidos <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.bank_apellidos}
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col> 

                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">{modalInfo.tipo_identificacion} <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.identificacion}
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col>  

                                                                    
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Pais <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.pais}
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col>   


                                                                    
                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Provincia <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.provincia}
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col>  





                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Ciudad <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          
                                                                          id="note"
                                                                          name="note"
                                                                          
                                                                          value={modalInfo.ciudad}
                                                                          
                                                                        
                                                                        className=" form-control" required=""/>
                                                              
                                                                    </Col>  
                                                                    
                                                                  

                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">
                                                                        Dirección 
                                                                        <span className="text-success  mt-5">*</span> 
                                                                      </label>

                                                                        <input 
                                                                          type="text"    
                                                                          id="note"
                                                                          name="note"
                                                                          value={modalInfo.direccion_cliente}
                                                                          className=" form-control" required=""
                                                                          />
                                                              
                                                                    </Col>  




                                                                    <Col xs="3" sm="3" md="3" lg="3" xl="3">
                                                                    <label className="panther-retiro-label">Telefono 
                                                                    <span className="text-success  mt-5">*</span> </label>
                                                                        <input 
                                                                          type="text"
                                                                          id="note"
                                                                          name="note"
                                                                          value={modalInfo.telefono }
                                                                          className=" form-control" 
                                                                          required=""/>
                                                              
                                                                    </Col>  
                                                                        </Row>  :  null }
                                                                                          </div> 



                                                                        
                                                                          


                                                                                                
                                                                                            

                                                                            

                                                                          </div>  



                                                                          {/* <div className="box-retiro-info transparent-bg-retiro-border"> */}
                                                                            <div className="cantidad">



                                        <div className="retiro-1">
                                     

                                       <div className="monto-dolares "> 
                                       <span className="height-11">USD</span>
                                            <input type="number" className="deposito-form-control-props-input  input-monto-modal "   
                                                      placeholder="Ingrese la cantidad..."
                                                      id="amount"
                                                      name="amount"
                                                      min={20}

                                                      
                                                      onChange={onChange}
                                                      
                                                      value={modalInfo.amount} 
                                                      required                                                      
                                                      />
                                        </div> 
                                    </div> 

                                    <div className="calculo-retiro">

                                                        <div className="box-calculo-retiro ">


                                                        { retiro.amount ? 
                                                        <>
                                                        <p className="monto-dolares-alerta animation-icon-in-dos montoplus"> 
                                                        <span className="number-retiro-span"> 
                                                        <NumberFormat value={retiro.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> 
                                                        </span> es el monto que va a enviar </p> 
                                                        </>
                                                        
                                                        : 

                                                        <>
                                                        {/* <p className="monto-dolares-alerta animation-icon-in-cuatro montoless mb-1">
                                                            Aún no ha ingresado un monto a enviar
                                                        </p> */}



                                                        </>
                                                        
                                                        } 

                                                        <div className="align-retiro-grid">
                                                        {/* <p>Comision flat  </p>

                                                        <h5>{objetotasas.monto_flat} <span>$</span></h5> */}


                                                        {/* <p>Comision porcentual </p>
                                                        <h5><NumberFormat value={objetotasas.monto_porcentual}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={0} toFixed={2} prefix={''} /> 
                                                             <span></span> <span>%</span> </h5> */}

                                                             <p>Monto a enviar</p>
                                                             <h5 className={retiro.amount === '' || retiro.amount === 0 ? 'opacity-0' :''}> <NumberFormat value={retiro.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </h5>

                                                             <p>Comisión</p>
                                                        <h5  id="x_resta" className={retiro.amount === '' || retiro.amount === 0 ? 'opacity-0' :''}>0</h5>

                                                             <p>total  a recibir</p>

                                                             

                                                        
                                                        
                                                        <h5  id="x_final" className={retiro.amount === '' || retiro.amount === 0 ? 'opacity-0' :''}>0</h5>

                                                        

                                                        </div>

                                                        

                                                        

                                                        

                                                        


                                                        </div>

                                        </div>




                                                 
                                            

                                        </div>



                                                                          </section>

                                                                          <div className="box-retiro-info transparent-bg-retiro-border retiro-1">

                                                                          

                                                                          <h2 className="mb-3 monto-dolaresanimate-raya-04">Condiciones del retiro</h2>
                                                                          

                                                                          <div className="retiro-2">

                                                                          
                                                                            <p>
                                                                                Su retiro se verá reflejado en cuenta en un lapso no mayor de 5 dias hábiles.
                                                                            </p>

                                                                            <p>
                                                                                    El saldo minimo a mantener en cuenta es de $ 50 dolares.
                                                                            </p> 

                                                                            <p>
                                                                                    Se aplicarán una comisión fija de {objetotasas.monto_flat}
                                                                            </p> 

                                                                            <p>
                                                                                    Se aplicarán una comisión porcentual de <NumberFormat value={objetotasas.monto_porcentual}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} decimalScale={0} toFixed={2} prefix={''} /> %
                                                                            </p> 

                                                                            </div>

                                                                            </div>



                                 
                                  { demo_status === "active" ? 

                                  
                                            


                                                     <div className="retiro-1"> 
                                                    Esto es una plataforma de demostración, el saldo y las operaciones no son reales.

                                                    <button className="btn btn-primario btn-block btn-panther-power mt-0"  type="submit" disabled>
                                                    {elguardar}
                                                    </button>
                                                    
                                                    </div>
                                                        

                                            


                                  
                                  :   
                                  
                                  <div className="retiro-1">
                                
                                  
                                        <div > 
                                        

                                        <button className="btn btn-primario btn-block btn-panther-power mt-0"  type="submit" >
                                          {elguardar}
                                        </button>
                                        
                                        </div>
                                            
                            
                                  </div>


                                  
                                  
                                
                                
                                }
                                
                              
                                </form>

                    
                </div>

            </RetiroModal>

        
        



    );
}
 
export default RetirosView;