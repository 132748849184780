import React, {useReducer} from 'react';

/// necesita un estado inicial 

import alertaReducer from './alertaReducer.js';
import alertaContext from './alertaContext.js';


import { MOSTRAR_ALERTA, OCULTAR_ALERTA} from '../../types';


const AlertaState = props => {


    //// se necesita para el use reducer que acabamos de importar 
            const initialState = {
                alerta: null
            }


            // todo esto es use reducer que toma 2 parametros 

            const [state, dispatch] = useReducer(alertaReducer, initialState);

            // funciones 
                //va a tomar 2 parametros mensaje y categoria
                //  en el payload vamos a pasar un objeto
            const mostrarAlerta = (message, categoria) => {
                dispatch ({
                    type: MOSTRAR_ALERTA,
                    payload : {
                        message,
                        categoria
                    }
                });



                setTimeout( () => {
                    dispatch({
                        type: OCULTAR_ALERTA
                    })
                }, 3000 );
            }


        return (
            <alertaContext.Provider
                value={{
                        alerta:state.alerta,
                        mostrarAlerta

                }}
            
            >
                {props.children}
            </alertaContext.Provider>




        )
}


export default AlertaState;