import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../config/axios';
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import Zoom from '@material-ui/core/Zoom';

import axios from 'axios'

import btcimagen from '../assets/img/qr.png'

import AuthContext from '../context/autenticacion/authContext'

import BootstrapTable from 'react-bootstrap-table-next' 

import Spinner from '../components/spinners/Spinner'

import NumberFormat from 'react-number-format';


// aqui vamos a iumportar las imagenes

import imagenes from '../assets/flags/PA.png'
import imagenen from '../assets/flags/USA.png' 


import {ReactComponent as LogoPayPal} from '../logopaypal.svg';

import ModalDireccionesMaster from '../components/Modales/ModalDireccionesMaster';



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";

  
function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
} 



const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));





const Deposito = () => {   


  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  const authContext = useContext(AuthContext);
  const { depositos_cantidad,depositos,TodosDepositos, filename, demo_status } = authContext;  


  const [ cargando, guardarCargando] = useState(false);

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Enviar
    </Fragment> 



  

  
////////////// vamos a jalar el stado

const [ listadepositos , guardarListaDeposito ]= useState([{
  id:'',
  fecha:'',
  note:'',
  currency_tag: '',
  amount:'',
  estado:'',

}]);
 

const [pdfdatos, guardarPdfDatos] = useState({

  file_name:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
  file_name_eng:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
  file_name_pa:'https://panther7.s3.us-east-2.amazonaws.com/banking_transfer.pdf',
  });  



  const [ apiload, guardarApiLoad] = useState(true);

  const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },50);
    
    
  }
  



  // /// aqui vamos a usar el  useContext
  // const authContext = useContext(AuthContext);
  // const { depositList, depositos, retiros } = authContext; 
  
  const [ recargardata, setRecargarData  ] = useState(false);

  const [cantidad, guardarCantidades ] = useState({});


  useEffect((props) => { 


    


    const generarpdf = async () => {

     

      guardarPdfDatos(filename);
      
    } 
    
    
    
    
  



    const consultarApiListaDeDepositos = async () => {
      // const {amount, note, currency_tag, estado, created_at
    
      
      // } = listadepositos; 

      // const respuesta = await clienteAxios.post('/api/client/deposits/list')
        
      guardarListaDeposito(depositos)
      guardarCantidades(depositos_cantidad)
      
    }

    consultarApiListaDeDepositos()

    
    
    
    
  }, [!apiload,depositos_cantidad,depositos,TodosDepositos]) 



  const [mensaje, guardarMensaje] = useState({
    message:""
})

const [deposit, guardarDeposit] = useState({
    
    amount:'',
    type: '',
    file:'',
    note:'',
    bank:'',
    fecha:'',

});  


  

  const consultarApiPaypal = async () => { 

  

   
    const type = 5;
    const  amount =  0 ;

    const paypaldeposit = { type, amount}
    
  
  
    
  
   
  
   try {
        const respuesta = await clienteAxios.post('/api/client/deposits/create',paypaldeposit);
        
        guardarCargando(false)
   } catch (error) {
       
    
    guardarMensaje(error.response.data.message);
    handleOpen();
               
               
   }
  }  
  


const [opendireccion, setOpenDireccion] =useState(false);



const handleOpenDireccion = () => {
    setOpenDireccion(true);
} 

const handleCloseDireccion = () => {
    setOpenDireccion(false);;
}





  const [ modalStyle ] =useState(getModalStyle);
  
    const [open, setOpen] =useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    } 

    const handleClose = () => {
        setOpen(false);;
    }



const [pin, guardarPin ] = useState("");


const [ btnremesas, guardarBtnRemesas] = useState(true);




const [file, setFile] = useState(undefined);  





const [enviardepositobtn, guardarEnviarDepositoBtn] = useState(true);


const consultarApiDepositRemesas = async () => {
  const {
      amount,
      file,note,bank,fecha,type
    } = deposit;  

    const config = {     
      headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
      
      mimeType: 'multipart/form-data',
  } 

  const formData = new FormData();
 

  //formData.append('file',document.getElementById('file').files[0],file);
  formData.append('amount',document.getElementById('amount').value);
  //formData.append('note',document.getElementById('note').value);
  formData.append('type',document.getElementById('type').value);

  //formData.append('bank',document.getElementById('bank').value);
  //formData.append('fecha',document.getElementById('fecha').value);
  

  try {
      const respuesta = await clienteAxios.post('/api/client/deposits/create',formData,config);

            guardarPin(respuesta.data.data.pin)
            
            guardarMensaje(respuesta.data.message)
            
            guardarBtnRemesas(false)
            setRecargarData(true)
            guardarCargando(false)
            
              

             
              
      
  } catch (error) {
      
      
      guardarMensaje(error.response.data.message)
      guardarCargando(false)
              handleOpen()
             
  }




} 




const consultarApiDeposit = async () => {
    const {
        amount,
        file,note,bank,fecha,type
    
      
      } = deposit;  

      const config = {     
        headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
        
        mimeType: 'multipart/form-data',
    } 

    const formData = new FormData();
   
    formData.append('file',document.getElementById('file').files[0],file);
    formData.append('amount',document.getElementById('amount').value);
    // formData.append('note',document.getElementById('note').value);
    formData.append('type',document.getElementById('type').value);

    // formData.append('bank',document.getElementById('bank').value);
    // formData.append('fecha',document.getElementById('fecha').value);
    
 
 
    try {
        const respuesta = await clienteAxios.post('/api/client/deposits/create',formData,config);



              guardarPin(respuesta.data.data.pin)
              guardarMensaje(respuesta.data.message);
              setRecargarData(true);
              guardarCargando(false);

              guardarEnviarDepositoBtn(false);
              
                handleOpen(); 

              
             
        
    } catch (error) {
        
        
        guardarMensaje(error.response.data.message);
        guardarCargando(false);
                handleOpen();
              
    }

  
  
  
} 




function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}



const onChangeIndex = e => {
  var x = parseFloat(document.getElementById("amount").value);
      
  document.getElementById("totalarecibir").innerHTML ="0";
  if (x!="")
    {
        
        var calculo=parseFloat(x);
        
        if (!Number.isNaN(calculo))       document.getElementById("totalarecibir").innerHTML = currencyFormat(calculo);
    }
    else
    document.getElementById("totalarecibir").innerHTML ="0";
}


const onChange = e => {  

  


    // voy a colocar guardar usuario en el state
 
    guardarDeposit({
        ...deposit,
        [e.target.name] : e.target.value
        
    }) 

    setFile(e.target.files)
    

    
} 




const onSubmitRemesas = e => {
  e.preventDefault();
  guardarCargando(true);




  consultarApiDepositRemesas(deposit)

 

}  



////// vamos a hacer el pagamento de paypal

const [paypal, guardarPaypal] = useState('');


const onChangePaypal = e => {  

  


  // voy a colocar guardar usuario en el state

  guardarPaypal({
      ...paypal,
      [e.target.name] : e.target.value
      
  }) 

  
  

  
} 




const onSubmitPaypal = e => {
  e.preventDefault();

  PayPalPay(paypal);



}  



const PayPalPay = async () => {

     
  try {
    const respuestapaypal = await clienteAxios.post(`/api/client/deposits/pdf`);
      
    
    
    
   

    
  } catch (error) {
    
    guardarMensaje(error.response.data.message);
 
  }
} 





const paypallink = `http://54.212.52.47/paypal/pago.htm`









const onSubmit = e => {
    e.preventDefault();

    guardarCargando(true)
  

      consultarApiDeposit(deposit);

   

} 

 

  




  return (
    <>
    <div className="content">
        <Form onSubmit={onSubmit}>
      <div className="row d-flex justify-content-center ">
      {/* <Col md="2">
        </Col> */}
      
        <Col md="12" className="deposito-col-box"> 
          <Card className="mb-1">
            <CardHeader>
              <h3 className="title display-4 mb-0">Nuevo depósito</h3> 

              <Row className="mt-2 d-none">
                  <Col  md="12">
                    {/* <label className="mt-2">Selecciona método de recarga <span className="text-danger">*</span></label> */}
                  <select  className="custom-select form-control "  
                           id="type"
                          name="type"
                          onChange={onChange}
                          value={deposit.type} 
                          onClick={ () => {
                            guardarPin(null)
                            guardarBtnRemesas(true)
                            TodosDepositos()
                            guardarDeposit({
                              ...deposit,
                              amount:'',
                          file:'',
                              })
                          }}

                  
                  >
                
                  
                  <option value="" className="text-center"> Todos mis depósitos </option>
                  <option value="1" className="text-center" selected 
                  onClick={ () => {
                    guardarPin(null)
                    guardarBtnRemesas(true)
                    guardarDeposit({
                      ...deposit,
                      amount:'',
                      file:'',
                      })
                  }} >Transferencia Bancaria Internacional</option>
              <option value="2" className="text-center" >ACH / PANAMÁ</option>  
                  <option value="4" className="text-center" onClick={ () => {
                    guardarPin(null)
                    guardarBtnRemesas(true)
                    guardarDeposit({
                      ...deposit,
                      amount:'',
                      file:'',
                      })
                  }} >ACH / USA </option> 
                  <option value="3" className="text-center" onClick={ () => {
                    guardarPin(null)
                    guardarBtnRemesas(true)
                    guardarDeposit({
                      ...deposit,
                      amount:'',
                      file:'',
                      })
                  }}>Remesa</option>
                  {/* <option value="5" className="text-center" onClick={ () => {
                    guardarPin(null)
                    guardarBtnRemesas(true)
                    guardarDeposit({
                      ...deposit,
                      amount:''
                      })
                  }}>PayPal</option> */}
                    
                  
                  
               
                </select>
                  </Col>
              
                  
                </Row>  



              <hr className="my-3 border-info"/>
              <p className="retiro-type-fix">Seleccione el tipo de depósito a realizar</p>
            </CardHeader>
            <CardBody> 
            {/* onChange={onChange} value={deposit.type}  id="type" name="type" */}
            <div className="grid-container grid-container-depositos" > 

              <div onChange={onChange} value="4" className={deposit.type === "" ? 'active-selection' : '' }   onClick={ () => { guardarDeposit({...deposit, type:'',amount:''})
              TodosDepositos() }} >
              <p >Todos mis depósitos</p> 
              <span>Listado de depósitos </span>


              </div>

              <div value="2"    className={deposit.type === "2" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'2',amount:''})
                guardarPin(null)
                guardarBtnRemesas(true)
                 }}> 
                <i class="tim-icons icon-bank panther-icon-style">
                  </i> <p>ACH / PANAMÁ</p> 
                  
              </div>

              
                
              <div value="1"    className={deposit.type === "1" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'1',amount:''})
                guardarPin(null)
                guardarBtnRemesas(true)
                 }}> 
                <i class="tim-icons icon-bank panther-icon-style">
                  </i> <p>Transferencia bancaria internacional</p> 
                  
              </div>

             

              {/* <div value="3"    className={deposit.type === "3" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'3',amount:''})
                guardarPin(null)
               guardarBtnRemesas(true) }}> 
              <i class="tim-icons icon-bank panther-icon-style"></i> <p>Remesa</p> </div> */}


              {/* <div value="4"    className={deposit.type === "4" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'4',amount:''})
                guardarPin(null)
                guardarBtnRemesas(true) 
                    }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>ACH / USA </p> </div> */}


              {/* <div value="5"    className={deposit.type === "5" ? 'active-selection monto-dolares-dos ' : '' } onClick={ () => { guardarDeposit({...deposit, type:'5',amount:''})
                guardarPin(null)
                guardarBtnRemesas(true) 
                }}> <i class="tim-icons icon-bank panther-icon-style"></i> <p>PayPal</p> </div> */}



              </div> 

              

              {deposit.type === "" ? <p className="text-center pantherplus animation-icon-in-dos"><i class="tim-icons icon-bank"></i> Sus depósitos hechos hasta la fecha </p> : null }
              {deposit.type === "1" ? <p className="text-center pantherplus animation-icon-in-dos"> Para reportar depósitos hechos a la cuenta Panther en el exterior...</p> : null }
              {deposit.type === "3" ? <p className="text-center pantherplus animation-icon-in-dos"> Para reportar depósitos vía remesadora...</p> : null }
              {deposit.type === "2" ? <p className="text-center pantherplus animation-icon-in-dos"> Para reportar depósitos vía ACH / PANAMÁ...</p> : null }
              {deposit.type === "4" ? <p className="text-center pantherplus animation-icon-in-dos"> Para reportar depósitos vía ACH / USA...</p> : null }
              {deposit.type === "5" ? <p className="text-center pantherplus animation-icon-in-dos"> Para hacer depósitos vía PayPal...</p> : null }
             

           
                



 
               
              
            </CardBody>
            <CardFooter className="pb-0 pt-0 d-none">
            <p class="text-muted mt-1 font-fix-deposito-14 ">Comisiones<span class="float-right d-flex align-items-center">
              {/* <del>1.00 USD</del>  */}
              <span class="bg-success text-1 text-white font-weight-500 rounded d-inline-block px-2 line-height-4 ml-2">Gratis</span>
              </span></p> 

              <hr className="my-1 border-info"/> 
              <p class="font-weight-500">El monto será acreditada por su valor integral menos costos bancarios. <span class="text-3 float-right" id="montoretiro"></span></p>
            </CardFooter>
          
          </Card>

        
          

        </Col> 

        {/* <Col md="2">
        </Col>  */} 



        { deposit.type === "" ?  


          <>

          {cantidad > 0 ?<> 



            <Col md="12"> 
        
        <Card className="deposito-bg">
            



          <CardBody>
            
     
     

              <Row>
                  <Col md="12">
                  <div className=" p-3 mt-4 registrar-retiros-lista  ">
                        <h1 className="m-0">Todos tus depósitos </h1>
                       
                        
                        
                       


                        
                      </div>   
                      
                      <div className=" shadow-sm rounded py-3 mb-3 depositos-tabla-box " >


                                                                


                                            <CardBody className="header-inversiones-grid depositos-box-grid" >
                                                                                            
                                                <div className="grid-container-tabla-depositos">

                                                                                              
                                                                                                    
                                                                                            <div className="" data-tooltip="Código de referencia">
                                                                                                  <strong className="line-fix">
                                                                                                  CODIGO
                                                                                                  </strong>
                                                                                                  </div>
                                                                                                  <div className="" data-tooltip="Fecha del depósito"> 
                                                                                                  
                                                                                                  <strong className="line-fix">
                                                                                                    FECHA
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" data-tooltip="Información de depósito"> 

                                                                                                  <strong className="line-fix align-items-flex-start">
                                                                                                    INFORMACION
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" data-tooltip="Moneda">

                                                                                                  <strong className="line-fix">
                                                                                                  MONEDA
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className=""  data-tooltip="Monto "> 
                                                                                                  <strong className="line-fix align-items-flex-end">
                                                                                                  MONTO 
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className=""  data-tooltip="Estado del movimiento">

                                                                                                  <strong className="line-fix">
                                                                                                  ESTADO DEL MOVIMIENTO
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div> 
                                                                            
                                                                                            </div>

                                                                                            
                                            </CardBody> 

                                            
                                            {listadepositos.map(listadeposito => (
                                                                                              <>
                                                          

                                            <CardBody className="header-inversiones-grid depositos-box-grid-body" >
                                                                                            
                                                <div className="grid-container-tabla-depositos animation-op">

                                                                                              
                                                                                                    
                                                                                            <div className="" >
                                                                                                  <strong className="line-fix">
                                                                                                  {listadeposito.id}
                                                                                                  </strong>
                                                                                                  </div>
                                                                                                  <div className="" > 
                                                                                                  
                                                                                                  <strong className="line-fix">
                                                                                                  {listadeposito.fecha}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" > 

                                                                                                  <strong className="line-fix align-items-flex-start">
                                                                                                  {listadeposito.tipo}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" >

                                                                                                  <strong className="line-fix">
                                                                                                  {listadeposito.currency_tag}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" > 
                                                                                                  <strong className="line-fix align-items-flex-end">
                                                                                                  {listadeposito.monto}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" >

                                                                                                  <strong className="line-fix">
                                                                                                  {listadeposito.estado}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div> 
                                                                            
                                                                                            </div>

                                                                                            
                                            </CardBody> 

                                          </>
                                                                                  ) )}

                                </div>


               
                  
                  
                  </Col>
              </Row> 


             
              
           
            
          </CardBody>
        
          
        </Card>
      </Col>             

                          

      </>      :  

      <CardBody className="justify-content-center d-flex">
        <Row className="box-depositos-seleccion-alerta justify-content-center w-100"> 
        <Col md="12" className=" panther-open-titulo-alerta border-panther"> 

        
          {cantidad === 0 ?  <> 
      
              <i className="tim-icons  icon-money-coins animation-icon-in mb-1"/>
                      <h2 className="animation-icon-in-dos mb-3">Sin depósitos</h2>
                        <h3 class="animate-raya-03 mb-2">Todavía no ha hecho ningún depósito</h3>
                        <p className="text-center animation-icon-in-cuatro ">Para depósitar dinero de su cuenta Panther 7, porfavor seleccione el tipo de envío y siga intuitivamente los pasos </p>
                          </> :
                        <Spinner/>
                        
                        }
        </Col>
                    
        </Row>  
        </CardBody>
        
       
      
    }

                
      
    </>
      
      :null} 

        {/* ////// aqui estamos colocando el listado de depositos  */}
       


        
        { deposit.type === "1" ? 
        
      
        <Col md="12"> 
        
        <Card>
         
          <CardBody>
            
     
     

              <Row className="deposito-bg">
                  <Col md="12">
                  <div className="card p-3 mt-4 registrar-deposito card-box-transparent ">
                        <h1 className="m-0">REGISTRAR TRANSFERENCIA INTERNACIONAL </h1>
                       
                        <hr class="my-1 border-info"/>
                        
                       

                        <div className="acc-deposito-container">
                        <div className="term mt-8 mb-4 mt-4">
                          <h5><strong className="">Banco Receptor:</strong> First National Bankers Bank </h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">ABA:</strong> 065403370</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">SWIFT:</strong> FRNAUS44</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Banco Beneficiario:</strong> HomeBank of Arkansas</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Número de Cuenta:</strong> 082902485</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Crédito Adicional a:</strong> ESSENTIAL FINANCIAL</h5>
                          </div> 
                          <div className="term my-1">
                          <h5><strong className=""> Acc#</strong> 42183563 </h5>
                          </div>

                          <div className="term my-1">
                          <h5><strong className="">Referencia de Crédito:</strong> 62364709 </h5>
                          </div>

                          
                            {/* <div className="term mt-3 mb-8">
                            <p className="deposito-text-refer">
                            <strong >Referencia de Crédito:
                          </strong> 62364709 <br className=""/>
                            <span className="red-500-fg">* La colocación del código es obligatoria en campo de observaciones de la transferencia</span>
                            </p>

                          </div>  */}

                        </div> 

                        
                      </div> 


               
                  
                  
                  </Col>
                  <Col md="12" className="justify-content-between d-flex align-items-center text-center my-2 p-5"> 

                      <div className="flags-dad"> 
                            <div className="height-box-fit">
                            <img className="flags" src={imagenes}/>
                            </div>


                      <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name}`} rel="noopener noreferrer" target="_blank" >

                                    
                                    Descarga tus datos bancarios
                                
                                  </a>
                      </div> 
                      <div className="ml-2 flags-dad" >
                      <div className="height-box-fit">
                            <img className="flags" src={imagenen}/>
                            </div>
                      <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name_eng}`} rel="noopener noreferrer" target="_blank" >

                                    
                                    Download your bank data
                                
                                  </a>
                      </div>

                      </Col>

              </Row> 

              <Row> 
              <Col md="12" >
                          <h2 className="display-currency text-cente">
                          <p>Usted está haciendo un deposito con el monto <span className="currency-bold"><NumberFormat value={deposit.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                          </h2>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                    < div className="form-group form-control-deposito-container">
                <label className="deposito-label-between" for="youSend">
                  <p>Monto <span className="text-danger">*</span></p>
                  <p>Elegir Moneda <span className="text-success"> *  </span></p>
                  </label> 
                

                <div className="input-group">
                  <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                  <input type="text" className="deposito-form-control-props-input form-control"   
                  placeholder="Digite el monto"
                  id="amount"
                  name="amount"
                  onChange={onChange}
                  value={deposit.amount} 
                  required
                  
                  />
                  <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                  <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabIndex="-98">
                          <optgroup label="Moneda" className="bg-primary">
                              <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                              <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                          </optgroup>
                        </select>
                  </div>
                </div>
              </div>

                   
                    </FormGroup>
                  
                  </Col>
                </Row>

            
            
         
              <Row>
                <Col md="12">

                <FormGroup>
                      <label>Cargar el comprobante <span className="text-danger">*</span> </label>
                      
                      <div className="custom-file-panther">
                      
                      {/* { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null} */}
                      <label className="panther-input-file " htmlFor="file"> 
                       <i className="tim-icons icon-paper p-2" />  Ejem .JPG .PDF 'Tamaño Maximo del archivo: 4mb'</label>

                      <input type="file"
                          id="file"
                          name="file"
                          onChange={onChange}
                          value={deposit.file} 
                          required
                      className="custom-file-input-panther"/>
                      </div>
                          
                    </FormGroup> 
                </Col>
              </Row>
           
             
            
          </CardBody>


          {
            demo_status === "active" ? 
            <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 Esto es una plataforma de demostración, el saldo y las operaciones no son reales.
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" disabled>
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter> :
          <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" >
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter>

          }


          
          
        </Card>
      </Col> :null}  

      { deposit.type === "2" ? 
        
      
        <Col md="12"> 
        
        <Card>
         
          <CardBody>
            
     
     

              <Row className="deposito-bg">
                  <Col md="12" >
                  <div className="card-box-transparent card p-3 mt-4 registrar-deposito ">
                        <h1 className="m-0">REGISTRAR DEPÓSITO ACH / PANAMÁ</h1>
                       
                        <hr class="my-1 border-info"/>
                        
                       

                        <div className="acc-deposito-container d-none">
                        <div className="term mt-8 mb-4 mt-4">
                          <h5><strong className="">Banco:</strong> Banco BANISTMO </h5>
                          </div>

                        

                     
                        <div className="term my-1">
                          <h5><strong className="">Beneficiario:</strong> COODEM - Cooperativa del Emprendedor</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Número de Cuenta:</strong> 0116676868</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Tipo de Cuenta:</strong> Corriente </h5>
                          </div> 


                          

                          {/* <div className="term mt-3 mb-8">
                            <p className="deposito-text-refer">
                            <strong >Referencia:
                          </strong> 707595540<br className=""/>
                            <span className="red-500-fg">* La Referencia es obligatoria en las observaciones de la transferencia</span>
                            </p>

                          </div> */}

                        </div> 

                        
                      </div> 


               
                  
                  
                  </Col> 
                  <Col md="12" className="justify-content-center  align-items-center text-center my-2 p-5 d-none"> 

                  <div className="flags-dad"> 
                        <div className="height-box-fit">
                        <img className="flags" src={imagenes}/>
                        </div>

                  
                  <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name_pa}`} rel="noopener noreferrer" target="_blank" >
      
                                
                                Descarga tus datos bancarios
                            
                              </a>
                  </div>    
                  </Col>
             
                  
              </Row> 

              <Row>
              <Col md="12" >
                          <h2 className="display-currency text-cente">
                          <p>Usted está haciendo un deposito con el monto <span className="currency-bold"><NumberFormat value={deposit.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                          </h2>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                    
                    < div className="form-group form-control-deposito-container">
                <label className="deposito-label-between" for="youSend">
                  <p>Monto <span className="text-danger">*</span></p>
                  <p>Elegir Moneda <span className="text-success"> *  </span></p>
                  </label> 
                

                <div className="input-group">
                  <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                  <input 
                  type="number"
                   className="deposito-form-control-props-input form-control text-right"   
                  placeholder="Digite el monto"
                  id="amount"
                  name="amount"
                  max="3000"
                  onChange={onChange}
                  value={deposit.amount} 
                  required

                  
                  /> 

              
                  <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                  <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabIndex="-98">
                          <optgroup label="Moneda" className="bg-primary">
                              <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                              <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                          </optgroup>
                        </select>
                  </div>
                </div>
              </div>

                   
                    </FormGroup>
                  
                  </Col> 
                

                </Row>
{/* 
              <Row>
                <Col  md="12" className="mb-3">
                  <label>Referencia <span className="text-danger">*</span> </label>
                <input 
                  type="text"
                  placeholder="Escriba el numero de Referencia"
                  id="note"
                  name="note"
                  onChange={onChange}
                  value={deposit.note} 
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}

              
              {/* <Row>
                <Col  md="12"  className="mb-3">
                  <label>Fecha ACH <span className="text-danger">*</span> </label>
                <input 
                  type="date"
                  id="fecha"
                  name="fecha"
                  onChange={onChange}
                  value={deposit.fecha} 
                  
                  
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}
              {/* <Row>
                <Col  md="12" className="mb-3">
                  <label>Banco Origen <span className="text-danger">*</span> </label>
                <input 
                  type="text"
                  id="bank"
                  name="bank"
                  onChange={onChange}
                  value={deposit.bank} 
                  placeholder="Escriba el nombre del Banco"
                  
                  
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}



{/* 
              <Row>
                <Col  md="12 my-4" >
                  <label>Seleccione el banco origen <span className="text-danger">*</span></label>
                <select 
                  id="bank"
                  name="bank"
                  onChange={onChange}
                  value={deposit.bank} 
                   className="form-control  pointer-cursor">
                <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                <option value="Allbank Corp">Allbank Corp</option>
                      <option value="BAC International Bank, Inc.">BAC International Bank, Inc.</option>
                      <option value="Balboa Bank & Trust Corp">Balboa Bank & Trust Corp</option>
                      <option value="Banco Aliado S.A.">Banco Aliado S.A.</option>
                      <option value="Banco Azteca (Panamá), S.A.">Banco Azteca (Panamá), S.A.</option>
                      <option value="Banco BAC de Panamá, S.A.">Banco BAC de Panamá, S.A.</option>
                      <option value="Banco Bolivariano (Panamá), S.A.">Banco Bolivariano (Panamá), S.A.</option>
                      <option value="Banco Citibank (Panamá,) S.A.">Banco Citibank (Panamá,) S.A.</option>
                      <option value="Banco Davivienda (Panamá) S.A.">Banco Davivienda (Panamá) S.A.</option>
                      <option value="Banco de Bogotá, S.A.">Banco de Bogotá, S.A.</option>
                      <option value="Banco del Pacífico (Panamá), S.A.">Banco del Pacífico (Panamá), S.A.</option>
                      <option value="Banco Delta, S.A.">Banco Delta, S.A.</option>
                      <option value="Banco Ficohsa (Panamá), S.A.">Banco Ficohsa (Panamá), S.A.</option>
                      <option value="Banco G&T Continental (Panamá) S.A. (BMF)">Banco G&T Continental (Panamá) S.A. (BMF)</option>
                      <option value="Banco HIPOTECARIO NACIONAL">Banco HIPOTECARIO NACIONAL</option>
                      <option value="Banco General, S.A." selected >Banco General, S.A.</option>
                      <option value="Banco Internacional de Costa Rica, S.A (BICSA)">Banco Internacional de Costa Rica, S.A (BICSA)</option>
                      <option value="Banco La Hipotecaria, S.A.">Banco La Hipotecaria, S.A.</option>
                      <option value="Banco Lafise Panamá S.A.">Banco Lafise Panamá S.A.</option>
                      <option value="Banco Latinoamericano de Comercio Exterior, S.A. (BLADEX)">Banco Latinoamericano de Comercio Exterior, S.A. (BLADEX)</option>
                      <option value="Banco Nacional de Panamá">Banco Nacional de Panamá</option>
                      <option value="Banco Panamá, S.A">anco Panamá, S.A</option>
                      <option value="Banco Panameño de la Vivienda, S.A. (BANVIVIENDA)">Banco Panameño de la Vivienda, S.A. (BANVIVIENDA)</option>
                      <option value="Banco Pichincha Panamá, S.A.">Banco Pichincha Panamá, S.A.</option>
                      <option value="Banco Prival, S.A. (Español) o Prival Bank, S.A.">Banco Prival, S.A. (Español) o Prival Bank, S.A.</option>
                      <option value="Banco Universal, S.A.">Banco Universal, S.A.</option>
                      <option value="Bancolombia S.A.">Bancolombia S.A.</option>
                      <option value="Banesco S.A.">Banesco S.A.</option>
                      <option value="BANISI, S.A.">BANISI, S.A.</option>
                      <option value="Banistmo S.A.">Banistmo</option>
                      <option value="Bank Leumi-Le Israel B.M.">Bank Leumi-Le Israel B.M.</option>
                      <option value="Bank of China Limited">Bank of China Limited</option>
                      <option value="BBP Bank S.A.">BBP Bank S.A.</option>
                      <option value="BCT Bank International S.A.">BCT Bank International S.A.</option>
                      <option value="Caja de Ahorros">Caja de Ahorros</option>
                      <option value="Capital Bank Inc.">Capital Bank Inc.</option>
                      <option value="Citibank, N.A. Sucursal Panamá">Citibank, N.A. Sucursal Panamá</option>
                      <option value="Credicorp Bank S.A.">Credicorp Bank S.A.</option>
                      <option value="FPB Bank Inc.">FPB Bank Inc.</option>
                      <option value="Global Bank Corporation">Global Bank Corporation</option>
                      <option value="Korea Exchange Bank, Ltd.">orea Exchange Bank, Ltd.</option>
                      <option value="Mega International Commercial Bank Co. Ltd.">Mega International Commercial Bank Co. Ltd.</option>
                      <option value="Mercantil Bank (Panamá), S.A.">Mercantil Bank (Panamá), S.A.</option>
                      <option value="Metrobank, S.A.">Metrobank, S.A.</option>
                      <option value="MiBanco, S.A.BMF">MiBanco, S.A.BMF</option>
                      <option value="MMG Bank Corporation">MMG Bank Corporation</option>
                      <option value="Multibank Inc.">Multibank Inc.</option>
                      <option value="Produbank (Panamá) S.A.">Produbank (Panamá) S.A.</option>
                      <option value="St. Georges Bank & Company, Inc.">St. Georges Bank & Company, Inc.</option>
                      <option value="The Bank of Nova Scotia (Panamá), S.A.">The Bank of Nova Scotia (Panamá), S.A.</option>
                      <option value="The Bank of Nova Scotia (SCOTIABANK)">The Bank of Nova Scotia (SCOTIABANK)</option>
                      <option value="Towerbank International Inc.">Towerbank International Inc.</option>
                      <option value="Unibank, S.A.">Unibank, S.A.</option>
                
                </select>
                </Col>
            
                
              </Row> 
          */}
              <Row>
                <Col md="12">

                <FormGroup >

                  

                

                  


                      <label>Cargar el comprobante <span className="text-danger">*</span> </label>
                      
                      <div className="custom-file-panther">

                        
                      { deposit.file !== "" ? <>

                      <div className="load-file">
                        Comprobante cargado en estos momentos!
                      </div>
                      </> :null

                      }
                      
                      {/* { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null} */}
                      <label className="panther-input-file mb-0 " htmlFor="file"> 
                       <i className="tim-icons icon-paper p-2" />  Ejem .JPG .PDF 'Tamaño Maximo del archivo: 4mb'</label>

                      <input type="file"
                          id="file"
                          name="file"
                          onChange={onChange}
                          value={deposit.file} 
                          required
                      className="custom-file-input-panther"/>



                      </div>
                          
                    </FormGroup> 
                </Col>
              </Row>
              <p className="monto-registrar">El monto máximo por depósito es de <strong>3000 dólares</strong>. Para depósitos mayores a <strong>3000 dólares</strong>, comuniquese a nuestro correo info@panther7.com </p>
             
            
          </CardBody>
          
          { demo_status === "active" ? <CardFooter>
             <div className="row justify-content-center">
                 
                  <div  className="col-6 text-center">
                  Esto es una plataforma de demostración, el saldo y las operaciones no son reales.
                    <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" disabled>
                    {guardar}
                  </button>
                  </div>
             </div>
          </CardFooter>: <CardFooter>
             <div className="row justify-content-center">
               
                 
                  <div  className="col-6 text-center">
                  
                    <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" >
                    {guardar}
                  </button>
                  </div>
             </div>
          </CardFooter>

          }
          
        </Card>
      </Col> :null} 



      { deposit.type === "5" ? 
        
      
        <Col md="12"> 
        
        <Card className="bg-white">
         
          <CardBody>
            
     
     
              <Row className="justify-content-center d-flex paypal-help ">
                  <Col md="3 " className="animate-raya-03">   

                  <LogoPayPal className="animation-icon-in "/>
                  </Col>
              </Row>
              { demo_status === "active" ? 
              <Row className="justify-content-center d-flex" disabled> 
              <Col md="3" className="justify-content-center align-items-center d-flex" 

              // onClick={
              //    e => ( 

                  
              //     consultarApiPaypal()
              //    )
              //  } 
               
               
               >
              <a className="btn btn-primario btn-block  animation-icon-in-cuatro btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn"
               href={ `${paypallink}`} rel="noopener noreferrer" target="_blank" disabled >

                              
                              Pagar con PayPal
                          
                            </a> 
                            
          
              
              </Col> 
              </Row> 
              
              : <Row className="justify-content-center d-flex"> 
                  <Col md="3" className="justify-content-center align-items-center d-flex" 

                   onClick={
                      e => ( 

                      
                     consultarApiPaypal()
                      )
                    } 
                   
                   
                   >
                  <a className="btn btn-primario btn-block  animation-icon-in-cuatro btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn"
                   href={ `${paypallink}`} rel="noopener noreferrer" target="_blank" >

                                  
                                  Pagar con PayPal
                              
                                </a> 
                                
              
                  
                  </Col>  
            
              </Row> 
            }




              <Row className="justify-content-center d-flex"> 
                  <Col md="5" className="justify-content-center align-items-center d-flex">
                  <p className="text-black ">

                                  
                                  La forma más rápida y segura de Pagar
                              
                                </p> 
                                
              
                  
                  </Col>
              </Row> 


             
              
           
            
          </CardBody>
  
          
        </Card>

          <Card>
            <Row className="p-4"> 
            
            <p className="paypal-font">
            Los depósitos por Paypal tienen una comisión operativa del 5.5%.
            </p>

            <p className="paypal-font">
            Por ejemplo: si usted deposita $100, se harán efectivos en su cuenta Panther 7: $94.5
            </p>

            
            </Row>
          </Card>




      </Col> : null} 


   

       { deposit.type === "4" ? 
        
      
        <Col md="12"> 
        
        <Card>
         
          <CardBody>
            
     
     

              <Row className="deposito-bg">
                  <Col md="12" >
                  <div className="card-box-transparent card p-3 mt-4 registrar-deposito ">
                        <h1 className="m-0">REGISTRAR DEPOSITO ACH / USA </h1>
                       
                        <hr class="my-1 border-info"/>
                        
                       

                        <div className="acc-deposito-container">
                        <div className="term mt-8 mb-4 mt-4">
                          <h5><strong className="">Banco Receptor:</strong> First National Bankers Bank </h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">ABA:</strong> 065403370</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">SWIFT:</strong> FRNAUS44</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Banco Beneficiario:</strong> HomeBank of Arkansas</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Número de Cuenta:</strong> 082902485</h5>
                          </div>

                        <div className="term my-1">
                          <h5><strong className="">Crédito Adicional a:</strong> Handlesbanc Bayerish Inc - Acc# 03301338</h5>
                          </div> 


                          <div className="term my-1">
                          <h5><strong className="">Referencia de Crédito:</strong> 62364709 </h5>
                          </div>

                          {/* <div className="term mt-3 mb-8">
                            <p className="deposito-text-refer">
                            <strong >Referencia:
                          </strong> 707595540<br className=""/>
                            <span className="red-500-fg">* La Referencia es obligatoria en las observaciones de la transferencia</span>
                            </p>

                          </div> */}

                        </div> 

                        
                      </div> 


               
                  
                  
                  </Col>
                  <Col md="12" className="justify-content-between d-flex align-items-center text-center my-2 p-5"> 

                    <div className="flags-dad"> 
                          <div className="height-box-fit">
                          <img className="flags" src={imagenes}/>
                          </div>


                    <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name}`} rel="noopener noreferrer" target="_blank" >

                                  
                                  Descarga tus datos bancarios
                              
                                </a>
                    </div> 
                    <div className="ml-2 flags-dad" >
                    <div className="height-box-fit">
                          <img className="flags" src={imagenen}/>
                          </div>
                    <a className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 datos-bancarios-btn" href={ `${pdfdatos.file_name_eng}`} rel="noopener noreferrer" target="_blank" >

                                  
                                  Download your bank data
                              
                                </a>
                    </div>

                    </Col>
              </Row> 

              <Row>
              <Col md="12" >
                          <h2 className="display-currency text-cente">
                          <p>Usted está haciendo un deposito con el monto <span className="currency-bold"><NumberFormat value={deposit.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                          </h2>
                  </Col>
                  <Col md="12">
                    <FormGroup>
                    
                    < div className="form-group form-control-deposito-container">
                <label className="deposito-label-between" for="youSend">
                  <p>Monto <span className="text-danger">*</span></p>
                  <p>Elegir Moneda <span className="text-success"> *  </span></p>
                  </label> 
                

                <div className="input-group">
                  <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                  <input type="text" className="deposito-form-control-props-input form-control"   
                  placeholder="Digite el monto"
                  id="amount"
                  name="amount"
                  
                  onChange={onChange}
                  value={deposit.amount} 
                  required

                  
                  /> 

              
                  <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                  <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabIndex="-98">
                          <optgroup label="Moneda" className="bg-primary">
                              <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                              <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                          </optgroup>
                        </select>
                  </div>
                </div>
              </div>

                   
                    </FormGroup>
                  
                  </Col> 
                

                </Row>
{/* 
              <Row>
                <Col  md="12" className="mb-3">
                  <label>Referencia <span className="text-danger">*</span> </label>
                <input 
                  type="text"
                  placeholder="Escriba el numero de Referencia"
                  id="note"
                  name="note"
                  onChange={onChange}
                  value={deposit.note} 
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}

              
              {/* <Row>
                <Col  md="12"  className="mb-3">
                  <label>Fecha ACH <span className="text-danger">*</span> </label>
                <input 
                  type="date"
                  id="fecha"
                  name="fecha"
                  onChange={onChange}
                  value={deposit.fecha} 
                  
                  
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}
              {/* <Row>
                <Col  md="12" className="mb-3">
                  <label>Banco Origen <span className="text-danger">*</span> </label>
                <input 
                  type="text"
                  id="bank"
                  name="bank"
                  onChange={onChange}
                  value={deposit.bank} 
                  placeholder="Escriba el nombre del Banco"
                  
                  
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>  */}



{/* 
              <Row>
                <Col  md="12 my-4" >
                  <label>Seleccione el banco origen <span className="text-danger">*</span></label>
                <select 
                  id="bank"
                  name="bank"
                  onChange={onChange}
                  value={deposit.bank} 
                   className="form-control  pointer-cursor">
                <option value="" className="text-center option-fix"> --- Seleccione --- </option>
                <option value="Allbank Corp">Allbank Corp</option>
                      <option value="BAC International Bank, Inc.">BAC International Bank, Inc.</option>
                      <option value="Balboa Bank & Trust Corp">Balboa Bank & Trust Corp</option>
                      <option value="Banco Aliado S.A.">Banco Aliado S.A.</option>
                      <option value="Banco Azteca (Panamá), S.A.">Banco Azteca (Panamá), S.A.</option>
                      <option value="Banco BAC de Panamá, S.A.">Banco BAC de Panamá, S.A.</option>
                      <option value="Banco Bolivariano (Panamá), S.A.">Banco Bolivariano (Panamá), S.A.</option>
                      <option value="Banco Citibank (Panamá,) S.A.">Banco Citibank (Panamá,) S.A.</option>
                      <option value="Banco Davivienda (Panamá) S.A.">Banco Davivienda (Panamá) S.A.</option>
                      <option value="Banco de Bogotá, S.A.">Banco de Bogotá, S.A.</option>
                      <option value="Banco del Pacífico (Panamá), S.A.">Banco del Pacífico (Panamá), S.A.</option>
                      <option value="Banco Delta, S.A.">Banco Delta, S.A.</option>
                      <option value="Banco Ficohsa (Panamá), S.A.">Banco Ficohsa (Panamá), S.A.</option>
                      <option value="Banco G&T Continental (Panamá) S.A. (BMF)">Banco G&T Continental (Panamá) S.A. (BMF)</option>
                      <option value="Banco HIPOTECARIO NACIONAL">Banco HIPOTECARIO NACIONAL</option>
                      <option value="Banco General, S.A." selected >Banco General, S.A.</option>
                      <option value="Banco Internacional de Costa Rica, S.A (BICSA)">Banco Internacional de Costa Rica, S.A (BICSA)</option>
                      <option value="Banco La Hipotecaria, S.A.">Banco La Hipotecaria, S.A.</option>
                      <option value="Banco Lafise Panamá S.A.">Banco Lafise Panamá S.A.</option>
                      <option value="Banco Latinoamericano de Comercio Exterior, S.A. (BLADEX)">Banco Latinoamericano de Comercio Exterior, S.A. (BLADEX)</option>
                      <option value="Banco Nacional de Panamá">Banco Nacional de Panamá</option>
                      <option value="Banco Panamá, S.A">anco Panamá, S.A</option>
                      <option value="Banco Panameño de la Vivienda, S.A. (BANVIVIENDA)">Banco Panameño de la Vivienda, S.A. (BANVIVIENDA)</option>
                      <option value="Banco Pichincha Panamá, S.A.">Banco Pichincha Panamá, S.A.</option>
                      <option value="Banco Prival, S.A. (Español) o Prival Bank, S.A.">Banco Prival, S.A. (Español) o Prival Bank, S.A.</option>
                      <option value="Banco Universal, S.A.">Banco Universal, S.A.</option>
                      <option value="Bancolombia S.A.">Bancolombia S.A.</option>
                      <option value="Banesco S.A.">Banesco S.A.</option>
                      <option value="BANISI, S.A.">BANISI, S.A.</option>
                      <option value="Banistmo S.A.">Banistmo</option>
                      <option value="Bank Leumi-Le Israel B.M.">Bank Leumi-Le Israel B.M.</option>
                      <option value="Bank of China Limited">Bank of China Limited</option>
                      <option value="BBP Bank S.A.">BBP Bank S.A.</option>
                      <option value="BCT Bank International S.A.">BCT Bank International S.A.</option>
                      <option value="Caja de Ahorros">Caja de Ahorros</option>
                      <option value="Capital Bank Inc.">Capital Bank Inc.</option>
                      <option value="Citibank, N.A. Sucursal Panamá">Citibank, N.A. Sucursal Panamá</option>
                      <option value="Credicorp Bank S.A.">Credicorp Bank S.A.</option>
                      <option value="FPB Bank Inc.">FPB Bank Inc.</option>
                      <option value="Global Bank Corporation">Global Bank Corporation</option>
                      <option value="Korea Exchange Bank, Ltd.">orea Exchange Bank, Ltd.</option>
                      <option value="Mega International Commercial Bank Co. Ltd.">Mega International Commercial Bank Co. Ltd.</option>
                      <option value="Mercantil Bank (Panamá), S.A.">Mercantil Bank (Panamá), S.A.</option>
                      <option value="Metrobank, S.A.">Metrobank, S.A.</option>
                      <option value="MiBanco, S.A.BMF">MiBanco, S.A.BMF</option>
                      <option value="MMG Bank Corporation">MMG Bank Corporation</option>
                      <option value="Multibank Inc.">Multibank Inc.</option>
                      <option value="Produbank (Panamá) S.A.">Produbank (Panamá) S.A.</option>
                      <option value="St. Georges Bank & Company, Inc.">St. Georges Bank & Company, Inc.</option>
                      <option value="The Bank of Nova Scotia (Panamá), S.A.">The Bank of Nova Scotia (Panamá), S.A.</option>
                      <option value="The Bank of Nova Scotia (SCOTIABANK)">The Bank of Nova Scotia (SCOTIABANK)</option>
                      <option value="Towerbank International Inc.">Towerbank International Inc.</option>
                      <option value="Unibank, S.A.">Unibank, S.A.</option>
                
                </select>
                </Col>
            
                
              </Row> 
          */}
              <Row>
                <Col md="12">

                <FormGroup>
                      <label>Cargar el comprobante <span className="text-danger">*</span> </label>
                      
                      <div className="custom-file-panther">
                      
                      {/* { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null} */}
                      <label className="panther-input-file " htmlFor="file"> 
                       <i className="tim-icons icon-paper p-2" />  Ejem .JPG .PDF 'Tamaño Maximo del archivo: 4mb'</label>

                      <input type="file"
                          id="file"
                          name="file"
                          onChange={onChange}
                          value={deposit.file} 
                          required
                      className="custom-file-input-panther"/>
                      </div>
                          
                    </FormGroup> 
                </Col>
              </Row>
           
             
            
          </CardBody>
          
          { demo_status === "active" ? 
          <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 Esto es una plataforma de demostración, el saldo y las operaciones no son reales.
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" disabled>
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter>: 
          <CardFooter>
          <div className="row justify-content-center">
                 
                 <div  className="col-6 text-center">
                 
                   <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" >
                   {guardar}
                 </button>
                 </div>
            </div>
          </CardFooter>
          }
          
        </Card>
      </Col> :null} 



        
      </div>
      </Form> 

          <form onSubmit={onSubmitRemesas}> 
          <div className="row">
          { deposit.type === "3" ? 
        
      
        <Col md="12"> 
        
        <Card>
         
          <CardBody>
          <Row >
                 <h1 className="m-0  p-3 mt-4">DEPOSITO VÍA REMESADORA </h1>
                       
                        <hr class="my-1 border-bottom border-info"/>
                        
                       
                        <Col md="12" >
                          <h2 className="display-currency text-cente">
                          <p>Usted está haciendo un deposito con el monto <span className="currency-bold"><NumberFormat value={deposit.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/> </span>  </p>
                          </h2>
                  </Col>
                      
                  <Col md="12">
                    <FormGroup>
                    < div className="form-group form-control-deposito-container">
                <label className="deposito-label-between" for="youSend">
                  <p>Monto <span className="text-danger">*</span></p>
                  <p>Elegir Moneda <span className="text-success"> *  </span></p>
                  </label> 
                

                <div className="input-group">
                  <div className="input-group-prepend "> <span className="input-deposito-moneda input-group-text">$</span> </div>
                  <input type="text" className="deposito-form-control-props-input form-control"   
                  placeholder="Digite el monto a enviar"
                  id="amount"
                  name="amount"
                  onChange={onChange}
                  value={deposit.amount}
                  required 
                  
                  />
                  <div className="input-group-append input-group-append-deposito  p-0 custom-select "> 
                  <select id="youSendCurrency" data-style="custom-select bg-transparent border-0" data-container="body" data-live-search="true" className="selectpicker form-control p-0 custom-select-deposito bg-transparent" required="" tabIndex="-98">
                          <optgroup label="Moneda" className="bg-primary">
                              <option data-icon="currency-flag currency-flag-usd mr-1" data-subtext="USD" selected="selected" value="">USD&nbsp;</option>
                              <option data-icon="btc" data-subtext="BTC" value="">BTC &nbsp;</option>
                          </optgroup>
                        </select>
                  </div>
                </div>
              </div>

                   
                    </FormGroup>
                  
                  </Col>
                </Row>

                  {/* <Row>
                <Col  md="12" className="mb-3">
                  <label>Número Autorización (PIN) <span className="text-danger">*</span> </label>
                <input 
                  type="text"
                  placeholder="Escriba el numero de Referencia"
                  id="note"
                  name="note"
                  placeholder="5529341719"
                  
                
                className=" form-control" required="" onchange="cambiar(this.value)"/>
              
              
                </Col>
            
                
              </Row>   */} 


                {pin ? 
                          
                          
                          <Row className="deposito-bg">
                          <Col md="12">
                          <div className="card p-3 mt-2 registrar-deposito ">
                               
        
                                <div className="acc-deposito-container">
                                  <h2>Pasos para proceder a realizar el deposito vía Master Money </h2>
                                  </div> 
        
                                  <ol>
                                  <li>  
                                      <div className="term mt-8 mb-1 mt-1">
                                      <h5>Dirigase al Master Money más cercano. Ver listado de oficinas  <strong className="master-click"onClick={ ()=> {
                                        handleOpenDireccion()
                                      }}>Master Money</strong> </h5>
                                      </div>
        
                                  </li> 
        
                                  <li>
                                      <div className="term my-1">
                                      <h5>Debe ir con una identificación vigente.</h5>
                                      </div>
                                  </li>
        
                                  <li>
                                    <div className="term my-1">
                                    <h5>Notificarle al operador Master Money el numero de autorización (PantherPIN) 
                                    {/* <strong className="pin"> {pin ? <p className="panther-pin-box">{pin}</p> : null}</strong> */}
                                       </h5>
                                    </div>
                                  </li> 
                                  <li>
                                      <div className="term my-1">
                                      <h5>Entréguele al operador Master Money el valor de su envió a deposito</h5>
                                      </div> 
                                  </li>
                                  <li>
                                      <div className="term my-1">
                                      <h5>Al procesar su remesa, reciba su copia de envió y su deposito estará disponible en su wallet Panther 7 en efectivo en un máximo de 30 minutos.</h5>
                                      </div>
                                  </li> 
                                  <li>
                                      <div className="term my-1">
                                      <h5>Toda la información será enviada vía correo electrónico</h5>
                                      </div>
                                  </li>
        
        
                              </ol>
                                  <p className="text-white panther-pin-box">PantherPIN <strong className="pin">{pin}</strong> </p> 
                              
                              </div> 
        
        
                       
                          
                          
                          </Col>
                         
                      </Row> 
        
                          
                          
                          
                          : null} 
            

             
          </CardBody>
          <CardFooter> 

           { btnremesas ?
             <div className="row justify-content-center">
                 
             { demo_status === "active" ? 
             <div  className="col-6 text-center">
             Esto es una plataforma de demostración, el saldo y las operaciones no son reales.
               <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" disabled>
               {guardar}
             </button>
             </div> 
             : 
             <div  className="col-6 text-center">
             
               <button className=" btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  type="submit" >
               {guardar}
             </button>
             </div>

            
            }
        </div> :null }
          </CardFooter>
      
        </Card>
      </Col> :null}  


      

      </div>
          </form>
    </div> 

    { mensaje ? <Modal
   
   className={classes.modal}
   open={open} 
   mensaje={mensaje}
   

   
                     
   
   closeAfterTransition
   BackdropComponent={Backdrop}
   BackdropProps={{
     timeout: 900,
   }}
 >
   <Fade in={mensaje}
   timeout={154}
   >
     <div className="container">
   <div className="row">
     <div className="col-12 d-flex justify-content-center">
     <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
     
     <CardBody>
                         <Row className="d-flex justify-content-center">
                                 <Col sm="12" md="6"> 
                                     <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                 </Col>       
                         </Row>
                       </CardBody> 
                       <CardFooter>
         <div className="row">
             
         <div  className="col-4">
             </div>
             <div  className="col-4">
               <button className=" btn mt-0 btn-block btn-info" onClick={ () => (
                       guardarCargando(false),
                       TodosDepositos(),
                       guardarMensaje(null),
                       handleClose(),
                       guardarDeposit({
  
                        amount:'',
                        type:'',
                        file:'',
                        note:'',
                       bank:'',
                       fecha:'',
                   })
                                                                 
                     )}>
               Aceptar
             </button>
             </div>
                   
         <div  className="col-4">
             </div>
         </div>
     </CardFooter>
                       
                   
     

   </div>

     </div>

   </div>
     </div>
   </Fade>
</Modal> : null}  

<ModalDireccionesMaster 
      opendireccion={opendireccion}
      handleCloseDireccion={handleCloseDireccion}
      handleOpenDireccion={handleOpenDireccion}

/>

  </>


    
  );
}
 
export default Deposito;


