
import React, { useEffect, useState, useContext } from "react";
import ListaProjectos from '../components/inversiones/ListaProjectos'

import FiltroProyecto from '../components/inversiones/FiltroProyecto'

// cambiar por el reducer de inversiones, donde voy a llamar la api
import AuthContext from '../context/autenticacion/authContext.js'



// reactstrap components
import { 
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  
  Table,
  Row,

  Col,
  UncontrolledTooltip
} from "reactstrap"; 





const Projectos = (props) => { 


  /// aqui vamos a colocar el context
  const authContext = useContext(AuthContext);
      const { MostrarProyectos,contenido_proyectos, AtrasProyectos,BtnProtectOff, btn_protect, BtnProtectOn } = authContext;  



  const [mostrarproyecto, guardarMostrarProyecto] = useState({
    main:1,
    proyecto:'',
    
  })

  ////// envento del boton , bloqueo de carga

  const [btnlazzy, guardarBtnLazzy] = useState(false);

  // useEffect(() => {
  //      BtnProtectOff()
  // }, [])
  
  

  
  



  return (
    <>        



    
            <div className="content"> 
            {contenido_proyectos === null ? 
                                     
                                     <Row className="box-depositos-seleccion justify-content-center"> 
                                      <Col md="12" className=" panther-open-titulo border-panther animate-raya-03">
                                      <i className="tim-icons icon-spaceship "/>
                                            <h2>Proyectos</h2>
                                              <h3>Hacemos que el arte de invertir sea muy fácil y  <br/> amigable. Sea cual sea su experiencia o nivel <br/> de inversor, con Panther 7 estará respaldado.</h3>
                                          </Col>

                                     

                                        <Col md="3" >

                                        {btn_protect === false ? <>
                                          <button color="link" title="" type="button" class="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1 animation-forma-block-btn"  onClick={ () => {
                                                                      
                                                                      guardarMostrarProyecto(({...mostrarproyecto, proyecto: 1,main: 0}))
                                                                      MostrarProyectos()
                                                                      
                                                                      
                                                                        }
                                                                        }>Continuar</button>
                                                                        <p className="text-center">Para invertir dele Clic en continuar</p>
                                        
                                        </> : <p className="text-center">Cargando...</p>
                                        
                                        
                                        }


                                        
                                      

                                        
                                        
                                        
                                        
                                        </Col>
                                                                 
                                      </Row> : <>
                                      <div className="row atras-box">
                                      <p className="text-right atras-class "  onClick={ () => {
                                                                      
                                                                    
                                                                      AtrasProyectos()
                                                                      
                                                                      
                                                                        }
                                                                        }>Atrás</p>   
                                        </div>      
                                      <ListaProjectos/>  
                                      </>

}  




  

         
            
            
              
            
            </div> 
    
  </>
  );
}
 
export default Projectos;

