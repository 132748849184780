import React, { useState, useContext } from 'react'
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 
import LogoWhiteNoAnim from '../../assets/scss/black-dashboard-react/panther/logo-white-none-anim.svg'; 

import LogoBlack from '../../assets/scss/black-dashboard-react/panther/logo.svg'; 
import LogoBlackNoAnim from '../../assets/scss/black-dashboard-react/panther/logoNoAnim.svg'; 


import TodosMisDepositos from './TodosMisDepositos'
import DepositosPanama from './DepositosPanama'
import DepositosInternacional from './DepositosInternacional'

import AuthContext from '../../context/autenticacion/authContext'



const Depositos = () => {

    const authContext = useContext(AuthContext);
    const {jimf_theme} = authContext




    const [ layerout, setLayerOut] =useState({
        todos_mis_depositos:true,
        transferencia_nacional:false,
        transferencia_internacional:false,
    })

    const TodosDepositosLay = () => {
        setLayerOut({
            todos_mis_depositos:true,
            transferencia_nacional:false,
            transferencia_internacional:false,  
        })
    }

    const TransferenciaNacionalLay = () => {
        setLayerOut({
            todos_mis_depositos:false,
            transferencia_nacional:true,
            transferencia_internacional:false,  
        })
    }
    
    const TransferenciaInterNacionalLay = () => {
        setLayerOut({
            todos_mis_depositos:false,
            transferencia_nacional:false,
            transferencia_internacional:true,  
        })
    }


    const { todos_mis_depositos, transferencia_nacional, transferencia_internacional } = layerout


    return (  

            <>
            <div className="content">


                <div 
                    className={`${jimf_theme === "white" ? 'box-ultradeposito-white':'box-ultradeposito' }`}
                
                >

                    <div 
                    className={`${jimf_theme === "white" ? 'header-deposito-white':'header-deposito' }`}
                    >
                            <div className=" box-logo-white" >

                                    {jimf_theme === "white" ? 
                                    <>

                                    <img src={LogoBlackNoAnim} className=" logo-size-2 mobile-responsive-jimf" />
                                    <img src={LogoBlack} className=" logo-size-2 desktop-responsive-jimf" />
                                    
                                    </> :
                                    <>
                                    <img src={LogoWhiteNoAnim} className=" logo-size-2 mobile-responsive-jimf" />
                                    <img src={LogoWhite} className=" logo-size-2 desktop-responsive-jimf" />
                                    </>

                                    }
                            </div>
                        <div>
                            <h2>Depósito</h2>
                            <p>Seleccione el tipo de deposito a realizar</p>
                            <span> <strong>www.</strong>panther7<strong>.com</strong> </span>
                        </div>
                    </div>

                    <div className={`${jimf_theme === "white" ? 'body-deposito-white':'body-deposito' }`}>

                        <div 
                        className={`${jimf_theme === "white" ? 'ultra-deposito-jimfwhite':'ultra-deposito-jimf' }`}
                        >

                            <button className={`
                            ${todos_mis_depositos === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                            ${todos_mis_depositos === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                            
                            `
                            } 
                                    onClick={TodosDepositosLay}>
                                Mis depósito
                            </button>

                            <button className={`
                                ${transferencia_internacional === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                                ${transferencia_internacional === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                            ` } 
                            onClick={TransferenciaInterNacionalLay}>
                                Depósito Internacional
                            </button>

                            <button className={`
                            ${transferencia_nacional === true && jimf_theme !== "white" ? 'active-deposit-jimf' : ''}
                            ${transferencia_nacional === true && jimf_theme === "white" ? 'active-deposit-jimfwhite' : ''}
                            `} 
                            onClick={TransferenciaNacionalLay}>
                                Depósito Nacional
                            </button>

                        </div>



                        <div className="ultra-deposito-jimf-info">

                        {todos_mis_depositos === true ?
                        <TodosMisDepositos
                        
                        
                        /> : null
                        } 

                        {transferencia_internacional === true ?
                        <DepositosInternacional
                            jimf_theme={jimf_theme}
                        /> : null
                        } 

                        {transferencia_nacional === true ?
                        <DepositosPanama
                            jimf_theme={jimf_theme}
                        /> : null
                        } 



                            
                        
                            
                        </div>

                    </div>



                    <div className={`${jimf_theme === "white" ? 'ultradepositowhite':'ultradeposito' }`}>

                    <div className={`${jimf_theme === "white" ? 'ultradeposito-son-white':'ultradeposito-son' }`}>
                    </div>

                    </div>
                
                    

                </div>








            </div>
            
            
            
            </>



    );
}
 
export default Depositos;