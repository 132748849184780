
import React, { useEffect, useState, useContext } from "react";
import ListaInversion from '../components/inversiones/ListaInversion'
import ListaVendidas from '../components/inversiones/ListaVendidas'
import FiltroInversion from '../components/inversiones/FiltroInversion'



///* estilos del carousel


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 

import styled from 'styled-components'




// cambiar por el reducer de inversiones, donde voy a llamar la api
import AuthContext from '../context/autenticacion/authContext.js'

// reactstrap components
import { 
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap"; 

// aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    outline:'none',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      outline:'none',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     outline:'none',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      outline:'none',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      outline:'none',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));






const Inversiones = (props) => { 

  const authContext = useContext(AuthContext);
      const { MostrarInversiones,contenido_inversiones, AtrasInversiones } = authContext;  



  const theme = useTheme();

    //// evento del mercado secundario

    const [valuedos, setValueDos] = React.useState(0);

    const handleChangeMercado = (event, newValueDos) => {
      setValueDos(newValueDos);
    };
  
    const handleChangeIndexMercado = (index) => {
      setValueDos(index);
    }; 
  
  
    const [mostrarproyecto, guardarMostrarProyecto] = useState({
      main:1,
      proyecto:'',
      
    })

    const [appbar, guardarAppBar] = useState({
      primero:1,
      segundo:0
      
    })
  
    
    
  
    

  
  



  return (
    <>
    <div className="content">   

    {contenido_inversiones === null ? 
                                     
                                     <Row className="box-depositos-seleccion justify-content-center"> 
                                      <Col md="12" className=" panther-open-titulo border-panther animate-raya-03">
                                      <i className="tim-icons icon-chart-bar-32"/>
                                            <h2>Inversiones</h2>
                                            <h3>Hacemos que el arte de invertir sea muy fácil y  <br/> amigable. Sea cual sea su experiencia o nivel <br/> de inversor, con Panther 7 estará respaldado.</h3>
                                          </Col>

                                     

                                        <Col md="3" >


                                        <button color="link" title="" type="button" class="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  onClick={ () => {
                                                                      
                                                                      guardarMostrarProyecto(({...mostrarproyecto, proyecto: 1,main: 0}))
                                                                      MostrarInversiones()
                                                                      
                                                                      
                                                                        }
                                                                        }>Continuar</button>
                                                                        <p className="text-center">Para ver el estado de sus inversiones, dele Clic en continuar</p>

                                        
                                        
                                        
                                        
                                        </Col>
                                       
                                      </Row> : 

                                            <>  

                                  <div className="row atras-box">
                                      <p className="text-right atras-class "  onClick={ () => {
                                                                      
                                                                    
                                                                      AtrasInversiones()
                                                                      
                                                                      
                                                                        }
                                                                        }>Atrás</p>   
                                    </div>   

                                    <div className="grid-app-bar-simple mb-2 "> 
                                        
                                        
                                        
                                               
                                           
                                     <div>
                                                               <p className={` ${appbar.primero === 1 ? 'active-selection-proyectos':''}`}
                                                                onClick={ ()=>(
                                                                  guardarAppBar(({...appbar, primero: 1, segundo: 0}))
                                                                )}
                                                               
                                                               > Inversiones activas
                                                                <h4 >
                                                                  
                                                                </h4>
                                                               </p>

                                                               <p className={` ${appbar.segundo === 1 ? 'active-selection-proyectos':''}`}
                                                                  onClick={ ()=>(
                                                                    guardarAppBar(({...appbar, primero: 0, segundo: 1}))
                                                                  )}
                                                               
                                                               >
                                                                 Inversiones vendidas
                                                                 <h4 >
                                                                 
                                                                </h4>
                                                                 </p>
                                      </div>

                                     

                                       
                                                               
                                       

                                     </div>  

                                     <div className={` text-white ${appbar.primero === 1 ? 'd-block':'d-none'}`} >
                                        <ListaInversion />

                                     </div>
                                     <div className={`  ${appbar.segundo === 1 ? 'd-block':'d-none'}`}>
                                     <ListaVendidas />
                                     </div>
                                     
                                     




                                        {/* <Row className="my-2">
                                          <Col md="12" className="appbar-box-panther-dos">
                                        <AppBar position="static" color="default" className="app-panther-bar-dos">
                                          
                                        <StyledTabs value={valuedos} 
                                                  onChange={handleChangeMercado} 
                                                  aria-label="styled tabs example"
                                                  >

                                            <StyledTab  label="Activas" {...a11yProps(0)} />
                                            <StyledTab label="Vendidas" {...a11yProps(1)} />
                                            
                                            
                                        </StyledTabs>


                                        </AppBar> 
                                        </Col>
                                      </Row>   */}




                                        {/* <SwipeableViews
                                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                          index={valuedos}
                                          onChangeIndex={handleChangeIndexMercado}
                                        >
                                          <TabPanel value={valuedos} index={0} dir={theme.direction} > 
                                          <ListaInversion className={`  ${appbar.primero === 1 ? 'd-block':'d-none'}`}/>


                                          
                                          </TabPanel>

                                          <TabPanel value={valuedos} index={1} dir={theme.direction}>  
                                          
                                          <ListaVendidas className={`  ${appbar.segundo === 1 ? 'd-block':'d-none'}`}/>

                                          </TabPanel> 
                                      
                                    
                                        </SwipeableViews>  */}


</>


}  

      
    
    </div>
  </>
  );
}
 
export default Inversiones;

