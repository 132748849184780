import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import NumberFormat from 'react-number-format';
import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner'



const DatosPersonales = ({usuario,jimf_theme, setCompletar}) => {

    

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 

    const Loader = ( spin ) ? <LineSpinner/> :  
                                          
    <Fragment>
      Completar información
      </Fragment> 

      const Abrir = () => {
        setCompletar(true)
      }



    


                    const [ spin, setSpin ] = useState(false);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);


    return (  
            <>

            <div className="window-jimf-retiro box-dual">

            <div className="cuento-deposito">
                        <div className="box-cuento-deposito g-jimf-100">
                        <h1>Actualización de mis datos personales</h1>
                        <p> Bienvenido a tu plataforma favorita de inversiones, 
                            <small> porfavor completa </small>
                             los siguientes campos con tus datos personales para podre disfrutar de 
                             <small> Panther7 </small>
                        </p>

                        <p>
                            <small>
                            Los campos con asteriscos son obligatorios * 
                            </small>


                        </p>

                        </div>
                        <div className="box-cuento-deposito cool-left">

                                    <h5><strong className="">Nombre de usuario:</strong> {usuario.name} </h5>
                                    <h5><strong className="">Correo electrónico:</strong> {usuario.email} </h5>
                                    <h5><strong className="">Código de referido:</strong> {usuario.referrer} </h5>

                                    <h5><strong className="">Documento de identidad:</strong>
                                    { !usuario.identity ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.identity}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Sexo:</strong>
                                    { !usuario.sexo ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.sexo}
                                    </>

                                    } 
                                    
                                    </h5>



                                    <h5><strong className="">Situación laboral:</strong>
                                    { !usuario.situacionlaboral ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.situacionlaboral}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Empresa donde labora:</strong>
                                    { !usuario.comercial_name ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.comercial_name}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Fecha de nacimiento:</strong>
                                    { !usuario.birthday_date  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.birthday_date}
                                    </>

                                    } 
                                    
                                    </h5>


                                    

                                    
                        </div>
                        
                </div>

                <div className="cuento-deposito">
                <div className="box-cuento-deposito cool-left">
                            <h5><strong className="">Número de Celular:</strong>
                                    { !usuario.telephone  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.telephone}
                                    </>

                                    } 
                                    
                                    </h5>

                                    


                                    <h5><strong className="">Dirección principal:</strong>
                                    { !usuario.address_street  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_street}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Dirección Calle :</strong>
                                    { !usuario.address_number  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_number}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Código Postal  :</strong>
                                    { !usuario.address_zipcode  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_zipcode}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">País :</strong>
                                    { !usuario.address_country  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_country}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Provincia :</strong>
                                    { !usuario.address_zone  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_zone}
                                    </>

                                    } 
                                    
                                    </h5>

                                    <h5><strong className="">Ciudad :</strong>
                                    { !usuario.address_city  ?
                                    <>
                                        Sin agregar 
                                    </> : 
                                    <>
                                        {usuario.address_zone}
                                    </>

                                    } 
                                    
                                    </h5>



                                   

                                    
                            </div>


                            <div className="box-cuento-deposito g-jimf-100">
                                <h1>Actualización de mis datos personales</h1>
                                <p> Haga Click en 
                                    <small> completar información </small>
                                    para los campos que aún están sin agregar
                                </p>

                                <div className="completar-datos">
                                <button  onClick={Abrir} className={`submit-jimf ${spin === true ? 'padd-mad' :''}`}>
                                        {Loader}
                                            </button>

                                </div>



                        </div>
                        
                </div>
                
                




            </div>


            

            <div className="window-select-tipe">
                <h1>Complete su información personal</h1>
                </div>

                


                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                        /> 
                    
                
                </> : null
                
                }
            
            
            
            </>



    );
}
 
export default DatosPersonales;