import React, { useState, Fragment } from 'react'; 
import clienteAxios from '../../config/axios'

import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Container,
    Row,
    Col
  } from "reactstrap"; 


  
import EditModal from '@material-ui/core/Modal' 
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 

  
import Modal from '@material-ui/core/Modal' 
import ModalCancelar from '@material-ui/core/Modal' 

import Spinner from '../spinners/Spinner'



/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';  




function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
 
  }
  
  
  const useStyles = makeStyles((theme) => ({
    root:{
        background: 'non',
        backgroundColor: 'none',
    },
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'scroll',
    height:'100%',
    display:'block',
    background: 'rgba(0, 124, 255, 0.88)',
    backgroundColor: 'none',
      
    },
    paper: {
      
      border: '2px solid #000',
      boxShadow: theme.shadows[5],
      
      margin: '20% 1% 10% 1%',
    },
  }));
  
  
  


const CuentasBeneficiariosRemesa = ({cuentasremesas,apiload,ApiLoad,ListaRemesas}) => { 



/// aqui vamos a colocar el loader 
const [ cargando, guardarCargando] = useState(false);

const guardar = ( cargando ) ? <Spinner/> :  
                                        
<Fragment>
  Desactivar cuenta
  </Fragment> 





/////////////////////// extra
    const [open, setOpen] =useState(false);

    const handleOpen = () => {
        setOpen(true);
    } 
   
    const handleClose = () => {
        setOpen(false);
        guardarMensaje(null);
    }


    const [modalInfo, setModalInfo] = useState([]);

    const [cancelaroferta, guardarCancelarOferta] = useState("");


///////////////////// extra


    const classes = useStyles(); 
    const [ modalStyle ] =useState(getModalStyle);
      
   ///edit modal//////////////////////////////////
        const [openedit, setOpenEdit] =useState(false);

        
        const handleOpenEdit = () => {
        setOpenEdit(true);
        } 

        const handleCloseEdit = () => {
        setOpenEdit(false);
        guardarMensaje(null);
        
        }

    ////////////// edita modal fin 


    //// aqui vamos a colocar el mensaje y su estado
    const [mensaje, guardarMensaje] = useState('');

        const [cuenta, guardarCuenta] =useState(''); 


        const borrarOferta = async () => {
       
  
                
          try {
            const respuesta = await clienteAxios.post(`/api/client/beneficiario_delete/${cancelaroferta}`);
              
            guardarCargando(false);
            guardarMensaje(respuesta.data.message); 
            abrirMensaje();
        
            
          } catch (error) {
            guardarCargando(false);
            guardarMensaje(error.response.data.message);
         
          }
        } 




       /// aqui vamos a colocar el modal de mensaje
 const [ modalmensaje, setModalMensaje ] = useState(false);
 /// aqui vamos a colocar los verdaderos manejadores en funciones

 const abrirMensaje = () => {
  setModalMensaje(true);
  
 }
 
 const cerrarMensaje = () => {
  setModalMensaje(false);
    guardarMensaje(false);
 } 


      

  const onSubmitCancelar = e => {
    guardarCargando(true);
      e.preventDefault(); 

      
      
      borrarOferta(cancelaroferta);

  }




    
    return (  


        
        <>  

        {   cuentasremesas.length > 0 ?
            cuentasremesas.map(cuenta => (
                <Col className="animation-cuentas" xs="12" sm="6" md="6" lg="4" key={cuenta.id}      BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 900,
                }}>
                                  
                <div className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0 account-bank my-2">
                    <div className="col-3 d-flex justify-content-center p-3">
                      <div className="my-auto text-center mr-2 ml-2"> <i className="fas fa-university  account-bank-icon"></i>
                      
                        <p className="bg-light text-0 text-body font-weight-500 rounded-pill d-inline-block px-2 line-height-4 opacity-8 mb-0">Bancaria</p>
                        
                      </div>
                    </div>
                    <div className="col-9 ml-3 border-left">
                      <div className="py-2 my-2 ">
                        <p className="text-4 font-weight-500 mb-1">{cuenta.bank_client}</p>
                        <p className="text-4 opacity-9 mb-1">XXXX-{cuenta.bank_apellidos}</p>
                        <p className="m-0">{cuenta.pais} <span className="text-3"><i className="fas fa-check-circle"></i></span></p>
                        <p className="text-4 font-weight-500 mb-1"><strong>{cuenta.tipo_identificacion} </strong>{cuenta.identificacion}</p>
                        
                      </div>
                    </div>
                     
                    <div className="account-card-overlay  p-3 mb-4 mb-lg-0"> 
                        <p className="pr-5"    onClick={ () => {
                         handleOpen()
                         guardarCuenta(cuenta)
                      }}>

                    <span className="mr-1"><i className="fas fa-share"></i></span>Ver detalles</p> 


                        <p 

                            onClick={ () => {
                              handleOpenEdit();
                              
                              guardarCancelarOferta( `${cuenta.id}`)
                              
                              setModalInfo(cuenta);
                              
                            }


                            }
                        
                        
                        ><span className="mr-1"><i className="fas fa-minus-circle"></i></span>Borrar</p> 
                      </div>
                       
                    </div> 

                    
                
                </Col>

            ) ) : (
                <p className="animation-cuentas w-100 text-center">Sin beneficiario remesa </p>
            )


        }  

<Modal
                    
                    className={classes.modal}
                    open={open} 
                    
                    
                    disableScrollLock={true}
                    
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    onClose={handleClose}>
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper} Modal-inversion `}>

                        <CardHeader className="bg-modal-header"> 
                              <Container>
                                <Row>
                                  <Col xs="10" sm="10" md="10" lg="10" className=" fix-cuentaspropias-title ">
                                    
                                    <div className="card p-3 mt-4 registrar-deposito card-box-transparent-dos   pl-3 pr-3">
                                        <h1 className="m-0 bold-tittle text-white">Cuenta de beneficiario Remesa Panther7 </h1>
                                        <p className="pl-2 text-white"> (Detalle de la cuenta ) de {cuenta.bank_client}</p>
                                      
                                        
                                      </div> 
                                  </Col>
                                  <Col xs="2" sm="2" md="2" lg="2" className="align-items-center d-flex justify-content-center">
                                    <div className="cancel-box-btn">
                                        <div className="cancel-btn-panther" onClick={ () => (
                                          handleClose()
                                        )}> 
                                            <i className="tim-icons icon-simple-remove"/>
                                        </div>
                                    </div>
                                  </Col>
                                </Row>
                              </Container>
                                  
                          </CardHeader>
                        
                        <CardBody className="tarjeta-modal-fix-detalle">
                                            <Row className="d-flex justify-content-center"> 
                                            <Col sm="4" md="4" className=" static-height d-flex"> 
                       
                                                        
                                            <FormGroup className="account-card account-card-primary text-white rounded p-3 mb-4 mb-lg-0 account-bank my-2">
                       <Row className=" justify-content-center p-3">
                       <div className="col-12 d-flex justify-content-center p-3">
                                            <div className="my-auto text-center"> 
                                            
                                           
                                            <div className="text-center text-4 text-white my-4"> <i className="fas fa-university  account-bank-icon-max"></i></div>
                    
                                            <div className="text-center text-4 text-white my-4"><p className="bg-light text-0 text-body font-weight-500 rounded-pill d-inline-block px-2 line-height-4 opacity-8 mb-0">Remesadora</p></div>

                                            
                                            
                                            <div className="text-center text-4 text-white my-4">{cuenta.pais} | {cuenta.bank_client} {cuenta.bank_apellidos}</div>
                                            </div>
                                          </div>
                                          </Row>



                                            </FormGroup>
                        </Col>      

                                                    <Col sm="8" md="8" className=" static-height"> 
                       
                                                        
                                                        <FormGroup>
                                   < div className="form-group form-control-deposito-container">

                              <label className="panther-retiro-label">Nombres<span className="text-success  mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Identificacion Destinatario"
                                  id="bank_client"
                                  name="bank_client"
                                  value={cuenta.bank_client}
                                  
                                  
                                
                                className=" form-control" required="" />

       
                              <label className="panther-retiro-label">Apellidos<span className="text-success  mt-3">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="e.g. Smith Rhodes"
                                  id="bank_apellidos"
                                  name="bank_apellidos"
                                  value={cuenta.bank_apellidos}
                                  
                                  
                                
                                className=" form-control" required="" onchange="cambiar(this.value)"/>

                                                                                
                                <label className="panther-retiro-label">Pais<span className="text-success  mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Identificacion Destinatario"
                                  id="pais"
                                  name="pais"
                                  
                                  value={cuenta.pais}
                                  
                                
                                className=" form-control" required=""/> 
                                <label className="panther-retiro-label"> {cuenta.tipo_identificacion}<span className="text-success  mt-5">*</span> </label>
                                <input 
                                  type="text"
                                  placeholder="Identificacion Destinatario"
                                  id="identificacion"
                                  name="identificacion"
                                  
                                  value={cuenta.identificacion}
                                  
                                
                                className=" form-control" required=""/>
                                     
                              </div> 



                                 
                                   </FormGroup>


                                                        
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter className="mt-3">
                                        <div className="row justify-content-center">
                                
                          
                                <div  className="col-sm-12 col-md-6 col-12">
                                <button className=" btn mt-0 btn-block btn-info btn-panther-power" onClick={ () => {
                              handleOpenEdit();
                              
                              guardarCancelarOferta( `${cuenta.id}`)
                              
                              setModalInfo(cuenta);
                              handleClose();
                              
                            }


                            }>
                                Borrar cuenta
                                </button>
                                </div>
                                    
                            
                            </div>
                          
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </Modal> 

                    <ModalCancelar 
                            open={openedit}
                            className="modal-responsive-fix"
                         
                        >
                            <div style={modalStyle} className={`  card ${classes.paper} Modal-oferta`}>
                      
                                
                            
                                            

                                  <Row > 

                                  <form onSubmit={onSubmitCancelar}>
                                            <Col sm="12" md="12">
                                                <CardHeader>

                                                  <Row>
                                                  <Col xs="9" sm="9" md="9" lg="9" className=" fix-cancelarcuenta-title ">
                                                  <h5 className="title display-4 mb-0 text-left">  Estas seguro que deseas desactivar tu beneficiario de CUENTAS NACIONALES? </h5>
                                                  </Col>

                                                  <Col xs="3" sm="3" md="3" lg="3" className="btn-cancel-justify-center">
                                                      <div className="cancel-box-btn">
                                                          <div className="cancel-btn-panther" onClick={ () => (
                                                            handleCloseEdit()
                                                          )}> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      </div>
                                                    </Col>

                                                  </Row>
                                                
                                                </CardHeader>
                                                  
                                                <Card className="card"> 
                                        
                                        
                                                <CardBody>
                                                    
                                                    <h1 className="cancel-ofert-body"> Estas a un solo Click de desactivar a  <span>{modalInfo.bank_client}</span> con el numero de cuenta  <span>{modalInfo.bank_type}</span> <span>{modalInfo.bank_account} </span>         
                                                            </h1>
                                                    <div className="acc-config-panther">

                                                    {/* <ul>

                                                            <li>NOMBRE DEL PROYECTO: <strong> {modalInfo.name}  </strong> </li>
                                                            <li>FECHA DE INICIO DE INVERSION:   <strong> {modalInfo.fecha_inicio}</strong>   </li>
                                                           
                                                            <li>FECHA FIN DE LA INVERSION: <strong> {modalInfo.fecha_fin}  </strong> </li>
                                                            <li>MONTO: <strong> {modalInfo.offers_amount} % </strong> </li>
                                                            <li>TASA DE RENDIMIENTO: <strong> {modalInfo.tax_referential_client} % </strong> </li>
                                                            <li>RENDIMIENTO ACTUAL: <strong> {modalInfo.rendimiento} $ </strong> </li>
                                                            <li>SALDO TOTAL: <strong> {modalInfo.saldo} % </strong> </li>
                                                         

                                                    </ul>  */}
                                                
                                                
                                                    </div>
                                                </CardBody> 

                                                <CardFooter>
                                                      <Container className="border-top border-info pt-5 "> 
                                                            <Row className="justify-content-center">

                                                          <Col sm="5" lg="5" xl="5" md="5">
                                                          <button
                                                                        title=""
                                                                        type="submit"
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1">
                                                                          {guardar}
                                                                        
                                                                      </button>
                                                          
                                                          </Col>


                                                      </Row>
                                                      
                                                      
                                                      </Container>



                                                      

                                                    </CardFooter>
                                                      </Card>
                                            </Col>  

                                            </form>
                                                    


                                          
                                         
                                    </Row>

                                    

                              
                                  

                                  

                              
                           
                            </div>
                        </ModalCancelar> 
                        { mensaje ? <Modal
                                
                                className={classes.modal}
                                open={modalmensaje} 
                                mensaje={mensaje}
                                                  
                                ListaRemesas={ListaRemesas}
                                closeAfterTransition
                                BackdropComponent={Backdrop}
                                BackdropProps={{
                                  timeout: 900,
                                }}
                              >
                                <Fade in={modalmensaje}
                                timeout={154}
                                >
                                  <div className="container">
                                <div className="row">
                                  <div className="col-12 d-flex justify-content-center">
                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
                                  
                                  <CardBody>
                                                      <Row className="d-flex justify-content-center">
                                                              <Col sm="12" md="6"> 
                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                              </Col>       
                                                      </Row>
                                                    </CardBody> 
                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info" onClick={ () => (
                                                                    ApiLoad(),
                                                                    handleCloseEdit(),
                                                                    handleClose(),
                                                                    guardarMensaje(null),
                                                                    ListaRemesas()
                                                                    
                                                                    
                                                                    
                                                                  )}>
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                   
     

                                                        </div>

                                                          </div>

                                                        </div>
                                                          </div>
                                                        </Fade>
                                                      </Modal> : null}



                                                              </>


    );
}
 
export default CuentasBeneficiariosRemesa;