import { useLottie } from "lottie-react";
import KYC from "../IconsAnimation/KYC.json";
 
const ConoceTuCliente = () => {
  const options = {
    animationData: KYC,
    loop: true,
    autoplay: true,
    height: 100,
  };
 
  const { View } = useLottie(options);
 
  return View;
};
 
export default ConoceTuCliente;