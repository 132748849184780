import React from 'react'
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 




const Mensaje = ({mensaje,guardarMensajeOpen,
    guardarMensaje}) => {



    const MensajeClose =  () => {

        guardarMensajeOpen(false);
        guardarMensaje('');

    }



    return ( 

            <>

            <div className={ `Mensaje `} >

            

                <div className="mensaje-box">
                <img src={LogoWhite} className=" logo-size-2" />
                <h2>{mensaje}</h2>
                <div className="btn-box-panther-mensaje">
                    <button className="btn-panther" onClick={MensajeClose}>
                        Aceptar
                    </button>

                </div>

                </div>
                




            </div>
            
            
            
            </>


     );
}
 
export default Mensaje;