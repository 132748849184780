import React from 'react';
import './TextSpinner.css'

const TextSpinner = () => {
    return ( 


        <>
         
        <div className="spinner-box-text">
           
            
        <div className="sk-cube-grid">
            <div className="sk-cube sk-cube1"></div>
            <div className="sk-cube sk-cube2"></div>
            <div className="sk-cube sk-cube3"></div>
            <div className="sk-cube sk-cube4"></div>
            <div className="sk-cube sk-cube5"></div>
            <div className="sk-cube sk-cube6"></div>
            <div className="sk-cube sk-cube7"></div>
            <div className="sk-cube sk-cube8"></div>
            <div className="sk-cube sk-cube9"></div>
        </div>


        </div>  

        <h4 className="text-info text-center font-fix-size">Cargando....</h4>

        </>

     );
}
 
export default TextSpinner;