import React , { useContext , useEffect, useState } from 'react';

import { Router, Route, Switch, Redirect } from "react-router-dom";

import AdminLayout from "layouts/Admin/Admin.js";
import ClienteLayout from "layouts/Cliente/Cliente.js";
import Main from "./layouts/Main";

import ReactGA from 'react-ga';


import LandingIndex from 'components/Landing/LadingIndex'

///
import Login from 'components/Auth/Login.js';
import NuevaCuenta from 'components/Auth/NuevaCuenta.js';
import ResetPassword from 'components/Auth/ResetPassword';

/// aqui vamos a llamar los componentes
import Activation from 'components/Auth/Activation.js';


import LoginPendiente from 'components/Auth/LoginPendiente.js';
import { createBrowserHistory } from "history";
import AlertaState from './context/alertas/alertaState.js';
import AuthState from './context/autenticacion/authState.js'; 

import AuthContext from './context/autenticacion/authContext'

import ModalProvider from './context/ModalContext/ModalContext'
import ModalPasswordProvider from './context/ModalContext/ModalPasswordContext'

import tokenAuth from './config/token' 
import RutaPrivada from './components/rutas/RutaPrivada'

import ThemeContextWrapper from "./components/ThemeWrapper/ThemeWrapper";
import BackgroundColorWrapper from "./components/BackgroundColorWrapper/BackgroundColorWrapper";

///vamos a importar el context

import SaldosState from './context/saldos/SaldosState'


import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';   

import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 

import Modal from '@material-ui/core/Modal' 



import { 
    Row,
    Col,
    CardBody,
    CardHeader,
    CardFooter,
  } from "reactstrap"; 



const useStyles = makeStyles((theme) => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      overflow:'hidden',
    height:'100%',
    
    background: '#0000005c',
      
    },
    paper: {
      background: '#001c52',
      border: '2px solid #04a1ff',
      boxShadow: theme.shadows[5],
      
      
    },
  }));
  
  



const token = localStorage.getItem('token');
if( token ) {
    tokenAuth(token);
}





const App = () => { 

    
            useEffect(() => {
            ReactGA.initialize('G-MVZLYKPKKN');
            ReactGA.pageview(window.location.pathname + window.location.search);
            },[])
    

    
    const hist = createBrowserHistory();
    

    

    return ( 
        <>

            
        <AlertaState> 

        <AuthState>
        <SaldosState>

                <ModalProvider>
                <ModalPasswordProvider>
                <ThemeContextWrapper>
                <BackgroundColorWrapper>
                <Router onUpdate={() => window.scrollTo(0, 0)} history={hist}>
                <Switch> 

                  
                    
                    
                    {/* <Route path="/cliente" render={props => <Main {...props} />} />  */}

                     {/* <Route path="/cliente" render={(props) => <Main {...props} />} />  */}
                    <Route exact path="/cotizador" component={LandingIndex} />
                    <Route exact path="/login-pendiente" component={LoginPendiente} />
                    <Route exact path="/portal" component={Login} />
                    <Route exact path="/nueva-cuenta" component={NuevaCuenta} />
                    <Route exact path="/activation/:token" component={Activation} />
                    <Route exact path="/forgot" component={ResetPassword} />
                    {/* <RutaPrivada exact path="/cliente" component={Main} /> */}
                   <RutaPrivada path="/cliente" component={ClienteLayout} /> 
            

                    {/* <Redirect from="/" to="/admin/dashboard" /> */}
                  <Redirect from="/" to="/portal" />
                  <Redirect from="/nueva-cuenta" to="/nueva-cuenta" />
                  {/* <Redirect from="/admin" to="../admin" />
                  <Redirect from="/webservice" to="/webservice" /> */}
                  {/* <Redirect from="/portal" to="/portal" /> */}
                  {/* <Redirect from="/activation" to="/activation" />  */}

                    
                </Switch>
                </Router> 
                </BackgroundColorWrapper>
                </ThemeContextWrapper>
                </ModalPasswordProvider>
                </ModalProvider>

        </SaldosState>

        </AuthState>

        </AlertaState> 


        </>

     );

    
}
 
export default App ;