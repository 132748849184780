import {
        
        OBTENER_USUARIO,
        LIMPIAR_ALERTA,
        USUARIO_AUTENTICADO,
        USUARIO_AUTENTICADO_ERROR,
        CERRAR_SESION,

        LOGIN_EXITOSO,
        LOGIN_ERROR,

        OLVIDO_PASSWORD,
        OLVIDO_PASSWORD_ERROR,

        REGISTRO_EXITOSO, 
        REGISTRO_ERROR,

        LISTAR_MIS_DATOS,
        LISTAR_MIS_DATOS_ERROR,

        CAMBIAR_PASSWORD,
        CAMBIAR_PASSWORD_ERROR,

        ACTUALIZAR_DATOS,
        ACTUALIZAR_DATOS_ERROR,

        LISTADO_CONTRATOS,
        LISTADO_CONTRATOS_ERROR,

        USER_STATUS,

        RETIRO_LIST,
        RETIRO_LIST_ERROR ,
        
        DEPOSIT_LIST,
        DEPOSIT_LIST_ERROR,

        CONTENIDO_PROYECTOS,
        BANNER_PROYECTOS,

        CONTENIDO_INVERSIONES,
        BANNER_INVERSIONES,

        CONTENIDO_OFERTAS,
        BANNER_OFERTAS,

        LISTA_NACIONALES,
        LISTA_NACIONALES_ERROR,

        LISTA_INTERNACIONALES,
        LISTA_INTERNACIONALES_ERROR,

        LISTA_REMESAS,
        LISTA_REMESAS_ERROR,

        TODOS_TUS_RETIROS_ERROR,
        TODOS_TUS_RETIROS,

        CANTIDAD_RETIROS,
        CANTIDAD_DEPOSITOS,

        TODOS_TUS_DEPOSITOS_ERROR,
        TODOS_TUS_DEPOSITOS,

        FILENAME,

        LISTA_OFERTAS,
        LISTA_OFERTAS_TOTALES_PAGINAS,

        LISTA_OFERENTE,
        LISTA_OFERENTE_TOTALES_PAGINAS,

        MIS_INVERSIONES_ACTIVAS,
        MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS,

        MIS_INVERSIONES_VENDIDAS,
        MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS,
        
        FILTROS_INVERSIONES, 
        FILTROS_PROYECTOS, 

        PROYECTOS_PRIMARIOS,

        PROYECTOS_PRIMARIOS_PAGINA,
        PROYECTOS_PRIMARIOS_CANTIDAD,
        PROYECTOS_PRIMARIOS_TOTAL_PAGINA,
        
        PROYECTOS_SECUNDARIOS, 

        PROYECTOS_SECUNDARIOS_CANTIDAD,
        PROYECTOS_SECUNDARIOS_PAGINA,
        PROYECTOS_SECUNDARIOS_TOTAL_PAGINA,

        FILTRO_PROYECTOS_PAIS,
        FILTRO_PROYECTOS_TIPO_OFERTA,
        FILTRO_PROYECTOS_EMISOR,
        FILTRO_PROYECTOS_ACTIVIDAD,

        SALDOS_DASH,
        ULTIMOS_MOVIMIENTOS,

        ULTIMOS_MOVIMIENTOS_CANTIDAD,
        ULTIMOS_MOVIMIENTOS_PAGE,
        ULTIMOS_MOVIMIENTOS_TOTAL_PAGE,

        ULTIMOS_MOVIMIENTOS_CSV, 
        ULTIMOS_MOVIMIENTOS_PDF, 

        DEMO_STATUS,

        COLLAPSE_TRUE,
        COLLAPSE_FALSE,

        PROYECTOS_PRIMARIOS_ALL,
        PROYECTOS_PRIMARIOS_ALL_CANTIDAD,

        CARGANDO_PANTHER,
        CARGANDO_PANTHER_X,

        CARGANDO_PANTHER_NULL,
        CARGANDO_PANTHER_X_NULL,

        BTN_PROTECT,

        

} from '../../types';

export default (state, action ) => {
    switch(action.type) { 



        case BTN_PROTECT:
            return {
                ...state,
                btn_protect:action.payload,
            }

        case USER_STATUS:
            return {
                ...state,
                status:action.payload,
            }



        // api 3
        case REGISTRO_EXITOSO: 
                        return {
                            ...state,
                            mensaje:action.payload,
                    }

    // api 1
        
        case LOGIN_EXITOSO:
            localStorage.setItem('token', action.payload.success.token);
            
            return {
                    ...state,
                    autenticado:true,
                    mensaje:null
                    
                    
            }

        case OBTENER_USUARIO:
           
            
            return {
                
                ...state,
                autenticado: true,
                usuario: action.payload,
                
            }
        
            // api 1
        case LOGIN_ERROR:

        // api 3
        case REGISTRO_ERROR:
            return {
                    ...state,
                    token:null,
                    mensaje: action.payload,
            } 

        case LIMPIAR_ALERTA:
            return {
                ...state,
                mensaje:null
            } 
        case USUARIO_AUTENTICADO:
            return{
                ...state,
                autenticado: true,
                usuario:action.payload,
                cargando: false
            } 

            case USUARIO_AUTENTICADO_ERROR:
                localStorage.removeItem('token');
                return {
                    ...state,
                    token: null,
                    usuario: null,
                    autenticado: null,
                    mensaje: action.payload, 
                    cargando: false
                }
         // api 2
        case OLVIDO_PASSWORD :
        return{
            ...state,
            mensaje: action.payload,

        } 
        // api 2
        case OLVIDO_PASSWORD_ERROR :
        return{
            ...state,
            mensaje: action.payload,

        }
         // api 4
        case LISTAR_MIS_DATOS :
        return{
            ...state,
            usuario:action.payload,

        }

        // api 4
        case LISTAR_MIS_DATOS_ERROR :
        return{
            ...state,
            token: null,
                    usuario: null,
                    autenticado: null,
                    mensaje: action.payload, 
                    cargando: false

        } 

        // api 5
        case CAMBIAR_PASSWORD :
        return{
            ...state,
            mensaje: action.payload,
            

        }

        // case 5
        case CAMBIAR_PASSWORD_ERROR :
            return{
                ...state,
                mensaje: action.payload,
    
            }

        //api 6
        case ACTUALIZAR_DATOS :
        return{
            ...state,
            mensaje: action.payload,

        }

        // api 6
        case ACTUALIZAR_DATOS_ERROR:
        return{
            ...state,
            mensaje: action.payload,
        } 

        // api 7
        case LISTADO_CONTRATOS:
        return{
            ...state,

        }

        // api 7
        case LISTADO_CONTRATOS_ERROR:
            return{
                ...state,
    
            }  


            case CERRAR_SESION:
                localStorage.removeItem('token', action.payload);
                return {
                        ...state,
                        token:null,
                        usuario:null,
                        autenticado: null,
                        mensaje: action.payload,
                        retiros:null,
                        depositos:null,
                        status:null,

                        saldos:null,

                        contenido_proyectos:null,
                        banner_proyectos:null,
                        contenido_inversiones:null,
                        banner_inversiones:null,
                        contenido_ofertas:null,
                        banner_ofertas:null,
                        
                        cuentas_internacionales:null,
                        cuentas_nacionales:null,
                        cuentas_remesas:null,

                        retiros_cantidad:null,
                        depositos_cantidad:null,

                        filename:null,

                        proyectos_primario:null,
                        proyectos_primario_all:null,
                        proyectos_primario_all_cantidad:null,
                        proyectos_secundarios:null,

                        proyectos_secundarios_cantidad:null,
                        proyectos_secundarios_total_pagina:null,
                        proyectos_secundarios_pagina:null,


                        proyectos_primario_total_pagina:null,
                        proyectos_primario_cantidad:null,
                        proyectos_primario_pagina:null,


                        mis_ofertas_inversor:null,
                        mis_ofertas_inversor_total_paginas:null,

                        mis_ofertas_oferente:null,
                        mis_ofertas_oferente_total_paginas:null,

                        filtro_inversiones:null,
                        filtro_proyectos:null,

                        mis_inversiones_activas:null,
                        mis_inversiones_activas_totales_paginas:null,
                        
                        mis_inversiones_vendidas:null,
                        mis_inversiones_vendidas_totales_paginas:null,

                        proyectos_primario:null,
                        proyectos_secundarios:null,
                        proyectos_primario_total_pagina:null,

                        filtro_proyectos_pais:null,
                        filtro_proyectos_tipo_oferta:null,
                        filtro_proyectos_emisor:null,
                        filtro_proyectos_actividad:null,
                        
                        ultimos_movimientos:null,
                        ultimos_movimientos_cantidad:null,
                        ultimos_movimientos_page:null,
                        ultimos_movimientos_total_page:null,
                        ultimos_movimientos_csv:null,
                        ultimos_movimientos_pdf:null,

                        panther_load:null,
                        recargandoapp:null,
                        
                        collapse:false,


                } 


                case DEPOSIT_LIST:
                    return{
                        ...state,
                        depositos:action.payload,
            
                    }

                    case DEPOSIT_LIST_ERROR:
                        return{
                            ...state,
                            depositos:action.payload,
                 
                  } 



                  case RETIRO_LIST:
                    return{
                        ...state,
                        retiros:action.payload,
            
                    }

                    case RETIRO_LIST_ERROR:
                        return{
                            ...state,
                            retiros:action.payload,
                
                        }  

                        //// contenido 


                        case CONTENIDO_PROYECTOS:
                        return{
                            ...state,
                            contenido_proyectos:action.payload,
                            banner_proyectos:null,
                
                        } 

                        case BANNER_PROYECTOS:
                        return{
                            ...state,
                            contenido_proyectos:null,
                            banner_proyectos:action.payload,
                
                        }  

                        case CONTENIDO_INVERSIONES:
                        return{
                            ...state,
                            banner_inversiones:null,
                            contenido_inversiones:action.payload,
                
                        }  

                        case BANNER_INVERSIONES:
                        return{
                            ...state,
                            contenido_inversiones:null,
                            banner_inversiones:action.payload,
                
                        } 
                         
                        case CONTENIDO_OFERTAS:
                        return{
                            ...state,
                            contenido_ofertas:action.payload,
                            banner_ofertas:null,
                
                        }  

                        case BANNER_OFERTAS:
                        return{
                            ...state,
                            contenido_ofertas:null,
                            banner_ofertas:action.payload,
                
                        } 

                        //////

                        case LISTA_NACIONALES:
                                return{
                                    ...state,
                                    cuentas_nacionales:action.payload,
                                    
                                } 

                          case LISTA_NACIONALES_ERROR :
                                        return{
                                            ...state,
                                            token: null,
                                                    usuario: null,
                                                    autenticado: null,
                                                    mensaje: action.payload, 
                                                    cargando: false

                             } 

                             case LISTA_INTERNACIONALES:
                                return{
                                    ...state,
                                    cuentas_internacionales:action.payload,
                                    
                                } 

                          case  LISTA_INTERNACIONALES_ERROR :
                                        return{
                                            ...state,
                                            token: null,
                                                    usuario: null,
                                                    autenticado: null,
                                                    mensaje: action.payload, 
                                                    cargando: false

                             } 

                             case  LISTA_REMESAS:
                                return{
                                    ...state,
                                    cuentas_remesas:action.payload,
                                    
                                } 

                          case LISTA_REMESAS_ERROR :
                                        return{
                                            ...state,
                                            token: null,
                                                    usuario: null,
                                                    autenticado: null,
                                                    mensaje: action.payload, 
                                                    cargando: false

                             }  



                         case TODOS_TUS_RETIROS:

                            return{
                                ...state,
                                retiros:action.payload,
                                
                            } 


                            case TODOS_TUS_RETIROS_ERROR:
                                return{
                                    ...state,
                                    retiros:null,
                                    autenticado: null,
                                    token: null,
                                    mensaje: action.payload, 
                                }

                                case CANTIDAD_RETIROS:
                                    return{
                                        ...state,
                                        retiros_cantidad:action.payload,
                                    }
                            
                                    case TODOS_TUS_DEPOSITOS:

                                        return{
                                            ...state,
                                            depositos:action.payload,
                                            
                                        } 
            
            
                                        case TODOS_TUS_DEPOSITOS_ERROR:
                                            return{
                                                ...state,
                                                depositos:null,
                                                autenticado: null,
                                                token: null,
                                                mensaje: action.payload, 
                                            }
            
                                            case CANTIDAD_DEPOSITOS:
                                                return{
                                                    ...state,
                                                    depositos_cantidad:action.payload,
                                                } 


                                                case FILENAME:
                                                    return {
                                                        ...state,
                                                        filename:action.payload,
                                                    }
                                            ////////
                                                case LISTA_OFERTAS:
                                                    return {
                                                        ...state,
                                                        mis_ofertas_inversor:action.payload,
                                                    }

                                                    case LISTA_OFERTAS_TOTALES_PAGINAS:
                                                        return {
                                                            ...state,
                                                            mis_ofertas_inversor_total_paginas:action.payload,
                                                        } 

                                                        case FILTROS_INVERSIONES:
                                                            return {
                                                                ...state,
                                                                filtro_inversiones:action.payload,
                                                            }

                                                            case FILTROS_PROYECTOS:
                                                                return {
                                                                    ...state,
                                                                    filtro_proyectos:action.payload,
                                                                }
                                                        /////////////

                                                case LISTA_OFERENTE:
                                                        return {
                                                            ...state,
                                                            mis_ofertas_oferente:action.payload,
                                                        } 

                                                        case LISTA_OFERENTE_TOTALES_PAGINAS:
                                                            return {
                                                                ...state,
                                                                mis_ofertas_oferente_total_paginas:action.payload,
                                                            } 

                                                    //////
                                                    case MIS_INVERSIONES_ACTIVAS:
                                                        return {
                                                            ...state,
                                                            mis_inversiones_activas:action.payload,
                                                        }    

                                                        case MIS_INVERSIONES_ACTIVAS_TOTALES_PAGINAS:
                                                            return {
                                                                ...state,
                                                                mis_inversiones_activas_totales_paginas:action.payload,
                                                            }

                                                            case MIS_INVERSIONES_VENDIDAS_TOTALES_PAGINAS:
                                                                return {
                                                                    ...state,
                                                                    mis_inversiones_vendidas_totales_paginas:action.payload,
                                                                }





                                                    case MIS_INVERSIONES_VENDIDAS :
                                                        return {
                                                            ...state,
                                                            mis_inversiones_vendidas:action.payload,
                                                        }

                                                    case PROYECTOS_PRIMARIOS:
                                                        return {
                                                            ...state,
                                                            proyectos_primario:action.payload,
                                                        }

                                                    case PROYECTOS_PRIMARIOS_ALL:
                                                        return{
                                                            ...state,
                                                            proyectos_primario_all:action.payload,
                                                        }

                                                        case PROYECTOS_PRIMARIOS_ALL_CANTIDAD:
                                                            return {
                                                                ...state,
                                                                proyectos_primario_all_cantidad:action.payload,
                                                            }



                                                    case PROYECTOS_SECUNDARIOS:
                                                        return {
                                                            ...state,
                                                            proyectos_secundarios:action.payload,
                                                        }

                                                        case PROYECTOS_PRIMARIOS_CANTIDAD:
                                                        return {
                                                            ...state,
                                                            proyectos_primario_cantidad:action.payload,
                                                        }

                                                        case PROYECTOS_SECUNDARIOS_CANTIDAD:
                                                        return {
                                                            ...state,
                                                            proyectos_secundarios_cantidad:action.payload,
                                                        }
                                                        case PROYECTOS_PRIMARIOS_PAGINA:
                                                            return {
                                                                ...state,
                                                                proyectos_primario_pagina:action.payload,
                                                            }

                                                            case  PROYECTOS_SECUNDARIOS_PAGINA:
                                                                return {
                                                                    ...state,
                                                                    proyectos_secundarios_pagina:action.payload,
                                                                }

                                                            case PROYECTOS_PRIMARIOS_TOTAL_PAGINA:
                                                                return {
                                                                    ...state,
                                                                    proyectos_primario_total_pagina:action.payload,
                                                                }

                                                                case PROYECTOS_SECUNDARIOS_TOTAL_PAGINA:
                                                                    return {
                                                                        ...state,
                                                                        proyectos_secundarios_total_pagina:action.payload,
                                                                    }




                                                            

                                        case FILTRO_PROYECTOS_EMISOR:
                                            return{
                                             ...state,
                                            filtro_proyectos_emisor:action.payload,
                                            }

                                             
                                            case FILTRO_PROYECTOS_PAIS:
                                            return{
                                             ...state,
                                            filtro_proyectos_pais:action.payload,
                                            }

                                            case FILTRO_PROYECTOS_TIPO_OFERTA:
                                            return{
                                             ...state,
                                            filtro_proyectos_tipo_oferta:action.payload,
                                            } 
                                            case FILTRO_PROYECTOS_ACTIVIDAD:
                                                return{
                                                    ...state,
                                                    filtro_proyectos_actividad:action.payload,
                                                }



                        case SALDOS_DASH:
                            return{
                                ...state,
                                saldos:action.payload,
                            } 


                            //// AQUI VANMOS A COLOCAR LAS ACCIONES DE LOS ;ULTIMOS MOVIMIENTOS

                            case ULTIMOS_MOVIMIENTOS:
                            return{
                                ...state,
                                ultimos_movimientos:action.payload,
                            } 


                            case ULTIMOS_MOVIMIENTOS_CANTIDAD:
                            return{
                                ...state,ultimos_movimientos_cantidad:action.payload,
                                
                            } 

                            case ULTIMOS_MOVIMIENTOS_PAGE:
                            return{
                                ...state,
                                ultimos_movimientos_page:action.payload,
                            } 

                            case ULTIMOS_MOVIMIENTOS_TOTAL_PAGE:
                            return{
                                ...state,
                                ultimos_movimientos_total_page:action.payload,
                            } 

                            case ULTIMOS_MOVIMIENTOS_CSV:
                            return{
                                ...state,
                                ultimos_movimientos_csv:action.payload,
                            } 
                            
                            case ULTIMOS_MOVIMIENTOS_PDF:
                            return{
                                ...state,
                                ultimos_movimientos_pdf:action.payload,
                            } 
                ///// aqui vamos a colocar el valor del demo
                case DEMO_STATUS:
                    return {
                        demo_status:"active",
                        } 
                case COLLAPSE_TRUE:
                return{
                    ...state,
                    collapse:action.payload,
        
                }

                case COLLAPSE_FALSE:  
                return{
                    ...state,
                    collapse:action.payload,
        
                } 


                ////// aqui vamos a colocar la recarga de la app y el loader

                ////LOADER////LOADER
                case CARGANDO_PANTHER:
                return{
                    ...state,
                    panther_load:action.payload,
        
                }
                case CARGANDO_PANTHER_X:
                return{
                    ...state,
                    panther_load:action.payload,
        
                } 
                ////LOADER////LOADER

                case CARGANDO_PANTHER_NULL:
                return{
                    ...state,
                    recargandoapp:action.payload,
        
                }
                case CARGANDO_PANTHER_X_NULL:
                return{
                    ...state,
                    recargandoapp:action.payload,
                }

                /// SI LA APLICACION ESTA RECARGANDO O NO 




                            





                                                       





                                        




    

    


        default: 
            return state;
    }


}