import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import Inversion from './Inversion';
import clienteAxios from '../../config/axios';
import DataTable, {theme} from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import Spinner from '../spinners/Spinner'

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


// importacion del modal
import Modal from '@material-ui/core/Modal'
import ModalMensaje from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles' 

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/// react table import

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import * as ReactBootStrap from 'react-bootstrap';  


import AuthContext from '../../context/autenticacion/authContext'

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
} from "reactstrap"; 

// aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 

/// chart
let chartPie = {
  data4: (canvas) => {
    let ctx = canvas.getContext("2d");
    
    
    let modalInfo = [];
    let enable = [];
    let investment = [];
    
    // for (const dataObj of modalInfo) {
    //   enable.push(parseInt(dataObj.percentage_enable))
    //   investment.push(parseInt(dataObj.percentage_investment))
    // }
    

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    
    

    return {
      type: 'doughnut',
      
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [10,80 ],
          weight: 300,
        }
      ] ,

      labels: ['Monto Disponible','Monto Invertido'],


    };
  },
  options: chart_pie_options
}; 



/// ahora va el option del chart
let chart_pie_options = {
  maintainAspectRatio: true,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        DoughnutPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "#00ffc33d",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        Percentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    
    margin: 2,
    
    boxShadow: theme.shadows[5],
    
    borderRadius: 10,
  },
}));






const ListaOfertas = ({apiload, ApiLoad}) => {  

  
  ///aqui vamos a exrportar el context
  const authContext = useContext(AuthContext);
  const { TodosProyectosPrimario, TodosProyectosSecundario, TodosInversiones, TodosOferentes, TodosOfertas, mis_ofertas_inversor, mis_ofertas_inversor_total_paginas,filtro_inversiones,Movimientos } = authContext;  

  const [proyectoti, guardarProyectotis] = useState([{
    description:'',
    imagen:'',
    nrocontrato:'',
    currency_code:'',
    name:'',
    tax_referential_client:'',
    end_date:'',


  }])

  const [inversiones, guardarInversiones] = useState([{
    amount:'', 
    amount_investment:'', 
    valorventa:'',
    amount_reserved:'', 
    approval_date:'', 
    approved_by:'', 
    category:'', 
    category_id:'', 
    code:'', 
    contract_type:'', 
    country_iso:'', 
    created_at:'', 
    created_by:'',
    cuenta_negociador:'',
     currency_code:'', 
     date_max_investment:'', 
     delay_percentage:'', 
     email_sent:'', 
     end_date:'', 
     fee_total_contract:'', 
     file:'', 
     finished_date:'', 
     frequency_payment:'', 
    id:'', 
    issuer:'', 
    loan_type:'', 
    min_amount_start:'',
    name:'' , 
    nrocontrato:'', 
    oferente:'', 
    oferente_id:'', 
    quantity:'', 
    quantity:'', 
    risk:'', 
    start_date:'',
    status:'', 
    tax_price_cuota:'', 
    tax_referential_client:'', 
    tax_referential_panther:'',
    total_inversores:'', 
    updated_at:'', 
    updated_by:'', 
    warranty:'',
    disponible: '',
    rendimiento: '',
    vender:'',
    saldo:'',
    project:{
      description:'',
      imagen:'',
      nrocontrato:'',
      currency_code:'',
      name:'',
      tax_referential_client:'',
      end_date:'',
  
  
    },
    fecha_inicio:'',
    fecha_fin:'',
    offers_amount:'',
  }]); 

     /// vamos a hacer la busqueda

     const [ busqueda, guardarBusqueda ] = useState({
      proyecto:'',
      vencimiento:'',
      monto:'',
      
  });
  
  
  
  const [ proyecto, guardarProyecto ] = useState([]);
  
  
  
 /// aqui estamos colocando el contador de pagina, numero
 const [ paginaactualcount, guardarPaginaActualCount ] = useState(1);
 // aqui estamos asignando el contador numero a la variable,mediante una cadena de  texto
 const [ paginaactual, guardarPaginaActual ] = useState({
    page:paginaactual,
   
 }); 

 

 const busquedaop = {...busqueda, ...paginaactual } ;

 

   
const [totales, guardarTotales ] = useState({
  cantidad:'',
  total_monto:'',
  total_rendimiento:'',
  total_saldo:'',
  
});
  
  
  
  
  


  useEffect( (props) => { 

    const consultarApiFiltros = async () => { 


      guardarProyecto(filtro_inversiones);
     
     
    }

    consultarApiFiltros();





    const consultarApiInversiones = async () => {
      const {amount, amount_investment, amount_reserved, 
        approval_date, approved_by, category, category_id, 
        code, contract_type, country_iso, created_at, created_by,
        cuenta_negociador, currency_code, date_max_investment, delay_percentage
        ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
        id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
        risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
        total_inversores, updated_at, updated_by, warranty, disponible,rendimiento, project,vender,fecha_inicio,fecha_fin,offers_amount,valorventa
      
      } = inversiones; 

   


      
      
          guardarInversiones(mis_ofertas_inversor)
          guardarTotales(mis_ofertas_inversor_total_paginas)




        // if (busquedaop) { 

        //   const respuesta = await clienteAxios.post('/api/client/offers', busquedaop)
        //    guardarInversiones(respuesta.data.data.datos)
        //     guardarTotales(respuesta.data.data.totales)
        //     guardarPaginaActual({
        //       page:`${paginaactualcount}`,
        //     }) 

        // }

      // guardarProyectotis(respuesta.data.data.proyect)
      
      
    }

    consultarApiInversiones()
    
    
    

  },[!apiload,mis_ofertas_inversor,mis_ofertas_inversor_total_paginas]);

  // [busqueda,paginaactual]



  // vamos a hacer la parte de venta
  const [venta, guardarVenta] = useState({

    amount:'',
    type: '',
    date:'',
    
    
    });  


    const [cancelaroferta, guardarCancelarOferta] = useState("");

  // vamos a pasarle el la informacion de la fila al modal
  const [modalInfo, setModalInfo] = useState([]);
  
  

  

  // vamos a extraer el context de emergencia para guardar los valores de la inversion 
  // despues 

  
  // vamos a hacer que el mensaje salga
  const [mensaje, guardarMensaje] = useState('');

  
   // configuracion del modal de material-ui
   const [ modalStyle ] =useState(getModalStyle);
   const [open, setOpen] =useState(false);

   const classes = useStyles();

   const handleOpen = () => {
       setOpen(true);
   } 

   const handleClose = () => {
       setOpen(false);
       guardarMensaje(null);
   }







  

    // estamos creando el state 
    const [contratomonto, guardarContratoMonto] = useState({
      amount:''
      
      
}); 

  

  const [project_id, guardarProjectid] = useState({
    project_id:''

  })

  const [projectid, guardarProjectidTrue] = useState({
    id:''

  })

  // extraer usuario del state

  const {amount} = parseInt(contratomonto) 
  
 

  


  const crearInversion = async inversion => {
       
    const {contratomonto,projectid} = inversion;
    
   
    
    var amount=document.getElementById('amount').value;
  
    try {
        const respuesta = await clienteAxios.post('/api/client/investments/create',{amount,project_id});
        
      
      
      guardarMensaje(respuesta.data.message); 

      setTimeout(() => {
        guardarMensaje(null);
    },5000);
        
    } catch (error) {
      guardarMensaje(error.response.data.message);
      setTimeout(() => {
        guardarMensaje(null);
        setOpen(false);
    },5000);
       
    }
} 


const borrarOferta = async () => {
       
  
  
  try {
    const respuesta = await clienteAxios.post(`/api/client/offers/cancel/${cancelaroferta}`);
      
    elGuardarCargando(false);
    
    guardarMensaje(respuesta.data.message); 

    
  } catch (error) {
    elGuardarCargando(false);
    guardarMensaje(error.response.data.message);
 
  }
} 








const onChangeFilter = e => {

  // voy a colocar guardar usuario en el state

  guardarBusqueda({
      ...busqueda,
      [e.target.name] : e.target.value

  })
}






  const onChange = e => {

      // voy a colocar guardar usuario en el state
      guardarVenta({
         
        [e.target.name] : (e.target.value)
    })


      guardarContratoMonto({
         
          [e.target.name] : parseFloat(e.target.value)
      })
  } 


  const [ elcargando, elGuardarCargando] = useState(false);

  const elguardar = ( elcargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Cancelar Ahora
    </Fragment> 




  const onSubmitCancelar = e => {
    elGuardarCargando(true);
      e.preventDefault(); 

      
      
      borrarOferta(cancelaroferta);

  }

   

  /// cuando el usuario quiere iniciar seseion
  const onSubmit = e => {
      e.preventDefault(); 

      

      crearInversion({ contratomonto,project_id});

  }

   
  const [ mostrar, setMostrar ] = useState(false);
    
    
      
    return (  
    <Fragment> 

    <Row> 
                <Col md="12"> 
                <form  >
                    <Card className="p-4">
                            <CardHeader> 
                            <CardTitle className="display-4" tag="h2" >Listado de ofertas
                            
                            </CardTitle>
                                
                            </CardHeader>
                            <CardBody>
                                    <Row>
                                        <Col md="6">
                                        <select className="form-control text-center mb-3" 
                                        id="proyecto"
                                        name="proyecto"
                                        onChange={onChangeFilter}
                                        value={busqueda.proyecto} > 

                                            <option value=""> - - -  Nombre del Proyecto - - - </option>
                                         {proyecto.map(proyectos => (
                                            <option
                                                key={proyectos.name}
                                                value={proyectos.name}

                                            >{proyectos.name}</option>

                                         )



                                         )}
                                            
                                        </select>
                                        </Col>
                        
                                  
                                        <Col md="6">
                                        <select className="form-control  text-center mb-3" 
                                                onChange={onChangeFilter}
                                                value={busqueda.vencimiento}
                                                id="vencimiento"
                                                name="vencimiento"

                                                >

                                        <option  value=""> - - - Periodo de Vencimiento - - -</option>
                                            <option value="1">Hoy</option>
                                            <option value="2"> Próximos 7 días</option>
                                            <option value="3"> Próximos 30 días</option>
                                            <option value="4">Próximos 360 días</option>
                                        </select>
                                            
                                        </Col>
                                    </Row>
                            </CardBody>
                            {/* <CardFooter className="d-flex justify-content-end">
                            <Button  className="btn-block  btn-radius-panther mt-0" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar" type="submit">
                                Buscar
                            </Button>
                            </CardFooter> */}



                    </Card> 
                    </form>
                </Col>
            </Row>
             
      
    
       
                        <Container fluid>

                        
       <Row> 
         


       <Col sm="12" xl="12" lg="12" md="12" className="p-0">
        <Divdesktop> 


          {totales.cantidad > 0 ? 
            <>


        <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                
      <div>
     
          <CardBody className="tabla_ofertas_final"> 

        

          <div className="table-responsive ps text-center"> 
              <table className="table table-bordered">
             
              <thead>
                 <tr> 
                            

                     <th className="" data-tooltip="Aqui encontraras el nombre del proyecto">
                     <strong className="line-fix">
                     <i class="tim-icons icon-bank p-2"/>NOMBRE <br/> DE PROYECTO
                     </strong>
                     </th>

                     
                     <th className="" data-tooltip="Fecha de inicio de la venta "> 
                     
                     <strong className="line-fix">
                      <i class="tim-icons icon-calendar-60 p-2"/> FECHA <br/>DE VENTA
                     </strong>
                     
                     </th>
                     <th className=""  data-tooltip="Fecha de vencimiento que tu definiste al vender"> 

                     <strong className="line-fix">
                      <i class="tim-icons icon-calendar-60 p-2"/> FECHA<br/> FIN DE VENTA
                     </strong>
                     
                     </th>
                     <th className="" data-tooltip="Aqui encontraras el monto de compra">

                     <strong className="line-fix" >
                      <i class="tim-icons icon-coins p-2"/> MONTO ( $ )
                     </strong>
                     
                     </th>
                     
                     <th className="" data-tooltip="Dinero recolectado hasta la fecha. ¡tus ganancias!">

                     <strong className="line-fix" >
                     <i class="tim-icons icon-chart-bar-32 p-2"/> RENDIMIENTO<br/> ACTUAL ( $ )
                     </strong>
                     
                     </th> 
                     <th className="" data-tooltip="el valor total de tu inversion hasta la fecha">

                     <strong className="line-fix" >
                     <i class="tim-icons icon-coins p-2"/>SALDO
                     </strong>
                     
                     </th> 
                     <th className="" data-tooltip="Valor final de venta"> 
                     <strong className="line-fix" > 
                     <i class="tim-icons icon-chart-bar-32 p-2 "/> VALOR  <br/> DE VENTA ( $ )     
                     </strong>
                     
                     </th>
                      <th className="p-2" 
                      data-tooltip="Cancela tu oferta en el mercado secundario">

                      <strong className="line-fix" >
                      <i class="tim-icons icon-tap-02 p-2"/> CANCELAR
                      </strong>

                    </th> 

                 
                 </tr>
               </thead> 
             
               <tbody> 

              {inversiones.map(inversion => (
                <>
                 
             
                            <tr key={inversion.id}>
                              <td>
                                <small className="line-fix">
                                  {inversion.name}
                                </small>
                              </td>
                              <td>
                                <small className="line-fix">
                                  {inversion.fecha_inicio}
                                </small>
                              </td>
                              <td>
                                <small className="line-fix">
                                   {inversion.fecha_fin}
                                </small>
                              </td>
                              <td>
                                 {inversion.offers_amount} 
                              </td>
                              

                              <td>
                                 {inversion.rendimiento}
                              </td>
                              <td>
                                {inversion.saldo}
                              </td> 
                              <td>
                                 {inversion.valorventa}
                              </td>

                           <td> 
                           <button className="btn btn-primario btn-block btn-panther-power-sm" onClick={ () => {
                                  handleOpen();
                                  
                                  guardarCancelarOferta( `${inversion.id}`)
                                  
                                  setModalInfo(inversion);
                                  
                                }


                                }>
                                Cancelar
                                </button>
                              
                              </td> 
                             
                            </tr>  
                              
         
              </>
                        ) )}

                    

        
                        </tbody> 

                        <tfoot>
                 <tr>
                     <th className="sortable">

                       TOTALES                      

                     </th>
                     <th className="sortable"> 
                     
                     
                     </th>
                     <th className="sortable"> 
                     
                     
                     </th>
                     <th className="sortable">

                     {totales.total_monto}
                     
                     </th>
                     <th className="sortable"> 
                     
                     </th>
                     <th className="sortable">
                     {totales.total_rendimiento}
                     </th> 
                     <th className="sortable">

                     {totales.total_saldo}
                     
                     </th> 

                     
                     <td>
                              
                              </td>
                             

 
                 </tr>
               </tfoot> 

                       


              </table>


          </div>


           
          </CardBody>
          {/* <CardFooter>
              <Container className=" pt-5 "> 
                    <Row className="justify-content-center">
                  { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                        <Col sm="5" lg="5" xl="5" md="5">
                            <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block   btn-panther-power mt-1"
                                disabled
                              > Primera Página </button> </Col>: (
                  <Col sm="5" lg="5" xl="5" md="5">
                      <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                onClick={() => 
                                  
                                  guardarPaginaActualCount(paginaactualcount - 1) 
                                              
                                
                                }
                              > Anterior &laquo; </button>
                  </Col>
                  )}
                  <Col md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                  <span className="box-pagination btn-block">
                            {paginaactualcount}
                  </span>
                 
                  </Col>

                  <Col sm="5" lg="5" xl="5" md="5">
                  <button
                                title=""
                                type="button"
                                className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                onClick={() => guardarPaginaActualCount(paginaactualcount + 1) }
                              > Siguiente &raquo; </button>
                  
                  </Col>


              </Row>
              
              
              </Container>



              

            </CardFooter> */}
           

        </div> 
       
        </Card>  


        </>  : 
            
            
            <CardBody className="justify-content-center d-flex p-0">
            <Row className="box-depositos-seleccion-alerta justify-content-center p-0"> 
                                      <Col md="12" className=" panther-open-titulo-alerta border-panther p-0"> 

                                    {totales.cantidad === 0 ?  <> 
                                    
                                    <i className="tim-icons icon-chart-pie-36 animation-icon-in"/>
                                            <h2 className="animation-icon-in-dos">¡Ups, aún sin ofertar!</h2>
                                              <h3 class="animate-raya-03">Todavía ninguna de sus inversiones las ha ofertado en el mercado secundario</h3>
                                              <p className="text-center animation-icon-in-cuatro">Puede ofertar sus inversiones en el mercado secundario para que los demás inversionistas le compren a usted </p>  </> :
                                              <Spinner/>
                                              
                                              }
            </Col>
                                          
            </Row>  

            </CardBody>
                                      
                                      
                                      }



        
      </Divdesktop> 

      <DivMobile> 

      {totales.cantidad > 0 ? 
            <>

      <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                
                                                <div>
                                      

                                                    <CardBody>
                                                          <Container> 

                                                            <Row>




                                                                {inversiones.map(inversion => (
                                                                  <>
                                                              <Col xs="12" sm="6" md="4" key={inversion.id} >
                                                                <Card className="card-responsive-bg"> 
                                                                  <CardHeader>
                                                                  <Row>
                                                                  <Col xs="6" sm="6" md="6">
                                                                      <p className="card-responsive-01"> <strong>  Fecha de Inversion:</strong> <br/>
                                                                      {inversion.date_investment}</p> 
                                                                        </Col>
                                                                        <Col xs="6" sm="6" md="6">
                                                                        <p className="card-responsive-02"> <strong>  Referencia:</strong><br/> 
                                                                        {inversion.end_date}</p>
                                                                        </Col>
                                                                        </Row>
                                                                  </CardHeader>

                                                                  <CardBody className="card-responsive-07">
                                                                      <Row> 

                                                                      <Col xs="12" sm="12" md="12">
                                                                              <p className="card-responsive-03"><strong>  Nombre del Proyecto :</strong> <br/> {inversion.name}</p>
                                                                          </Col>
                                                                          
                                                                        
                                                                          <Col xs="3" sm="3" md="3">
                                                                              <p className="card-responsive-03"><strong className="line-fix">  <i class="tim-icons icon-bank pr-1"/> Monto:</strong> <br/> {inversion.amount}</p>
                                                                          </Col>
                                                                          <Col xs="3" sm="3" md="3">
                                                                              <p className="card-responsive-03"><strong>Valor venta:</strong> <br/> {inversion.valorventa}</p>
                                                                          </Col>
                                                                          <Col xs="3" sm="3" md="3">
                                                                          <p className="card-responsive-04"><strong> Rendimiento :</strong> <br/> {inversion.rendimiento}</p>
                                                                          </Col>

                                                                          <Col xs="3" sm="3" md="3">
                                                                          <p className="card-responsive-04"><strong> Saldo Total:</strong> <br/> {inversion.saldo}</p>
                                                                          </Col>

                                                                      </Row>


                                                                  </CardBody>


                                                                  <CardFooter>
                                                                      <p className="card-responsive-05"><strong>  <i class="tim-icons icon-badge p-2"/> Vender:</strong><br/> {inversion.vender}</p>
                                                                  </CardFooter>
                                                           
                                                       
                                                                     
                                                                        
                                                                      
                                                                        
                                                   
                                                                </Card>
                                                              </Col>
                                                          </>


                                                                  ) )}
                                          
                                                              
                                                              





                                                            </Row>
                                                          </Container>
                                                    </CardBody>

{/* 
                                                      <CardFooter>
                                                        <Container className="border-top border-info pt-5 "> 
                                                              <Row className="justify-content-center">
                                                            { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                                                                  <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                      <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block   btn-panther-power mt-1"
                                                                          disabled
                                                                        > Primera Página </button> </Col>: (
                                                            <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={() => 
                                                                            
                                                                            guardarPaginaActualCount(paginaactualcount - 1) 
                                                                                        
                                                                          
                                                                          }
                                                                        > Anterior &laquo; </button>
                                                            </Col>
                                                            )}
                                                            <Col xs="2" md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                                                            <span className="box-pagination btn-block">
                                                                      {paginaactualcount}
                                                            </span>
                                                           
                                                            </Col>
                                          
                                                            <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                            <button
                                                                          title=""
                                                                          type="button"
                                                                          className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                          onClick={() => guardarPaginaActualCount(paginaactualcount + 1) }
                                                                        > Siguiente &raquo; </button>
                                                            
                                                            </Col>
                                          
                                          
                                                        </Row>
                                                        
                                                        
                                                        </Container>
                                          
                                          
                                          
                                                        
                                          
                                                      </CardFooter> */}
                                          
                                          
                                          
                                          
                                                  </div> 
                                                 
                                                  </Card> 


                                                  </>  : 
            
            
            
            <Row className="box-depositos-seleccion-alerta justify-content-center"> 
                                      <Col md="12" className=" panther-open-titulo-alerta border-panther"> 

                                    {totales.cantidad === 0 ?  <> 
                                    
                                    <i className="tim-icons icon-chart-pie-36 animation-icon-in"/>
                                            <h3 className="animation-icon-in-dos">¡Ups, aún estás sin ofertar!</h3>
                                              <h3 class="animate-raya-03">Todavía nunguna de tus inversiones las haz ofertado en el mercado secundario</h3>
                                              <p className="text-center animation-icon-in-cuatro">Puede ofertar sus inversiones en el mercado secundario para que los demás inversionistas le compren a usted! </p>  </> :
                                              <Spinner/>
                                              
                                              }
            </Col>
                                          
            </Row>  
                                      
                                      
                                      }




      </DivMobile>



      
      
      </Col>




       </Row> 
                                      
       </Container>
   


                      <Modal 
                            open={open}
                            className="modal-responsive-fix"
                            onClose={ () => {
                                handleClose();
                                
                                
                            } }
                        >
                            <div style={modalStyle} className={`  card ${classes.paper} Modal-oferta`}>
                      
                                
                            
                                            

                                  <Row > 

                                  <form onSubmit={onSubmitCancelar}>
                                            <Col sm="12" md="12">
                                                <CardHeader>

                                                  <Row>
                                                  <Col xs="9" sm="9" md="9" lg="9" className=" fix-cancelarcuenta-title ">
                                                  <h5 className="title display-4 mb-0 text-left">  Estas seguro que desear Cancelar tu inversion ofertada en el mercado secundario? </h5>
                                                  </Col>

                                                  <Col xs="3" sm="3" md="3" lg="3" className="btn-cancel-justify-center">
                                                      <div className="cancel-box-btn">
                                                          <div className="cancel-btn-panther" onClick={ () => (
                                                            handleClose()
                                                          )}> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      </div>
                                                    </Col>

                                                  </Row>
                                                
                                                </CardHeader>
                                                  
                                                <Card className="card"> 
                                        
                                        
                                                <CardBody>
                                                    
                                                    <h1 className="cancel-ofert-body"> Tu proyecto con el nombre <span>{modalInfo.name}</span> ha incrementado su valor en <span>{modalInfo.rendimiento} </span>         
                                                    hasta la fecha, su inversion tiene un valor total de  <span>{modalInfo.saldo}</span>        </h1>
                                                    <div className="acc-config-panther">

                                         
                                                
                                                
                                                    </div>
                                                </CardBody> 

                                                <CardFooter>
                                                      <Container className="border-top border-info pt-5 "> 
                                                            <Row className="justify-content-center">

                                                          <Col sm="5" lg="5" xl="5" md="5">
                                                          <button
                                                                        title=""
                                                                        type="submit"
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1">
                                                                          {elguardar}
                                                                        
                                                                      </button>
                                                          
                                                          </Col>


                                                      </Row>
                                                      
                                                      
                                                      </Container>



                                                      

                                                    </CardFooter>
                                                      </Card>
                                            </Col>  

                                            </form>
                                                    


                                          
                                         
                                    </Row>

                                    

                              
                                  

                                  

                              
                           
                            </div>
                        </Modal> 


                        { mensaje ? <ModalMensaje
                                                
                                                className={classes.modal}
                                                open={open} 
                                                mensaje={mensaje}
                                                TodosOfertas={TodosOfertas}
                                                TodosProyectosPrimario={TodosProyectosPrimario}
                                                TodosProyectosSecundario={TodosProyectosSecundario}
                                                onClose={handleClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={open}
                                                timeout={154}
                                                onClose={handleClose}>
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info"  onClick={() => (
                                                                                    guardarMensaje(null),
                                                                                    TodosOfertas(),
                                                                                    TodosInversiones(),
                                                                                    TodosOferentes(),
                                                                                    handleClose(),
                                                                                    TodosProyectosPrimario(),
                                                                                    TodosProyectosSecundario(),
                                                                                    Movimientos()
                                                                  )} >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </ModalMensaje> : null}

       

</Fragment>     
        


     


        
        
          





     );
};

 
export default ListaOfertas; 




{/* <tr className="mercadoprimario-style"> 
        
<th>1000076-P1002</th>
  <th>BHR</th>
  <th>Emprendimientos</th>
  <th>Parcelas</th>
  <th>SEGUROS LA OCCIDENTAL</th>
  <th className=" montoplus">A++</th>
  <th>+++<i className="tim-icons icon-check-2 montoplus" /></th>
  <th>3%</th>
  <th>23/01/21 (16 dias)</th>
  <th>83.33</th>
  <th>83.44</th>
  <th>	0.11 / 0.13%</th>
  <th>
        <div class="progress">
      <div className="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
  </th>
  <Inversion/>
</tr> 
 */}
