import React, { useContext, useEffect } from 'react';
import { Route, Redirect } from 'react-router-dom';
import AuthContext from '../../context/autenticacion/authContext';

const RutaPrivada = ({ component: Component, ...props  }) => {

    const authContext = useContext(AuthContext);
    const { Movimientos, autenticado, Saldos, usuarioAutenticado, userStatus,ListaNacionales, ListaInternacionales, ListaRemesas,TodosRetiros, TodosDepositos,TodosOfertas,TodosOferentes, TodosInversiones,TodosInversionesVendidas,TodosProyectosSecundario,
        TodosProyectosPrimario, PantherLoadAppRecarga, PantherLoad } = authContext;

    useEffect(() => {
        PantherLoadAppRecarga();
        PantherLoad();
        userStatus();
        usuarioAutenticado();
        Saldos();
        ListaNacionales();
        ListaInternacionales();
        ListaRemesas();
        TodosRetiros();
        TodosDepositos();
        TodosOfertas();
        TodosOferentes();
        TodosInversiones();
        TodosInversionesVendidas();
        TodosProyectosSecundario();
        TodosProyectosPrimario();
        
        
        
        Movimientos()

        // eslint-disable-next-line
    }, []);

    return ( 
        <Route { ...props } render={ props => !autenticado ?  (
            <Redirect to="/" />
        )  : (
            <Component {...props} />
        ) } />

     );
}
 
export default RutaPrivada;