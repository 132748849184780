import React, { useContext, useState } from 'react';
import { ModalContext } from '../../context/ModalContext/ModalContext'

import Modal from '@material-ui/core/Modal'
import { makeStyles } from '@material-ui/core/styles' 



import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Table,
    CardTitle,
    ButtonGroup

  } from "reactstrap";  


  import ChatBlueModal from '../IconsAnimation/ChatBlueModal'

    
function getModalStyle() {
    const top = 50 ;
    const left = 50;
  
    return {
      top: `${top}%`,
      left: `${left}%`,
      transform: `translate(-${top}%, -${left}%)`,
    };
}

const useStyles = makeStyles(theme => ({
    paper: {
      position: 'absolute',
      
      margin: 2,
      
      boxShadow: theme.shadows[5],
      
      borderRadius: 10,
    },
}));



const Inversion = ({inversion}) => { 

        // configuracion del modal de material-ui
        const [ modalStyle ] =useState(getModalStyle);
        const [open, setOpen] =useState(false);
    
        const classes = useStyles();
    
        const handleOpen = () => {
            setOpen(true);
        } 
    
        const handleClose = () => {
            setOpen(false);;
        }
    
    


    // extraer los valores del context 
    const { guardarIdInversion } =useContext(ModalContext); 

    

     


    return ( 
        <div className="btn-blu">
                    <button
                    type="submit"
                     className="btn btn-block"
                     onClick={() => {
                        
                        handleOpen();
                    }}
                    
                    >Invertir</button> 
                    <Modal 
                            open={open}
                            onClose={ () => {
                                handleClose();
                                guardarIdInversion(null);
                                
                            } }
                        >
                            <div style={modalStyle} className={`  card ${classes.paper} Modal-inversion`}>
                            <CardHeader className="bg-success">
                                <h3 className="title display-4">Detalle de la inversion</h3>
                            </CardHeader>
                                  <CardBody>
                                        
                                  
                                            

                                  <Row >
                                            <Col sm="12" md="4">
                                                

                                                <Card className="card">
                                                <CardHeader>
                                                    <h5 className="title display-4">Datos de la Oferta</h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody>
                                                    
                                                    <div className="acc-config-panther">


                                                    <ul>

                                                            <li>Tipo de Oferta:   <strong> Empredimientos </strong>   </li>
                                                            <li>Monto: <strong> 1,000.00 </strong> </li>
                                                            <li>Distribución: <strong> 1,000.00 </strong> </li>
                                                            <li>Cantidad Emitida: <strong> 12 </strong> </li>
                                                            <li>Tasa de Interés: <strong> 3% </strong> </li>
                                                            <li>Garantía: <strong> SI </strong> </li>
                                                            <li>Riesgo: <strong> ++A </strong> </li>
                                                            <li>Fecha de Rendimiento: <strong> 23 / 11 / 2021 </strong> </li>
                                                            <li>Tiempo Total de Contrato <strong> 307 d. </strong> </li>
                                                            <li>Monto Invertido <strong> 83.33 </strong> </li>
                                                            <li>Documento adjunto por Información Adicional del Proyecto <strong> <i className="tim-icons icon-badge p-2" />   </strong> </li>
                                                            
                                                            
                                                        

                                                    </ul> 
                                                
                                                
                                                    </div>
                                                </CardBody> 
                                            
                                            
                                                </Card>
                                            </Col> 
                                            <Col sm="12" md="4">
                                                

                                                <Card className="card">
                                                <CardHeader>
                                                    <h5 className="title display-4">Distribucion del Contrato</h5>
                                                    <p className="text-success border-success border-bottom pb-2"></p>
                                                </CardHeader>
                                                <CardBody> 
                                                        <Col md="12">

                                                                <div className="kyc-container">
                                                                    <ChatBlueModal/>
                                                                </div>

                                                            </Col>
                                               
                                                </CardBody> 
                                                <CardFooter className="d-flex justify-content-end"> 
                                                <div className="acc-config-panther">
                                                    <ul>
                                                            <li>Monto Invertido: 8.3%</li> 
                                                            <li>Monto Disponible: 91.7%</li> 

                                                    </ul>
                                                    </div>
                                                </CardFooter>
                                            
                                                </Card>
                                            </Col> 
                                            <Col sm="12" md="4">
                                        

                                        <Card className="card">
                                        <CardHeader>
                                            <h5 className="title display-4">Saldo en la Cuenta</h5>
                                            <p className="text-success border-success border-bottom pb-2"></p>
                                            
                                        </CardHeader>
                                        <CardBody>
                                            
                                            <div className="acc-config-panther">
                                                

                                            <ul>    
                                                <h3 className="text-left m-0">Invertir</h3> 
                                                <p className="text-success border-success border-bottom text-right">83.33</p>
                                                <h3 className="text-left m-0">Monto a Invertir</h3> 
                                                <p className="text-success border-success border-bottom display-4 text-right">83.33</p>

                                                    <li className="display-4"> Ganancia:    </li>
                                                    <li className="text-right m-0 p-1">0.02    </li>
                                                    <li className="display-4">Valor a Recibir:    </li>
                                                    <li className="text-right m-0 p-1">83.35    </li>
                                                    
                                            </ul>
                                        
                                            </div>
                                        </CardBody> 
                                        <CardFooter className="d-flex justify-content-end Modal-Footer-style">
                                            <Button type="button" className=" edithbtn  btn-block" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar">
                                            Invertir
                                            </Button>
                                        </CardFooter>
                                    
                                        </Card>
                                    </Col> 
                                    </Row>
                                  </CardBody> 

                                  <CardFooter className="Modal-Footer-style">
                                        <Table className="tablesorter" responsive>
                                            <thead className="text-primary">
                                            <tr>
                                                <th>Codigo</th>
                                                <th>Pais</th>
                                                <th>Tipo</th>
                                                <th>Categoria</th>
                                                <th>Emisor</th>
                                                <th>Riesgo</th>
                                                <th>Garantia</th>
                                                <th>Interes</th>
                                                <th>Vencimiento</th>
                                                <th>Monto</th>
                                                <th>Final</th>
                                                <th>Ganancia</th>
                                                <th>Estado</th>
                                                
                                            </tr>
                                            </thead>
                                            <tbody>
                                            <tr className="mercadoprimario-style">
                                                    <th>1000076-P1002</th>
                                                        <th>BHR</th>
                                                        <th>Emprendimientos</th>
                                                        <th>Parcelas</th>
                                                        <th>SEGUROS LA OCCIDENTAL</th>
                                                        <th className=" montoplus">A++</th>
                                                        <th>+++<i className="tim-icons icon-check-2 montoplus" /></th>
                                                        <th>3%</th>
                                                        <th>23/01/21 (16 dias)</th>
                                                        <th>83.33</th>
                                                        <th>83.44</th>
                                                        <th>	0.11 / 0.13%</th>
                                                        <th><div class="progress">
                                                        <div className="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                                        </div>
                                                        </th>
                                            </tr> 
                                            </tbody>
                                        </Table>
                                    </CardFooter>
                           
                            </div>
                        </Modal>
        </div>
     );
}
 
export default Inversion; 

