import React, { useContext } from 'react';
import AuthContext from '../../context/autenticacion/authContext';
import { Route, Redirect } from 'react-router-dom';

import logoTextPanther from '../../assets/scss/black-dashboard-react/panther/pantherlogo_name.png'


const CerrarSesion = ({props}) => {

    const authContext = useContext(AuthContext);
  const { CerrarSesion,
          collapse,
        CollapseNav,
        ExpandNav,
    
  
  } = authContext;  


  const open = () => {
    if (collapse === true) {
        CollapseNav()
    }

    if (collapse === false) {
        ExpandNav()
    }
  } 

    return ( 
        <Route> 

          { collapse === true ? <>
            <div className={`d-flex flex-row  align-items-center`} >
              <p className=" joema-2">
                {/* {logoImg} */}
                <img src={logoTextPanther}/>
                {/* {logoText} */}
              </p>
                  

                </div>    
          
          </> : null

          }

  

      <div className="d-flex flex-row  align-items-center salir-container ml-2" onClick={open}>
                  <p className=" salir" > { collapse === true ? 'MOSTRAR MENÚ':'OCULTAR MENÚ' } </p>  <i className="tim-icons icon-bullet-list-67   icon-salir " />

                </div>


                

                


        
        <div className="d-flex flex-row p-2 align-items-center" onClick={ 
                    
                       
                    () => { CerrarSesion() }}>
                  <p className="p-1 salir" > Cerrar sesión</p>  <i className="tim-icons icon-user-run mt-1 p-1 icon-salir" />

                </div>

                
          
        {/* <button className="btn btn-sm btn-blank"
                onClick={ 
                    
                       
                    () => { CerrarSesion() }}
        
        >
           Cerrar Sesion
        </button> */}
        </Route>

     );
}
 
export default CerrarSesion;