
import Dashboard from "views/Dashboard.js";
import Inversiones from "views/Inversiones.js";
import Deposito from "views/Deposito.js";
import Retiro from "views/Retiro.js";
import Projectos from "views/Projectos";

import UserProfile from "views/UserProfile.js";
import UltraDeposito from "views/DepositosView/Depositos.jsx";  



var routespen = [

  {
    path: "/ultradeposito",
    name: "Depósito",
    
    icon: "tim-icons icon-coins",
    component: UltraDeposito,
    layout: "/cliente"
  },
  {
    path: "/user-profile",
    name: "Mi Cuenta",
    
    icon: "tim-icons icon-single-02",
    component: UserProfile,
    layout: "/cliente"
  },
 




]; 







export default routespen;
