import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';
import Inversion from './Inversion';
import clienteAxios from '../../config/axios';
import DataTable, {theme} from 'react-data-table-component'
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { Link } from 'react-router-dom';
import Spinner from '../spinners/Spinner'

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


// importacion del modal
import Modal from '@material-ui/core/Modal'
import ModalMensaje from '@material-ui/core/Modal'

import ModalPrimario from '../Modales/ModalPrimario'

import { makeStyles } from '@material-ui/core/styles' 

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

/// react table import

import BootstrapTable from 'react-bootstrap-table-next'
import paginationFactory from 'react-bootstrap-table2-paginator'
import * as ReactBootStrap from 'react-bootstrap';  

import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  CardFooter,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip,
  Container,
} from "reactstrap"; 

import AuthContext from '../../context/autenticacion/authContext'

// aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 







/// chart
let chartPie = {
  data4: (canvas) => {
    let ctx = canvas.getContext("2d");
    
    
    let modalInfo = [];
    let enable = [];
    let investment = [];
    
    // for (const dataObj of modalInfo) {
    //   enable.push(parseInt(dataObj.percentage_enable))
    //   investment.push(parseInt(dataObj.percentage_investment))
    // }
    

    let gradientStroke = ctx.createLinearGradient(0, 230, 0, 50);

    gradientStroke.addColorStop(1, "rgba(29,140,248,0.2)");
    gradientStroke.addColorStop(0.4, "rgba(29,140,248,0.0)");
    
    

    return {
      type: 'doughnut',
      
      datasets: [
        {
          label: "Data",
          fill: true,
          backgroundColor: gradientStroke,
          borderColor: "#1f8ef1",
          borderWidth: 2,
          borderDash: [],
          borderDashOffset: 0.0,
          pointBackgroundColor: "#1f8ef1",
          pointBorderColor: "rgba(255,255,255,0)",
          pointHoverBackgroundColor: "#1f8ef1",
          pointBorderWidth: 20,
          pointHoverRadius: 4,
          pointHoverBorderWidth: 15,
          pointRadius: 4,
          data: [10,80 ],
          weight: 300,
        }
      ] ,

      labels: ['Monto Disponible','Monto Invertido'],


    };
  },
  options: chart_pie_options
}; 



/// ahora va el option del chart
let chart_pie_options = {
  maintainAspectRatio: true,
  legend: {
    display: true
  },
  tooltips: {
    backgroundColor: "#f5f5f5",
    titleFontColor: "#333",
    bodyFontColor: "#666",
    bodySpacing: 4,
    xPadding: 12,
    mode: "nearest",
    intersect: 0,
    position: "nearest"
  },
  responsive: true,
  scales: {
    yAxes: [
      {
        DoughnutPercentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "#00ffc33d",
          zeroLineColor: "transparent"
        },
        ticks: {
          suggestedMin: 60,
          suggestedMax: 125,
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ],
    xAxes: [
      {
        Percentage: 1.6,
        gridLines: {
          drawBorder: false,
          color: "rgba(29,140,248,0.1)",
          zeroLineColor: "transparent"
        },
        ticks: {
          padding: 20,
          fontColor: "#9a9a9a"
        }
      }
    ]
  }
};





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    position: 'absolute',
    
    margin: 2,
    
    boxShadow: theme.shadows[5],
    
    borderRadius: 10,
  },
}));






const ListaOferente = ({apiload, ApiLoad}) => { 

  const authContext = useContext(AuthContext);
  const { TodosOferentes, mis_ofertas_oferente, filtro_inversiones } = authContext;  

  const [proyectoti, guardarProyectotis] = useState([{
    description:'',
    imagen:'',
    nrocontrato:'',
    currency_code:'',
    name:'',
    tax_referential_client:'',
    end_date:'',


  }]) 



  


  const [inversiones, guardarInversiones] = useState([{
    pais:"",
    emisor:"",
    tipo:"",
    riesgo:"",
    vencimiento:"",
    rango:""
  }]); 

     /// vamos a hacer la busqueda

     const [ busqueda, guardarBusqueda ] = useState({
      proyecto:'',
      vencimiento:'',
      monto:'',
      
  });
  
  
  
  const [ proyecto, guardarProyecto ] = useState([]);
  
  
  
 /// aqui estamos colocando el contador de pagina, numero
 const [ paginaactualcount, guardarPaginaActualCount ] = useState(1);
 // aqui estamos asignando el contador numero a la variable,mediante una cadena de  texto
 const [ paginaactual, guardarPaginaActual ] = useState({
    page:paginaactual,
   
 }); 

 

 const busquedaop = {...busqueda, ...paginaactual } ;

 const [ pdf, guardarDocumentacionPdf ] = useState({
   
   pdf:'',

 })
 const [ csv, guardarDocumentacionCsv ] = useState({
   
   csv:'',

 })


 
   
const [totales, guardarTotales ] = useState({
  cantidad:1,
  total_monto:'',
  total_rendimiento:'',
  total_saldo:'',
  
});

  


  useEffect( (props) => { 

    const consultarApiFiltros = async () => { 

      guardarProyecto(filtro_inversiones);

    //   try {
    //     const url = `/api/client/investments/filtros`

        
    //     const resultado = await clienteAxios.post(url); 
        
        
        
        

    // } catch (error) {
    //             return

    // } 
     
    }

    consultarApiFiltros();





    const consultarApiInversiones = async () => {
      const {amount, amount_investment, amount_reserved, 
        approval_date, approved_by, category, category_id, 
        code, contract_type, country_iso, created_at, created_by,
        cuenta_negociador, currency_code, date_max_investment, delay_percentage
        ,email_sent, end_date, fee_total_contract, file, finished_date, frequency_payment, 
        id, issuer, loan_type, min_amount_start,name , nrocontrato, oferente, oferente_id, quantity, 
        risk, start_date, status, tax_price_cuota, tax_referential_client, tax_referential_panther,
        total_inversores, updated_at, updated_by, warranty, disponible,rendimiento, project,vender,fecha_inicio,fecha_fin,offers_amount,valorventa
      
      } = inversiones; 

   


      // const respuesta = await clienteAxios.post('/api/client/projects/propios', busquedaop)
      
      
      
      guardarInversiones(mis_ofertas_oferente)
      
      


      
      guardarPaginaActual({
        page:`${paginaactualcount}`,
      }) 

            
      
      
      
    }

    consultarApiInversiones()
    
    
    

  },[!apiload,mis_ofertas_oferente]);

  // [busqueda,paginaactual]



  // vamos a hacer la parte de venta
  const [venta, guardarVenta] = useState({

    amount:'',
    type: '',
    date:'',
    
    
    });  


    const [cancelaroferta, guardarCancelarOferta] = useState("");

  // vamos a pasarle el la informacion de la fila al modal
  const [modalInfo, setModalInfo] = useState([]);
  
  

  

  // vamos a extraer el context de emergencia para guardar los valores de la inversion 
  // despues 

  
  // vamos a hacer que el mensaje salga
  const [mensaje, guardarMensaje] = useState('');

  
   // configuracion del modal de material-ui
   const [ modalStyle ] =useState(getModalStyle);
   const [open, setOpen] =useState(false);

   const classes = useStyles();

   const handleOpen = () => {
       setOpen(true);
   } 

   const handleClose = () => {
       setOpen(false);
       guardarMensaje(null);
   }







  

    // estamos creando el state 
    const [contratomonto, guardarContratoMonto] = useState({
      amount:''
      
      
}); 

  

  const [project_id, guardarProjectid] = useState({
    project_id:''

  })

  const [projectid, guardarProjectidTrue] = useState({
    id:''

  })

  // extraer usuario del state

  const {amount} = parseInt(contratomonto) 
  
 

  


  const crearInversion = async inversion => {
       
    const {contratomonto,projectid} = inversion;
    
   
    
    var amount=document.getElementById('amount').value;
  
    try {
        const respuesta = await clienteAxios.post('/api/client/investments/create',{amount,project_id});
        
      
      
      guardarMensaje(respuesta.data.message); 

      setTimeout(() => {
        guardarMensaje(null);
    },5000);
        
    } catch (error) {
      guardarMensaje(error.response.data.message);
      setTimeout(() => {
        guardarMensaje(null);
        setOpen(false);
    },5000);
       
    }
} 


const borrarOferta = async () => {
       
  
  
  try {
    const respuesta = await clienteAxios.post(`/api/client/offers/cancel/${cancelaroferta}`);
      
    elGuardarCargando(false);
    
    guardarMensaje(respuesta.data.message); 

    
  } catch (error) {
    elGuardarCargando(false);
    guardarMensaje(error.response.data.message);
 
  }
} 








const onChangeFilter = e => {

  // voy a colocar guardar usuario en el state

  guardarBusqueda({
      ...busqueda,
      [e.target.name] : e.target.value

  })
}






  const onChange = e => {

      // voy a colocar guardar usuario en el state
      guardarVenta({
         
        [e.target.name] : (e.target.value)
    })


      guardarContratoMonto({
         
          [e.target.name] : parseFloat(e.target.value)
      })
  } 


  const [ elcargando, elGuardarCargando] = useState(false);

  const elguardar = ( elcargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Cancelar Ahora
    </Fragment> 




  const onSubmitCancelar = e => {
    elGuardarCargando(true);
      e.preventDefault(); 

      
      
      borrarOferta(cancelaroferta);

  }

   

  /// cuando el usuario quiere iniciar seseion
  const onSubmit = e => {
      e.preventDefault(); 

      

      crearInversion({ contratomonto,project_id});

  }

   
  const [ mostrar, setMostrar ] = useState(false);
    
    
      
    return (  
    <Fragment> 

    <Row> 
                <Col md="12"> 
                <form  >
                    <Card className="p-4">
                            <CardHeader> 
                            <CardTitle className="display-4" tag="h2" >Listado de ofertas
                            
                            </CardTitle>
                                
                            </CardHeader>
                            <CardBody>
                                    <Row>
                                        <Col md="6">
                                        <select className="form-control text-center mb-3" 
                                        id="proyecto"
                                        name="proyecto"
                                        onChange={onChangeFilter}
                                        value={busqueda.proyecto} > 

                                            <option value=""> - - -  Nombre del Proyecto - - - </option>
                                         {proyecto.map(proyectos => (
                                            <option
                                                key={proyectos.name}
                                                value={proyectos.name}

                                            >{proyectos.name}</option>

                                         )



                                         )}
                                            
                                        </select>
                                        </Col>
                        
                                  
                                        <Col md="6">
                                        <select className="form-control  text-center mb-3" 
                                                onChange={onChangeFilter}
                                                value={busqueda.vencimiento}
                                                id="vencimiento"
                                                name="vencimiento"

                                                >

                                        <option  value=""> - - - Periodo de Vencimiento - - -</option>
                                            <option value="1">Hoy</option>
                                            <option value="2"> Próximos 7 días</option>
                                            <option value="3"> Próximos 30 días</option>
                                            <option value="4">Próximos 360 días</option>
                                        </select>
                                            
                                        </Col>
                                    </Row>
                            </CardBody>
                            {/* <CardFooter className="d-flex justify-content-end">
                            <Button  className="btn-block  btn-radius-panther mt-0" color="default" data-toggle="modal" data-target="#acc-config" data-whatever="Editar" type="submit">
                                Buscar
                            </Button>
                            </CardFooter> */}



                    </Card> 
                    </form>
                </Col>
            </Row>
             
      
    
       
            <Container fluid className="m-0 p-0">
                                         <Row className="m-0 p-0"> 


                                                <Col sm="12" xl="12" lg="12" md="12">
                                                <Divdesktop> 
                                                

                                                {inversiones.length < 0 ? <>


                                                
                                                <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                                                        
                                                <div>

                                                  <CardBody className="tabla_proyectos_final"> 



                                                  <div className="table-responsive ps text-center">
                                                      <table className="table table-bordered">
                                                      
                                                      <thead className="thead-pro">
                                                          <tr>
                                                              <th data-tooltip="Codigo de la inversión" >
                                                              <strong className="line-fix">
                                                              <i class="tim-icons icon-bank p-2"/>CODIGO
                                                              </strong>
                                                              </th>
                                                              <th className="" data-tooltip="País de la inversión"> 
                                                              
                                                              <strong className="line-fix">
                                                                PAIS
                                                              </strong>
                                                              
                                                              </th>
                                                              <th className="" data-tooltip="Tipo de inversión"> 

                                                              <strong className="line-fix">
                                                              TIPO
                                                              </strong>
                                                              
                                                              </th>
                                                              <th data-tooltip="Categoria de la inversión">

                                                              <strong className="line-fix">
                                                                CATEGORIA
                                                              </strong>
                                                              
                                                              </th>
                                                              <th className="" data-tooltip="Emisor de la inversión"> 
                                                              <strong className="line-fix">
                                                              EMISOR
                                                              </strong>
                                                              
                                                              </th>
                                                              <th className="" data-tooltip="Nombre de la inversión">

                                                              <strong className="line-fix">
                                                              NOMBRE
                                                              </strong>
                                                              
                                                              </th> 
                                                              <th className="" data-tooltip="Categiria de riesgo de la inversión">

                                                              <strong className="line-fix">
                                                              RIESGO
                                                              </strong>
                                                              
                                                              </th> 

                                                              <th className="" data-tooltip="Garantia Panther sobre la inversión">

                                                              <strong className="line-fix">
                                                              GARANTIA
                                                              </strong>
                                                              
                                                              </th> 

                                                              
                                                              <th className="" data-tooltip="Interes anual de rendimiento sobre la inversión">

                                                              <strong className="line-fix">
                                                              <i class="tim-icons icon-coins p-2"/>
                                                              INTERES ( % )
                                                              </strong>
                                                              
                                                              </th>
                                                              
                                                              <th className="" data-tooltip="Fecha de vencimiento de la inversión en el mercado primario">

                                                              <strong className="line-fix">
                                                              <i class="tim-icons icon-calendar-60 p-2"/> 
                                                              VENCIMIENTO
                                                              </strong>
                                                              
                                                              </th>
                                                              
                                                              <th className="" data-tooltip="Monto total de toda la inversión">

                                                              <strong className="line-fix">
                                                              <i class="tim-icons icon-coins p-2"/> MONTO ( $ )
                                                              </strong>
                                                              
                                                              </th>
                                                              
                                                              <th className="" data-tooltip="Remanente de Ganancia sobre la compra de la inversión">

                                                              <strong className="line-fix">
                                                              GANANCIA PERIODO ( % )
                                                              </strong>
                                                              
                                                              </th>
                                                              
                                                              <th className="" data-tooltip="Monto disponible para invertir">

                                                              <strong className="line-fix">
                                                              DISPONIBILIDAD
                                                              </strong>
                                                              
                                                              </th>

                                                              <th className="" data-tooltip="Numero de inversionistas participando en la inversión">

                                                              <strong className="line-fix">
                                                              <i class="tim-icons icon-single-02 p-2"/>  
                                                              PARTICIPANTES
                                                              </strong>

                                                              </th>
                                                              
                                                    

                                                            

                                                          </tr>
                                                        </thead> 
                                                      
                                                        <tbody> 

                                                      {inversiones.map(contrato => (
                                                        <>
                                                          
                                                      
                                                                    <tr key={contrato.id} onClick={ () => {
                                                                          handleOpen();
                                                                          guardarProjectid(contrato.id) 
                                                                          setModalInfo(contrato);
                                                                        }


                                                                        }>
                                                                      <td>
                                                                        <small className="line-fix">
                                                                          {contrato.code}
                                                                        </small>
                                                                      </td>
                                                                        <td>
                                                                          {contrato.country_iso}
                                                                        </td>
                                                                        <td>
                                                                          {contrato.tipo}
                                                                        </td>
                                                                        <td>
                                                                          {contrato.category}
                                                                        </td>
                                                                        <td>
                                                                          {contrato.issuer}
                                                                        </td> 
                                                                        <td>
                                                                          {contrato.name}
                                                                        </td>



                                                                      <td>
                                                                        <small className="line-fix">
                                                                          {contrato.risk}
                                                                        </small>
                                                                      </td> 

                                                                      <td>
                                                                          {contrato.warranty}
                                                                        </td>
                                                                        <td>
                                                                          {contrato.fee_total_contract}
                                                                        </td>
                                                                      <td>
                                                                        
                                                                            {contrato.end_date}
                                                                        
                                                                      </td>
                                                                      <td>
                                                                          {contrato.amount_proyecto} 
                                                                      </td>
                                                                      <td>
                                                                          {contrato.ganancia}
                                                                      </td>

                                                                        <td>
                                                                        {contrato.disponible}
                                                                        </td>


                                                                      <td>
                                                                          {contrato.total_inversores}
                                                                      </td>
                                                                    
                                                                    </tr>  
                                                                      
                                                  
                                                      </>
                                                                ) )}

                                                            


                                                                </tbody> 

                                                                


                                                      </table>


                                                  </div>


                                                    
                                                  </CardBody>
                                                    <CardFooter>
                                                      <Container className="border-top border-info pt-5 "> 
                                                            <Row className="justify-content-center">
                                                          { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                                                                <Col sm="5" lg="5" xl="5" md="5">
                                                                    <button
                                                                        title=""
                                                                        type="button"
                                                                        className="btn btn-primario btn-block   btn-panther-power mt-1"
                                                                        disabled
                                                                      > Primera Página </button> </Col>: (
                                                          <Col sm="5" lg="5" xl="5" md="5">
                                                              <button
                                                                        title=""
                                                                        type="button"
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={() => 
                                                                          
                                                                          (guardarPaginaActualCount(paginaactualcount - 1))
                                                                                      
                                                                        
                                                                        }
                                                                      > Anterior &laquo; </button>
                                                          </Col>
                                                          )}
                                                          <Col md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                                                          <span className="box-pagination btn-block">
                                                                    {paginaactualcount}
                                                          </span>
                                                          
                                                          </Col>

                                                          <Col sm="5" lg="5" xl="5" md="5">
                                                          <button
                                                                        title=""
                                                                        type="button"
                                                                        className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                        onClick={() => (guardarPaginaActualCount(paginaactualcount + 1)
                                                                          )
                                                                        }
                                                                      > Siguiente &raquo; </button>
                                                          
                                                          </Col>


                                                      </Row>
                                                      
                                                      
                                                      </Container>

                                                      

                                                      

                                                    </CardFooter>

                                                </div> 

                                                </Card>  


                                                </>  : 
            
            
            
                                                <Row className="box-depositos-seleccion-alerta justify-content-center m-0 p-0"> 
                                                                          <Col md="12" className=" panther-open-titulo-alerta border-panther m-0 p-0"> 

                                                                          
                                                                        {inversiones.length === 0 ?  <> 
                                                                        
                                                                        <i className="tim-icons icon-spaceship animation-icon-in"/>
                                                                                <h2 className="animation-icon-in-dos">¡Ups, sin proyectos ofertados!</h2>
                                                                                  <h3>Todavía no tiene sus propios proyectos ofertados en Panther7</h3>
                                                                                  <p className="text-center animation-icon-in-cuatro animate-raya-03">¿Sabias que sus sueños se pueden cumplir, ofertando sus propios proyectos en Panther7? </p>
                                                                                  <p className="text-center animation-icon-in-cuatro">Para colocar proyectos propios en Panther7, Diríjase directamente a las oficinas consultoras sede en su zona país </p>  </> :
                                                                                  <Spinner/>
                                                                                  
                                                                                  }
                                                </Col>
                                                                              
                                                </Row>  
                                                                          
                                                                          
                                                                          }



                                                </Divdesktop> 

                                                <DivMobile> 

                                                {inversiones.length < 0 ? <>

                                                <Card className="height-fix-ultimos-movimientos justify-content-center table-responsive">
                                                                                        
                                                                                        <div>
                                                                              

                                                                                            <CardBody>
                                                                                                  <Container> 

                                                                                                    <Row>




                                                                                                        {inversiones.map(contrato => (
                                                                                                          <>
                                                                                                      <Col xs="12" sm="12" md="6" key={contrato.id} >
                                                                                                        <Card className="card-responsive-bg"> 
                                                                                                          <CardHeader>
                                                                                                          <Row>
                                                                                                          <Col xs="6" sm="6" md="6">
                                                                                                              <p className="card-responsive-01-inversiones"> <strong>  Codigo </strong> <br/>
                                                                                                              {contrato.code}</p> 
                                                                                                                </Col>
                                                                                                                <Col xs="6" sm="6" md="6">
                                                                                                                <p className="card-responsive-02-inversiones"> <strong>  Pais </strong><br/> 
                                                                                                                {contrato.country_iso}</p>
                                                                                                                </Col>
                                                                                                                <Col xs="6" sm="6" md="6">
                                                                                                                <p className="card-responsive-02-inversiones"> <strong>  Tipo </strong><br/> 
                                                                                                                {contrato.tipo}</p>
                                                                                                                </Col>
                                                                                                                <Col xs="6" sm="6" md="6">
                                                                                                                <p className="card-responsive-02-inversiones"> <strong>  Categoria </strong><br/> 
                                                                                                                {contrato.category}</p>
                                                                                                                </Col>
                                                                                                                </Row>
                                                                                                          </CardHeader>

                                                                                                          <CardBody className="card-responsive-07-inversiones">
                                                                                                              <Row> 

                                                                                                              <Col xs="12" sm="12" md="12">
                                                                                                                      <p className="card-responsive-03-inversiones"><strong>  Nombre del Proyecto :</strong> <br/> {contrato.name}</p>
                                                                                                                  </Col> 

                                                                                                                  <Col xs="12" sm="12" md="12">
                                                                                                                      <p className="card-responsive-03-inversiones"><strong>  Emisor :</strong> <br/> {contrato.issuer}</p>
                                                                                                                  </Col>
                                                                                                                  
                                                                                                                
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                      <p className="card-responsive-03-inversiones"><strong className="line-fix">  <i class="tim-icons icon-bank pr-1"/> Monto:</strong> <br/> {contrato.amount_proyecto}</p>
                                                                                                                  </Col>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                      <p className="card-responsive-03-inversiones"><strong>  Riesgo : </strong> <br/>{contrato.risk}</p>
                                                                                                                  </Col>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Garantia :</strong> <br/> {contrato.warranty}</p>
                                                                                                                  </Col>

                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Interes ( % )</strong> <br/> {contrato.fee_total_contract}</p>
                                                                                                                  </Col> 
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Vencimiento :</strong> <br/> {contrato.end_date}</p>
                                                                                                                  </Col>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Ganancia ( % ):</strong> <br/> {contrato.tax_referential_client}</p>
                                                                                                                  </Col>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Disponibilidad:</strong> <br/> {contrato.disponible}</p>
                                                                                                                  </Col>
                                                                                                                  <Col xs="6" sm="6" md="6">
                                                                                                                  <p className="card-responsive-04-inversiones"><strong> Participantes:</strong> <br/> {contrato.total_inversores}</p>
                                                                                                                  </Col>
                                                                                                              

                                                                                                              </Row>


                                                                                                          </CardBody>


                                                                                                          <CardFooter>
                                                                                                              <p className="card-responsive-05-inversiones btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"  onClick={ () => {
                                                                                                                          handleOpen();
                                                                                                                          guardarProjectid(contrato.id) 
                                                                                                                          setModalInfo(contrato);
                                                                                                                          
                                                                                                                        }
                                                                                                              }> Invertir</p>
                                                                                                          </CardFooter>
                                                                                                    
                                                                                                
                                                                                                          
                                                                                                                
                                                                                                              
                                                                                                                
                                                                                            
                                                                                                        </Card>
                                                                                                      </Col>
                                                                                                  </>


                                                                                                          ) )}
                                                                                  
                                                                                                      
                                                                                                      





                                                                                                    </Row>
                                                                                                  </Container>
                                                                                            </CardBody>


                                                                                              <CardFooter>
                                                                                                <Container className="border-top border-info pt-5 "> 
                                                                                                      <Row className="justify-content-center">
                                                                                                    { (paginaactualcount === 1 || paginaactualcount === 0 ) ? 
                                                                                                          <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                                                              <button
                                                                                                                  title=""
                                                                                                                  type="button"
                                                                                                                  className="btn btn-primario btn-block   btn-panther-power mt-1"
                                                                                                                  disabled
                                                                                                                > Primera Página </button> </Col>: (
                                                                                                    <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                                                        <button
                                                                                                                  title=""
                                                                                                                  type="button"
                                                                                                                  className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                                                  onClick={() => 
                                                                                                                    
                                                                                                                    guardarPaginaActualCount(paginaactualcount - 1) 
                                                                                                                                
                                                                                                                  
                                                                                                                  }
                                                                                                                > Anterior &laquo; </button>
                                                                                                    </Col>
                                                                                                    )}
                                                                                                    <Col xs="2" md="2" sm="2" lg="2" xl="2" className="box-pagination-father">
                                                                                                    <span className="box-pagination btn-block">
                                                                                                              {paginaactualcount}
                                                                                                    </span>
                                                                                                    
                                                                                                    </Col>
                                                                                  
                                                                                                    <Col xs="5" sm="5" lg="5" xl="5" md="5">
                                                                                                    <button
                                                                                                                  title=""
                                                                                                                  type="button"
                                                                                                                  className="btn btn-primario btn-block  btn-radius-panther-dos  btn-panther-power mt-1"
                                                                                                                  onClick={() => guardarPaginaActualCount(paginaactualcount + 1) }
                                                                                                                > Siguiente &raquo; </button>
                                                                                                    
                                                                                                    </Col>
                                                                                  
                                                                                  
                                                                                                </Row>
                                                                                                
                                                                                                
                                                                                                </Container>
                                                                                  
                                                                                  
                                                                                  
                                                                                                
                                                                                  
                                                                                              </CardFooter>
                                                                                  
                                                                                  
                                                                                  
                                                                                  
                                                                                          </div> 
                                                                                          
                                                                                          </Card> 


                                                                                          </>  : 
            
            
            
                                                        <Row className="box-depositos-seleccion-alerta justify-content-center"> 
                                                                                  <Col md="12" className=" panther-open-titulo-alerta border-panther"> 

                                                                                {inversiones.length === 0 ?  <> 
                                                                                
                                                                                <i className="tim-icons icon-spaceship animation-icon-in"/>
                                                                                        <h3 className="animation-icon-in-dos">¡Ups, sin proyectos ofertados!</h3>
                                                                                          <h3>Todavia no tiene sus propios proyectos ofertados en Panther7</h3>
                                                                                          <p className="text-center animation-icon-in-cuatro animate-raya-03">¿Sabias que sus sueños se pueden cumplir, ofertando tus propios proyectos en Panther7? </p>
                                                                                          <p className="text-center animation-icon-in-cuatro">¡Para colocar proyectos propios en Panther7, Diríjase directamente a las oficinas consultoras sede en su zona país! </p>  </> :
                                                                                          <Spinner/>
                                                                                          
                                                                                          }
                                                        </Col>
                                                                                      
                                                        </Row>  
                                                                                  
                                                                                  
                                                      }




                                                                                          



                                                                              </DivMobile>





                                                                              </Col>



                                                </Row> 
                                         </Container >

                                          
   


                                         <ModalPrimario  modalInfo={modalInfo} 
                                                      onSubmit={onSubmit} 
                                                      onChange={onChange}
                                                      amount={amount}
                                                      handleOpen={handleOpen}      
                                                      handleClose={handleClose} 
                                                      open={open} 
                                                      mensaje={mensaje}                                    
                                                      
                                                      /> 


                        { mensaje ? <ModalMensaje
                                                
                                                className={classes.modal}
                                                open={open} 
                                                mensaje={mensaje}
                                                                  
                                                onClose={handleClose}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                  timeout: 900,
                                                }}
                                              >
                                                <Fade in={open}
                                                timeout={154}
                                                onClose={handleClose}>
                                                  <div className="container">
                                                <div className="row">
                                                  <div className="col-12 d-flex justify-content-center">
                                                  <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
                                                  
                                                  <CardBody>
                                                                      <Row className="d-flex justify-content-center">
                                                                              <Col sm="12" md="6"> 
                                                                                  <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                                                              </Col>       
                                                                      </Row>
                                                                    </CardBody> 
                                                                    <CardFooter>
                                                      <div className="row">
                                                          
                                                      <div  className="col-4">
                                                          </div>
                                                          <div  className="col-4">
                                                            <button className=" btn mt-0 btn-block btn-info"  onClick={() => (
                                                                                    guardarMensaje(null),
                                                                                    TodosOferentes(),
                                                                                    
                                                                                    handleClose()
                                                                  )} >
                                                            Aceptar
                                                          </button>
                                                          </div>
                                                                
                                                      <div  className="col-4">
                                                          </div>
                                                      </div>
                                                  </CardFooter>
                                                                    
                                                                
                                                  

                                                </div>

                                                  </div>

                                                </div>
                                                  </div>
                                                </Fade>
                                              </ModalMensaje> : null}

       

</Fragment>     
        


     


        
        
          





     );
};

 
export default ListaOferente; 




{/* <tr className="mercadoprimario-style"> 
        
<th>1000076-P1002</th>
  <th>BHR</th>
  <th>Emprendimientos</th>
  <th>Parcelas</th>
  <th>SEGUROS LA OCCIDENTAL</th>
  <th className=" montoplus">A++</th>
  <th>+++<i className="tim-icons icon-check-2 montoplus" /></th>
  <th>3%</th>
  <th>23/01/21 (16 dias)</th>
  <th>83.33</th>
  <th>83.44</th>
  <th>	0.11 / 0.13%</th>
  <th>
        <div class="progress">
      <div className="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
      </div>
  </th>
  <Inversion/>
</tr> 
 */}
