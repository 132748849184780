import React, {createContext, useState, useEffect} from 'react';
import axios from 'axios';


export const ModalContext = createContext();

const ModalProvider = (props) => {

    // state del provider / necesitamos guardar el id, hasta cuando el usuario de click
    const [ idinversion, guardarIdInversion] = useState(null);

    //vamos a crear otro state para guardar la receta de la consulta
    const [informacion, guardarInversion] = useState({})

    
    // una ves tenemos la receta queremos llamar a la receta
    useEffect(() => {

        const obtenerInversion = async () => {

            if(!idinversion) return;

            const url = `/api/client/projects`

            const resultado = await axios.post(url);
            guardarInversion(resultado.data);

            console.log(resultado);

            
        }
        obtenerInversion();
    }, [idinversion]);


    return ( 

            <ModalContext.Provider
                value={{
                    informacion,
                    guardarIdInversion,
                    guardarInversion
                }}
            >
                {props.children}
            </ModalContext.Provider>

     );
}
 
export default ModalProvider;