import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import NumberFormat from 'react-number-format';
import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner';
import AuthContext from '../../context/autenticacion/authContext.js';




const CuentasBancarias = ({jimf_theme}) => {

    const authContext = useContext(AuthContext);
    const { status,userStatus, listDatos,cuentas_internacionales,cuentas_nacionales, ListaNacionales, ListaInternacionales } = authContext;  


    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 


                    const [ spin, setSpin ] = useState(false);

                    const [ internacionales , setInternacionales ]= useState(cuentas_internacionales);
                    const [ nacionales , setNacionales ]= useState(cuentas_internacionales);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);

                    const [usuario, guardarUsuario] = useState({
                        current_password:'',
                        new_password:'',
                        confirm_password: '',
                        
                    });


                    const {current_password,new_password,confirm_password} = usuario;

                    

                


            


                const consultarApiReset = async () => {

                      
                    
                

                    try {
                            const respuesta = await clienteAxios.post('/api/client/changepassword',usuario);
                                
                                guardarMensajeOpen(true)
                               guardarMensaje(respuesta.data.message)                               
                               setSpin(false)
                               listDatos()
                               
                    } catch (error) {
                        guardarMensajeOpen(true)
                        guardarMensaje(error.response.data.message)
                        setSpin(false)
                        listDatos()
                                
                              
                    }
                
                  
                  
                  
                } 
                


                const onChange = e => {  
                   
        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
                } 

                const onSubmit = e => {
                    e.preventDefault();
                    setSpin(true)
                      consultarApiReset(usuario);
                } 


                const Loader = ( spin ) ? <LineSpinner/> :  
                                          
      <Fragment>
        Cambiar contraseña
        </Fragment> 



    return ( 

        <>

        <div className="window-jimf-retiro box-dual">

            <div 
            className={` ${jimf_theme === "white" ? 'cuentas-jimf-white':'cuentas-jimf' }`}
            >
                    <div className="box-cuentas-jimf g-jimf-100">

                            <div className="header-box-cuentas-jimf">
                                
                                <p>Cuentas internacionales</p>

                                <button>+</button>
                            </div>

                            <div className="body-box-cuentas-jimf">

                            {internacionales
            // .slice( (pagina -1)* porPagina, (pagina - 1) * porPagina + porPagina)
            // .filter(searchingTerm(term))
            .map(inter => (
                                                                                              <>
                                                          

                                                                        <div className={` ${jimf_theme === "white" ? 'cuentas-inter-jimf-white':'cuentas-inter-jimf' } `}>
                      
                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                  <p>Titular de la cuenta</p>
                                                                                                  
                                                                                                  <small >
                                                                                                  {inter.bank_client}
                                                                                                  </small>
                                                                                                  
                                                                                                  </div>

                                                                                                  
                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                        <p>
                                                                                                        Aprobada <span className="text-3"><i className="fas fa-check-circle"></i></span>

                                                                                                        </p>

                                                                                                        <small >
                                                                                                    Cuenta Internacional
                                                                                                    </small>
                                                                                                        </div>

                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                  <p>Número de la cuenta</p>

                                                                                                  

                                                                                                    <small >
                                                                                                    {inter.bank_account}
                                                                                                    </small>

                                                                                                    </div>


                                                                                                    

                                                                                                    <div className="cuentas-card-jimf" > 
                                                                                                    <p>Tipo de cuenta</p>

                                                                                                  

                                                                                                    <small >
                                                                                                    {inter.bank_type}
                                                                                                    </small>

                                                                                                    </div>

                                                                                                    

                                                                                                
                                                                            

                                                                        </div>

                                                                                              
                                                                                                    
                                                                                            
                                                 

                                          </>
                                                                                  ) )}

                            </div>

                    </div>

                   
                    
            </div>

            <div 
            
            className={` ${jimf_theme === "white" ? 'cuentas-jimf-white':'cuentas-jimf' }`}
            >
                    <div className="box-cuentas-jimf g-jimf-100">

                            <div className="header-box-cuentas-jimf">
                                <p>Cuentas nacionales</p>
                                <button>+</button>
                            </div>

                            <div className="body-box-cuentas-jimf">

                            {nacionales
            // .slice( (pagina -1)* porPagina, (pagina - 1) * porPagina + porPagina)
            // .filter(searchingTerm(term))
            .map(inter => (
                                                                                              <>
                                                          

                                                                        <div className={` ${jimf_theme === "white" ? 'cuentas-inter-jimf-white':'cuentas-inter-jimf' } `}>
                      
                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                  <p>Titular de la cuenta</p>
                                                                                                  
                                                                                                  <small >
                                                                                                  {inter.bank_client}
                                                                                                  </small>
                                                                                                  
                                                                                                  </div>

                                                                                                  
                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                        <p>
                                                                                                        Aprobada <span className="text-3"><i className="fas fa-check-circle"></i></span>

                                                                                                        </p>

                                                                                                        <small >
                                                                                                    Cuenta Nacional
                                                                                                    </small>
                                                                                                        </div>

                                                                                                  <div className="cuentas-card-jimf" > 

                                                                                                  <p>Número de la cuenta</p>

                                                                                                  

                                                                                                    <small >
                                                                                                    {inter.bank_account}
                                                                                                    </small>

                                                                                                    </div>


                                                                                                    

                                                                                                    <div className="cuentas-card-jimf" > 
                                                                                                    <p>Tipo de cuenta</p>

                                                                                                  

                                                                                                    <small >
                                                                                                    {inter.bank_type}
                                                                                                    </small>

                                                                                                    </div>

                                                                                                    

                                                                                                
                                                                            

                                                                        </div>

                                                                                              
                                                                                                    
                                                                                            
                                                 

                                          </>
                                                                                  ) )}

                            </div>

                    </div>

                   
                    
            </div>

           

        </div>
        <div className="window-select-tipe">
                <h1>Mis Cuentas Bancarias</h1>
        </div>


                                                
                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                            
                        /> 
                    
                
                </> : null
                
                }


        </>


     );
}
 
export default CuentasBancarias;