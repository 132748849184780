
import React  from "react";
import ReactDOM from "react-dom";

import App from './App';



// aqui voy a colocar por mientras el admin viejo de referencia
// importanto el context de autenticacion

import "assets/scss/black-dashboard-react.scss";
import "assets/demo/demo.css";
import "assets/css/nucleo-icons.css";

// // exportando la libreria de tablas de boostrap 
// import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';

// ahora vamos a importar lka libreria de paginacion par aque funcione
import 'react-bootstrap-table2-paginator/dist/react-bootstrap-table2-paginator.min.css';

// ahora vamos a importar el cdn de react-boostrap
// import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render( 
    
  <App/>


 , 
  document.getElementById("root"));

{
  // console.log(process.env.REACT_APP_BACKEND_URL)
};


    {/* <Row> 
         <Col xl="12" lg="12" md="12">
            <Card>
              <CardHeader>
                <CardTitle tag="h2" >Mercado Primario</CardTitle>
                
              </CardHeader>
              <CardBody>
                <Table className="tablesorter" responsive>
                  <thead className="text-primary">
                    <tr>
                      <th>Codigo</th>
                      <th>Pais</th>
                      <th>Tipo</th>
                      <th>Categoria</th>
                      <th>Emisor</th>
                      <th>Riesgo</th>
                      <th>Garantia</th>
                      <th>Interes</th>
                      <th>Vencimiento</th>
                      <th className="text-right">Monto</th>
                      <th className="text-right">Final</th>
                      <th className="text-right">Ganancia</th>
                      <th className="text-right">Estado</th>
                      <th>Invertir</th>
                    </tr>
                  </thead> 
                  <tbody>
                  { Object.keys(contratos).map((item, i) => (
                              <tr key={i} className="mercadoprimario-style">
                                      <th>{contratos[item].code}</th>
                                      <th>{contratos[item].country_iso}</th>
                                      <th>Emprendimientos</th>
                                      <th>{contratos[item].category}</th>
                                      <th>{contratos[item].name}</th>
                                      <th className=" montoplus">{contratos[item].risk}</th>
                                      <th>+++<i className="tim-icons icon-check-2 montoplus" /></th>
                                      <th>3%</th>
                                      <th>{contratos[item].end_date}</th>
                                      <th className="text-right">{contratos[item].amount}</th>
                                      <th className="text-right">83.44</th>
                                      <th className="text-right">	0.11 / 0.13%</th>
                                      <th>
                                            <div className="progress">
                                          <div className="progress-bar bg-success" role="progressbar" aria-valuenow="25" aria-valuemin="0" aria-valuemax="100"></div>
                                          </div>
                                      </th>
                                      <Inversion/>
                              </tr>
                      ))} 
                  


                  </tbody>
                   
                 
                </Table>
              </CardBody>
            </Card>
          </Col>
        </Row>  */} 