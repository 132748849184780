import React, { useState } from 'react'


const Pagination = ({pagina, setPagina, maximo, setAnimation, jimf_theme}) => {


    const [ input, setInput ] = useState(1)
    // const [ pagina, setPagina ] = useState()

    const prevPage = () => {
        setInput( parseInt(input) -1);
        setPagina( parseInt(pagina) -1);
        setAnimation(true);
    }

    const nextPage = () => {
        setInput(parseInt(input) + 1);
        setPagina(parseInt(pagina) + 1);
        setAnimation(true);
    }



    const onKeyDown = e => {

            if(e.keyCode == 13 ) {
                setAnimation(true);
                setPagina(parseInt(e.target.value));

                if(

                    parseInt(e.target.value < 1) ||
                    
                    parseInt(e.target.value) > Math.ceil (maximo) ||
                    isNaN ( parseInt (e.target.value))

                ){
                    setPagina(1)
                    setInput(1)
                } else {

                    setPagina(parseInt(e.target.value));

                }

            }
    };


    const onChange = e => {
        setInput(e.target.value);

    }



    return (  
        <> 
        <div
         
         className={`${jimf_theme === "white" ? 'pagination-components-white':'pagination-components' }`}
        >

            <button 
            disabled={ pagina === 1 || pagina < 1}
            onClick={prevPage}>
                {'<'}
            </button>

            <span>

            <input
                type="text"
                name="page"
                autoComplete="off"
                value={input}
                onChange={ (e) => onChange(e)}
                onKeyDown={ (e) => onKeyDown(e)}

            />
            <p> de <strong>{Math.ceil (maximo)}</strong> </p>

            </span>
            
            <button
                onClick={nextPage}
                disabled={ pagina === maximo || pagina > maximo}
            >
                {'>'}
            </button>



        </div>
        </>
    );
}
 
export default Pagination;