import React, {useContext} from 'react';
import authContext from '../context/autenticacion/authContext'

const Alerta = () => {
    

    const AuthContext = useContext(authContext);
    const { mensaje } = AuthContext;




    return ( 

            <div className="alerta-panther pt-2 pb-2">
                {mensaje}
            </div>
     );
}
 
export default Alerta;