import React, { Fragment, useContext , useEffect, useState, useMemo } from 'react';

import { Doughnut } from "react-chartjs-2";

import styled from 'styled-components'


import Modal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';  
import PropTypes from 'prop-types';

/// fade del modal
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import { 
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    CardFooter,
    Table,
    Row,
    Col,
    UncontrolledTooltip,
    Container,
    FormGroup,
    Input
  } from "reactstrap";  


  import Spinner from '../spinners/Spinner'
  import AuthContext from '../../context/autenticacion/authContext'



  // aqui solo aparece el responsive pequenho
const DivMobile = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const Divdesktop = styled.div `

display:none !important;






@media(min-width:992px) {
  display:block !important;
}



`; 





//// responsive
const Div = styled.div `
  padding: 0rem !important;
  margin: 0.2rem 0rem 2.0rem 0rem !important;
  display:flex !important;
  flex-direction:row;
  justify-content:center;
  
  
  
  
  
  @media(min-width:992px) {
    display:none !important;
    justify-content:center;
  }



`;

// pantalla grande
const DivModal = styled.div `

  display:none !important;
  flex-direction:row;
  
  
  
  
  
  @media(min-width:992px) {
    display:flex !important;
  }



`; 

/// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);





/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


  


const ModalPanther = ({pdf, paginaactualcount, modalInfo, onSubmit, onChange, amount, handleOpenDireccion,handleCloseDireccion, opendireccion, handleClose, handleOpen, mensaje, cargando, guardarCargando,ApiLoad,apiload}) => { 


  const authContext = useContext(AuthContext);

  const { 
    
    TodosOferentes, 
    TodosOfertas,
    TodosInversiones,
    TodosInversionesVendidas,

    TodosProyectosPrimario,
    TodosProyectosSecundario, 

    Movimientos,
    Saldos

   } = authContext;  


  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Invertir 
                                            </Fragment> 




    /////////// esto funciona para el suap

    const theme = useTheme();
    const [value, setValue] = React.useState(0);
  
  
  
    const handleChange = (event, newValue) => {
      setValue(newValue);
    };
  
    const handleChangeIndex = (index) => {
      setValue(index);
    }; 

    /////////////////////////////////

    const [chartstate, setChartState] = useState("data4");

  
    // vamos a extraer el context de emergencia para guardar los valores de la inversion 
    // despues 
  
    
    // vamos a hacer que el mensaje salga
    
  
    
     // configuracion del modal de material-ui
     const [ modalStyle ] =useState(getModalStyle);
     
  
     const classes = useStyles();
  
    

  



    return ( 



        <Fragment>
              

                        <Modal
   
                            className={`help-scroll ${classes.modal}`}
                            
                            open={opendireccion} 
                            paginaactualcount={paginaactualcount}
                            
                            pdf={pdf}
                            closeAfterTransition
                            BackdropComponent={Backdrop}
                            BackdropProps={{
                                timeout: 900,
                            }}
                            >
                                            <Fade in={opendireccion}
                                            timeout={154}
                                           > 
                            <div className="container">
                                                
                                                                <div className="row justify-content-center">
                                                                
                                                                <div className="col-12 d-flex justify-content-center">
                                                                <div className="container-fluid row d-flex seudo-hermano z-index-incremento">
                            
                     
                            <div  className="col-4">
                            <div className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario"  onClick={() => (  
                                                                                              handleCloseDireccion()

                              )}>
                                
                                
                                                          <div className="cancel-btn-panther text-fix-btn"> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div> 

                                                          
                                                      
                              </div>
                            
                            </div>
                                
                     
                      </div>  
                                                                
                                                                <div  className={`  card  modal-power-up-profile modal-direcciones`}> 
                                                                
                                                                                
                                                                            <h1>MOVIMIENTOS</h1>

                                                                            <div className="col-12 div-match ">


                                                                            <h3>Ahora puede exportar sus movimientos </h3>
                                                                            <h2> Está exportando sus movimientos de la página {paginaactualcount} en Formato PDF</h2>
                                                                            <h5> Desde pequeñas inversiones hasta grandes inversionistas | www.panther7.com </h5>
                                                                            


                                                                            
                                                                            <div  className="col-4 mt-1 "> 

                                                                                    <a href={ `${pdf}`} rel="noopener noreferrer" target="_blank" className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario btn-maps-index" >
                                                                                        
                                                                                        Generar PDF
                                                                                    
                                                                                    </a>
                                                                            </div> 
                                                                                    

                                                                            </div>


                                                                        

                                                                        </div> 

                                                                       
                                                                                    
                                                                        

                                                                       

                                                                        </div>

                                                                        </div>
                                                                        </div>
                                            </Fade>
                </Modal>


        </Fragment>

     );
}
 
export default ModalPanther;













