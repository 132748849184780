import React, { useReducer } from 'react';
import clienteAxios from '../../config/axios.js';


import SaldosContext from './SaldosContext';
import SaldosReducer from './SaldosReducer'; 


/// aqui vamos a colocar los types que son las funciones


import {
    SALDOS_DASH,
    SALDOS_DASH_ERROR
} from '../../types'; 



const SaldosState = props => {
    const initialState = {
        GANANCIAS:'',
        RETORNO:'',
        RETIRO:'',
        DEPOSITO:'',
        OFERTA:'',
        INVERTIDO:'',
        DISPONIBLE:'' ,
    }


    // dispatch es para ejectutar las acciones 
// tambien tenemos ekl state principal
const [state, dispatch] = useReducer(SaldosReducer, initialState)



const consultarApiSaldo = async () => { 

    try {
        const respuesta = await clienteAxios.post('/api/client/dashboard');
        
              
              
        dispatch({
            type:SALDOS_DASH,
            payload:(respuesta.data.data.saldos[0])
        }) ;
        
    } catch (error) { 
        console.log(error)
       
        dispatch({
            type:SALDOS_DASH_ERROR,
            payload:error.response
        }) ;
        
    }
}  









return (
    <SaldosContext.Provider
        value={{
            saldos: state.saldos,
            consultarApiSaldo,
        }}
    
    >

        {props.children}
    </SaldosContext.Provider>




) 

}





export default SaldosState;