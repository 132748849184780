
import React, { useContext, useState, useEffect, Fragment } from 'react';
import ActualizarPerfil from '../components/Profile/ActualizarPerfil';
import Actualizar from '../views/ActualizarProfileView/Actualizar';
import Kyc from '../components/Profile/Kyc'
import TarjetaFondeo from '../components/Profile/TarjetaFondeo'
import CuentaBancariaRetiro from '../components/Profile/CuentaBancariaRetiro'
import ScrollDown from '../components/IconsAnimation/ScrollDown'
import clienteAxios from '../config/axios';



// aqui vamos a colocar el context , de donde viene
import AuthContext from '../context/autenticacion/authContext'


///* estilos del carousel


import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { makeStyles, useTheme, withStyles } from '@material-ui/core/styles';

import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box'; 



// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  
  Row,
  Col
} from "reactstrap";


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}


const useStyles = makeStyles((theme) => ({
  root: {
    
    width: '100%',
  },
})); 

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    outline:'none',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);


const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    outline:'none',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      outline:'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      outline:'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
      
    },

  },
}))((props) => <Tab disableRipple {...props} />);


/// estilos para el carousel

const styles = {
  tabs: {
    background: '#fff',
  },
  slide: {
    padding: 15,
    minHeight: 700,
    color: '#fff',
  },
  slide1: {
    
    border: '1px solid #0047ff',
    borderRadius: '12px'
  },
  slide2: {
    
    border: '1px solid #0047ff',
    borderRadius: '12px'
  }
}; 


// const AutoPlaySwipeableViews = autoPlay(SwipeableViews);





const UserProfile = (props) => {  

  const [mensajeDos, guardarMensajeDos] = useState('');

  const authContext = useContext(AuthContext);
    const { status,userStatus,  usuario, listDatos,cuentas_internacionales,cuentas_nacionales, cuentas_remesas, ListaNacionales, ListaInternacionales, ListaRemesas } = authContext;  

    
      /// const api load 

  const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },3);
    
    
  }

  const [ apiload, guardarApiLoad] = useState(true);
//// aqui termina el api load 




    

  const [ recargardata, setRecargarData  ] = useState(false);


  const [ cuentas, setCuentas ] = useState([

    {
      id: '',
      client_id: '',
      bank_name: '',
      bank_account: '',
      bank_swift:'',
      created_at: '',
      updated_at: '',
      bank_client: '',
      bank_status:'',
      active:'',
      country_id:'',
      bank_personal:'',
  }

  ]); 

  
   
  
   const [ cuentaspropias, setCuentasPropias ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
      
   }

    ]); 
   
  
   const [ cuentasterceros, setCuentasTerceros ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
       bank_aba:'',
      
   }

   ]); 

  
   const [ cuentasremesas, setCuentasRemesas ] = useState([

     {
       id: '',
       client_id: '',
       bank_name: '',
       bank_account: '',
       bank_swift:'',
       created_at: '',
       updated_at: '',
       bank_client: '',
       bank_status:'',
       active:'',
       country_id:'',
      
   }

    ]); 



    const [appbar, guardarAppBar ] = useState({
      primero:1,
      segundo:0,
      tercero:0
    }) 


    const [ appbarkyc, guardarAppBarKyc] = useState({
      primero:1,
      segundo:0,
      tercero:0,
    }) 


    //// luego vamos a colocar las funciones de flecha, para usarlas en el navbar
    


    const ActivarPrimero = () => {
        guardarAppBar({...appbar,
          primero:1,
          segundo:0,
          tercero:0
        })
      }

    const ActivarSecundo = () => {
        guardarAppBar({          
          ...appbar,
          primero:0,
          segundo:1,
          tercero:0
        })
    } 

    const ActivarTercero = () => {
          guardarAppBar({
            ...appbar,
            primero:0,
            segundo:0,
            tercero:1
          })
      }  
      

//////////////////////////// kyc
      const ActivarPrimeroKyc = () => {
        guardarAppBarKyc({
          ...appbar,
          primero:1,
          segundo:0,
          tercero:0
        })
      }

    const ActivarSecundoKyc = () => {
        guardarAppBarKyc({          
          ...appbarkyc,
              primero:0,
              segundo:1,
              tercero:0
        })
    } 

    const ActivarTerceroKyc = () => {
          guardarAppBarKyc({
            ...appbarkyc,
                primero:0,
                segundo:0,
                tercero:1
          })
      } 







//   const [usuario, guardarUsuario] = useState({
//     id: '',
//     name:'',
//     email:'',
//     c:'',
//     client_type:'',
//     identity:'',
//     birthday_date:'',
//     total_rent:'',
//     status:'',
//     profession_id:'',
//     user_id:'',
//     telephone:'',
//     representative_name:'',
//     representative_cpf:'',
//     company_activity:'',
//     created_at:'',
//     updated_at:'',
//     address_street:'',
//     address_number:'',
//     address_city:'',
//     address_zipcode:'',
//     address_zone:'',
//     address_country:'',
//     is_ofertante:'',
//     comercial_name:'',
//     referido:'',
//     cpf_no_activo:'',
//     cpf:'',
//     first_name:'',
//     last_name:'',
//     middle_name:'',

// }); 

useEffect( () => {  


  const consultarApiBeneficiarios = async () => {
     
   
    // const propiasbank = {bank_personal:'1'}
    // const tercerosbank = {bank_personal:'2'}
    // const remesabank = {bank_personal:'3'}

    // const respuesta = await clienteAxios.post('/api/client/beneficiarios')
    // const respuestapropias = await clienteAxios.post('/api/client/beneficiarios',propiasbank)
    // const respuestaterceros = await clienteAxios.post('/api/client/beneficiarios',tercerosbank)
    // const respuestaremesa = await clienteAxios.post('/api/client/beneficiarios',remesabank)
    
    
    
    
     setCuentasTerceros(cuentas_internacionales)
     setCuentasPropias(cuentas_nacionales)
     setCuentasRemesas(cuentas_remesas)
    //  guardarProyectotis(respuesta.data.data.proyect)
    
    
  }

  consultarApiBeneficiarios()
  
  
  

 
 
  

},[!apiload, cuentas_internacionales,cuentas_nacionales,cuentas_remesas]);  



  const classes = useStyles();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleChangeIndex = (index) => {
    setValue(index);
  };

  // const [ value, setValue] = useState(0);
  // const handleTabs = (e , val) => {
  //   setValue(val)
  // }

  // const [toggleState, setToggleState ] = useState(1);
  // const toggleTab = (index) => {
  //   setToggleState(index);
  // }


  return (
    <>
      <div className="content"> 

      

            <div className={classes.root}> 

                  { status === 1 || status === 2 ? 

                  <>  

                        <div className="grid-app-bar-simple mb-2 "> 
                                        <div className="grid-items-3">
                                                                  <p className={` ${appbarkyc.primero === 1 ? 'active-selection-proyectos':''}`}
                                                                   onClick={ActivarPrimeroKyc}
                                                                  
                                                                  > Editar perfil
                                                                  <h4 >
                                                                     
                                                                     </h4>
                                                                   
                                                                  </p>
   
                                                                  <p className={`desactive-selection-proyectos ${appbarkyc.segundo === 1 ? 'active-selection-proyectos':''}`}
                                                                  
                                                                  onClick={ActivarSecundoKyc}
                                                                  
                                                                     
                                                                  
                                                                  >
                                                                    Centro de verificación
                                                                    <h4 >
                                                                     
                                                                   </h4>
                                                                    
                                                                    </p>


                                                                    <p className={`desactive-selection-proyectos ${appbarkyc.tercero === 1 ? 'active-selection-proyectos':''}`}
                                                                     onClick={ActivarTerceroKyc}
                                                                  >
                                                                    Cuentas bancarias
                                                                    <h4 >
                                                                     
                                                                   </h4>
                                                                    
                                                                    </p>

                                         </div>
                        </div>  


                   </>


                  : null
                            
                          }  


            { status === 3 ?

                <> 

                            <div className="grid-app-bar-simple  mb-2 "> 
                                        <div className="grid-items-2">
                                                                  <p className={` ${appbar.primero === 1 ? 'active-selection-proyectos':''}`}
                                                                   onClick={ActivarPrimero}
                                                                  
                                                                  > Editar perfil
                                                                   <h4 >
                                                                     
                                                                   </h4>
                                                                  </p>
   
                                                                  <p className={` ${appbar.segundo === 1 ? 'active-selection-proyectos':''}`}
                                                                     onClick={ActivarSecundo}
                                                                  
                                                                  >
                                                                    Cuentas bancarias
                                                                    <h4 >
                                                                    
                                                                   </h4>
                                                                    </p>

                                                                    {/* <p className={` ${appbar.tercero === 1 ? 'active-selection-proyectos':''}`}
                                                                     onClick={ActivarTercero}
                                                                  
                                                                  > Tarjetas
                                                                  <h4 >
                                                                  
                                                                 </h4>


                                                                  </p> */}
                                         </div>
                              </div>  

                 {/* <Row className="my-2">
                 <Col md="12" className="appbar-box-panther-dos">
                     <AppBar position="static" color="default" className="app-panther-bar-dos">
                  <StyledTabs value={value} 
                            onChange={handleChange} 
                            aria-label="styled tabs example"
                            > 

                           
                            
                      <StyledTab  label="Editar perfil" {...a11yProps(0)} />
                      
                      <StyledTab label="Cuentas bancarias" {...a11yProps(1)} />
                      
                      
                      
                      
                  </StyledTabs> 
                  </AppBar>
                  </Col>
                   </Row>   */}



                </>

                  : null
                            
                          }





                    
                  
                  { status === 1 || status === 2 ? 



                      <> 

                                <div className={` text-white ${appbarkyc.primero === 1 ? 'd-block':'d-none'}`} >
                                
                                    <Actualizar/>

                                     </div> 

                                     <div className={` text-white ${appbarkyc.segundo === 1 ? 'd-block':'d-none'}`} >
                                     <Row >
                                          <Kyc  setValue={setValue} recargardata={recargardata} setRecargarData={setRecargarData} userStatus={userStatus} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc}/>
                                      

                                          <Col md="4" className="box-perfil-center-fix">
                                            

                                          <Card className="card">
                                            <CardHeader>
                                                <div class="row justify-content-center d-flex">
                                                  <div class="col-12 text-center kyc-icon">

                                                  <i class="tim-icons icon-lock-circle"></i>

                                                  </div>
                                                
                                                </div>
                                              </CardHeader>
                                              <CardBody>
                                                
                                                <div class="row">
                                                <div class="col-12 kyc-text">

                                                  <h3> ¿Qué es KYC y para que sirve?</h3> 

                                                  <p>
                                                  KYC es el nombre que se le da al proceso de verificación de la identidad de un cliente. 
                                                  Este término, que se está convirtiendo en una expresión común, proviene del sector bancario. 
                                                  Las instituciones financieras siempre deben poder identificar a sus clientes cuando abren una nueva cuenta.
                                                  </p> 

                                                  <br/>

                                                  <p>
                                                  Los objetivos de las directrices de KYC son evitar que los bancos o entidades financieras sean utilizados,
                                                  intencionalmente o no, por elementos criminales para actividades de lavado de dinero. Los procedimientos 
                                                  relacionados también permiten a los bancos entender mejor a sus clientes y sus transacciones financieras.
                                                  </p>

                                                </div>


                                                </div>
                                              </CardBody> 
                                              <CardFooter className="d-flex justify-content-end">
                                                
                                              </CardFooter>
                                        
                                            </Card>
                                          </Col> 
                                        </Row> 

                                     </div> 

                                     <div className={` text-white ${appbarkyc.tercero === 1 ? 'd-block':'d-none'}`} >
                                      <Row > 

                            
                                          <CuentaBancariaRetiro 
                                                      mensajeDos={mensajeDos} 
                                                    guardarMensajeDos={guardarMensajeDos} 
                                                    cuentaspropias={cuentaspropias}
                                                      cuentasterceros={cuentasterceros}
                                                      cuentas={cuentas} 
                                                      setCuentas={setCuentas} 
                                                      recargardata={recargardata} 
                                                      setRecargarData={setRecargarData}
                                                      apiload={apiload} 
                                                      ApiLoad={ApiLoad} 
                                                      cuentasremesas={cuentasremesas}
                                                        
                                                      ListaInternacionales={ListaInternacionales}
                                                      ListaRemesas={ListaRemesas}
                                                      ListaNacionales={ListaNacionales}
                                                />
                                      
                                        </Row> 
                                    </div>

                   
                                      {/* <SwipeableViews
                                        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                        index={value}
                                        onChangeIndex={handleChangeIndex}
                                      >
                                        <TabPanel value={value} index={0} dir={theme.direction}  >
                                          <ActualizarPerfil usuario={usuario}  setValue={setValue} listDatos={listDatos} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc}/> 
                                        </TabPanel>

                                        <TabPanel value={value} index={1} dir={theme.direction}>

                                          <Row >
                                                <Kyc  setValue={setValue} recargardata={recargardata} setRecargarData={setRecargarData} userStatus={userStatus} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc}/>
                                            

                                                <Col md="4" className="box-perfil-center-fix">
                                                  

                                                <Card className="card">
                                                  <CardHeader>
                                                      <div class="row justify-content-center d-flex">
                                                        <div class="col-12 text-center kyc-icon">

                                                        <i class="tim-icons icon-lock-circle"></i>

                                                        </div>
                                                      
                                                      </div>
                                                    </CardHeader>
                                                    <CardBody>
                                                      
                                                      <div class="row">
                                                      <div class="col-12 kyc-text">

                                                        <h3> ¿Qué es KYC y para que sirve?</h3> 

                                                        <p>
                                                        KYC es el nombre que se le da al proceso de verificación de la identidad de un cliente. 
                                                        Este término, que se está convirtiendo en una expresión común, proviene del sector bancario. 
                                                        Las instituciones financieras siempre deben poder identificar a sus clientes cuando abren una nueva cuenta.
                                                        </p> 

                                                        <br/>

                                                        <p>
                                                        Los objetivos de las directrices de KYC son evitar que los bancos o entidades financieras sean utilizados,
                                                        intencionalmente o no, por elementos criminales para actividades de lavado de dinero. Los procedimientos 
                                                        relacionados también permiten a los bancos entender mejor a sus clientes y sus transacciones financieras.
                                                        </p>

                                                      </div>


                                                      </div>
                                                    </CardBody> 
                                                    <CardFooter className="d-flex justify-content-end">
                                                      
                                                    </CardFooter>
                                              
                                                  </Card>
                                                </Col> 
                                              </Row> 

                                        </TabPanel> 

                                        <TabPanel value={value} index={2} dir={theme.direction}>

                                          <Row > 

                                                
                                                <CuentaBancariaRetiro        

                                                    apiload={apiload} 
                                                    ApiLoad={ApiLoad} 
                                                    mensajeDos={mensajeDos} 
                                                    guardarMensajeDos={guardarMensajeDos}
                                                    cuentaspropias={cuentaspropias} 
                                                    cuentasterceros={cuentasterceros} 
                                                    cuentas={cuentas}
                                                      setCuentas={setCuentas}
                                                      recargardata={recargardata} 
                                                      setRecargarData={setRecargarData} 
                                                      ListaInternacionales={ListaInternacionales}
                                                      ListaRemesas={ListaRemesas}
                                                      ListaNacionales={ListaNacionales}
                                                      
                                                      />
                                            
                                          </Row> 

                                        </TabPanel>  

                                  
                                      </SwipeableViews>  */}

                  </>
                    

                :null
                }  


                
          { status === 3 ?  



                  <> 

                                    <div className={` text-white ${appbar.primero === 1 ? 'd-block':'d-none'}`} >

                                    <Actualizar/>

                                    {/* <ActualizarPerfil usuario={usuario}  setValue={setValue} listDatos={listDatos} guardarAppBar={guardarAppBar} guardarAppBarKyc={guardarAppBarKyc} appbar={appbar} appbarkyc={appbarkyc} />  */}

                                    

                                     </div>
                                     <div className={`  ${appbar.segundo === 1 ? 'd-block':'d-none'}`}>
                                     
                                     <CuentaBancariaRetiro 
                                        mensajeDos={mensajeDos} 
                                        guardarMensajeDos={guardarMensajeDos} 
                                        cuentaspropias={cuentaspropias}
                                          cuentasterceros={cuentasterceros}
                                          cuentas={cuentas} 
                                          setCuentas={setCuentas} 
                                          recargardata={recargardata} 
                                          setRecargarData={setRecargarData}
                                          apiload={apiload} 
                                          ApiLoad={ApiLoad} 
                                          cuentasremesas={cuentasremesas}
                                            
                                          ListaInternacionales={ListaInternacionales}
                                          ListaRemesas={ListaRemesas}
                                          ListaNacionales={ListaNacionales}
                                        />


                                     </div>

                   
                   {/* <SwipeableViews
                     axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                     index={value}
                     onChangeIndex={handleChangeIndex}
                   >
                     <TabPanel value={value} index={0} dir={theme.direction}  >
                     <ActualizarPerfil usuario={usuario}  setValue={setValue} listDatos={listDatos}/> 
                     </TabPanel>
 
                    
 
                     <TabPanel value={value} index={1} dir={theme.direction}>
 
                       <Row > 
 
                             
                       <CuentaBancariaRetiro 
                       mensajeDos={mensajeDos} 
                       guardarMensajeDos={guardarMensajeDos} 
                       cuentaspropias={cuentaspropias}
                        cuentasterceros={cuentasterceros}
                         cuentas={cuentas} 
                         setCuentas={setCuentas} 
                         recargardata={recargardata} 
                         setRecargarData={setRecargarData}
                         apiload={apiload} 
                          ApiLoad={ApiLoad} 
                          cuentasremesas={cuentasremesas}
                          
                          ListaInternacionales={ListaInternacionales}
                                   ListaRemesas={ListaRemesas}
                                   ListaNacionales={ListaNacionales}
                          />
                         
                       </Row> 
 
                     </TabPanel>  
 
                   
                
                   </SwipeableViews>  */}



                   </>
                     
 
                 :null
                 }

                </div>      

        
        
     
    
        

       

      </div>
    </> 

    
  );
}
 
export default UserProfile;
