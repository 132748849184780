import React, {createContext, useState, useEffect} from 'react';



export const ModalPasswordContext = createContext();

const ModalProvider = (props) => {

    // state del provider / necesitamos guardar el id, hasta cuando el usuario de click
    const [ idinversion, guardarIdInversion] = useState(null);

    //vamos a crear otro state para guardar la receta de la consulta
    const [informacion, guardarInversion] = useState({})

    
    // una ves tenemos la receta queremos llamar a la receta
    // useEffect(() => {

    //     const obtenerPassword = async () => {

    //         if(!idinversion) return;

    //         const url = `/api/client/projects`

    //         const resultado = await axios.post(url);
    //         guardarInversion(resultado.data);

    //         console.log(resultado);

            
    //     }
    //     obtenerPassword();
    // }, [idinversion]);


    return ( 

            <ModalPasswordContext.Provider
                value={{
                    informacion,
                    guardarIdInversion,
                    guardarInversion
                }}
            >
                {props.children}
            </ModalPasswordContext.Provider>

     );
}
 
export default ModalProvider;