import React from 'react'



const LineSpinner = () => {
    return (  



            <>


            <div className=" line-Spinner">

                <h3>
                    Cargando...
                </h3>

                <span className="line-Spinner-son">

                </span>


            </div>
            
            
            </>


    );
}
 
export default LineSpinner;