import React, { useState, useContext, useEffect, Fragment, useReducer } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../logo.svg'; 

import {
   
    LIMPIAR_ALERTA,
    

} from '../../types'; 



import Alerta from '../Alerta';

import AuthContext from '../../context/autenticacion/authContext.js'
import AuthReducer from '../../context/autenticacion/authReducer.js';

const ResetPassword = (props) => {  

    
    
     
  
      // ahora vamos a extraer los valores de registrar usuario del context
  
      const authContext = useContext(AuthContext);
      const { mensaje, autenticado, iniciarSesion,userStatus , olvidoPassword } = authContext; 

      const [ registrarseclick, setRegistrarseClick ] = useState(false);
      

      useEffect( () => {
        
        
     


            if(registrarseclick) {
           
      
                props.history.push('/');
                
           
        }



          


        


    } , [mensaje, autenticado,registrarseclick, props.history]
    );


  


    // estamos creando el state 
    const [usuario, guardarUsuario] = useState({
        email:'',
        
    });

    // extraer usuario del state

    const {email} = usuario;


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault(); 

        // validar que no haya campos vacios

        if(email.trim() === '' ) {
            
            return

        } 

        

        
        olvidoPassword({ email});

    }







    return ( 
        <div className="form-usuario"> 

        
            <div className="contenedor-form sombra-dark">

                <Logo/>
                <h1 className="login-font">Restablecer contraseña</h1>     
                {mensaje && <Alerta/>}
                
                    <form onSubmit={onSubmit}>
                    <div className="campo-form mt-4">
                                <label htmlFor="email" >Email </label>

                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Escribe tu correo electrónico"
                                    onChange={onChange}
                                    value={email}
                                    className="campo-form-panther"
                                    required
                                    />
                        </div>
                        

                        <div className="campo-form">

                       
                                <input
                                type="submit" className="btn btn-primario btn-block btn-panther-power"
                                value=" Enviar enlace para restablecer contraseña"  />
                                
                                

                        </div>
                        
                    </form>

                    <Link to={"/nueva-cuenta"} className="enlace-cuenta"  >
                    ¿No tienes una cuenta? Regístrate
                    </Link> 

                    <Link to={"/login"} className="enlace-cuenta  resetpassword" >
                    Iniciar Sesion
                    </Link>

            </div>
        </div>

     );
}
 
export default ResetPassword;
