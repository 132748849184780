import React, { useState, useEffect} from 'react';
import { Line, Bar } from "react-chartjs-2"; 


import {
    Button,
    ButtonGroup,
    Card,
    CardHeader,
    CardBody,
    CardTitle,
    DropdownToggle,
    DropdownMenu,
    DropdownItem,
    UncontrolledDropdown,
    Label,
    FormGroup,
    Input,
    Table,
    Row,
    Col,
    UncontrolledTooltip
  } from "reactstrap";

import clienteAxios from 'config/axios'

function currencyFormat(num) {
  return '$' + num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
}


const Grafica = (props) => { 

  
  



const [chartData, setChartData ] = useState({});
    
  const chart = (canvas) => {
    

    let userMontos = [];
    let userFechas = [];

    // let montos = currencyFormat(userMontos);
    // let fechas = currencyFormat(userFechas);

    
    

    clienteAxios
      .post("/api/client/investments/grafica")
      .then(res => {
        
        // console.log(res.data.data[0].amount);
        // console.log(res.data.data);
        // console.log(res.data.label);

        // console.log(res);

        for (const dataObj of res.data.data) {


          userMontos.push(dataObj.amount);
        //   format(dataObj.amount)
          
        }

        for (const dataObj of res.data.label) {
            
            userFechas.push(dataObj.fecha);
          }

        //   console.log(userFechas, userMontos);
        setChartData(
                
                    {
                        labels:userFechas,
                        datasets: [

                            {
                                label: "Inversion efectuada correctamente a un monto de ",
                                fill: true,
                                backgroundColor: "rgba(255,255,255,0)",
                                borderColor: "#1f8ef1",
                                borderWidth: 2,
                                borderDash: [],
                                borderDashOffset: 0.0,
                                pointBackgroundColor: "#1f8ef1",
                                pointBorderColor: "rgba(255,255,255,0)",
                                pointHoverBackgroundColor: "#1f8ef1",
                                pointBorderWidth: 20,
                                pointHoverRadius: 4,
                                pointHoverBorderWidth: 15,
                                pointRadius: 4,
                                data: userMontos,
                                
                              }    
                        ]
                    }
        )



      })
      .catch(err => {
        console.log(err);
      });
    
  };



    
  useEffect(() => {
    chart();
  }, []);



    



    


    return (
        <Card className="card-chart card-panther-blu-grafica">
        <CardHeader>
          <Row>
            <Col className="text-left" sm="6">
              <h5 >Todas sus inversiones</h5>
              <CardTitle tag="h2">Mis inversiones</CardTitle>
            </Col>
            
          </Row>
        </CardHeader>
        <CardBody>
          <div className="chart-area">
          <Line
          data={chartData}
          options={{
            maintainAspectRatio: false,
                legend: {
                    display: false
                },
                tooltips: {
                    backgroundColor: "#f5f5f5",
                    titleFontColor: "#333",
                    bodyFontColor: "#666",
                    bodySpacing: 4,
                    xPadding: 12,
                    mode: "nearest",
                    intersect: 0,
                    position: "nearest"
                },
                responsive: true,
            scales: {
                yAxes: [
                  {
                    barPercentage: 1.6,
                    gridLines: {
                      drawBorder: false,
                      color: "#00ffc33d",
                      zeroLineColor: "transparent"
                    },
                    ticks: {
                      suggestedMin: 60,
                      suggestedMax: 125,
                      padding: 20,
                      fontColor: "#9a9a9a"
                    }
                  }
                ],
                xAxes: [
                  {
                    barPercentage: 1.6,
                    gridLines: {
                      drawBorder: false,
                      color: "rgba(29,140,248,0.1)",
                      zeroLineColor: "transparent"
                    },
                    ticks: {
                      padding: 20,
                      fontColor: "#9a9a9a"
                    }
                  }
                ]
              }
          }}
        />
          </div>
        </CardBody>
      </Card>



      );
}
 
export default Grafica;