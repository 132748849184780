
import React from "react";
// used for making the prop types of this component
import PropTypes from "prop-types";

// reactstrap components
import { Container, Row, Nav, NavItem, NavLink } from "reactstrap";

const Footer = () => {
  return (
    <footer className="footer">
      <Container fluid>
       <Nav>
           {/* <NavItem>
            <NavLink href="https://www.panther7.com/?ref=bdr-user-archive-footer">Panther 7</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.panther7.com/presentation?ref=bdr-user-archive-footer">About Us</NavLink>
          </NavItem>
          <NavItem>
            <NavLink href="https://www.panther7.com/blog?ref=bdr-user-archive-footer">Blog</NavLink>
          </NavItem> */} 
          <>
          </>
        </Nav>
        <div className="copyright">
          © {new Date().getFullYear()} 
          {" "}
          <a className="text-white"
            href="https://www.panther7.com/"
            target="_blank"
          >
             Todos los derechos Reservados Panther 7
          </a>{" "}
          Plataforma de Inversiones
        </div>
      </Container>
    </footer>
  );
}
 
export default Footer;

