
import React, {useContext,useEffect, useState} from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// react plugin used to create charts
import { Line, Bar } from "react-chartjs-2";
import SaldosdelCliente from './SaldodelClienteOri';
import UltimosMov from './UltimosMov';
import ProductosDeInversion from './ProductosDeInversion';
import Grafica from './Grafica';


// reactstrap components
import {
  Button,
  ButtonGroup,
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  UncontrolledDropdown,
  Label,
  FormGroup,
  Input,
  Table,
  Row,
  Col,
  UncontrolledTooltip
} from "reactstrap"; 


import AuthContext from  '../context/autenticacion/authContext'  
import SaldosContext from  '../context/saldos/SaldosContext'  


const Dashboard = () => {


  

  const authContext = useContext(AuthContext);
  const { usuarioAutenticado, status, CerrarSesion } = authContext; 
  
    
  const saldosContext = useContext(SaldosContext);
   const { saldos, consultarApiSaldo } = saldosContext;     

   const ApiLoad = () => {
    guardarApiLoad(false);
    setTimeout(() => {
      guardarApiLoad(true);
  },3);
    
    
  }

  const [ apiload, guardarApiLoad] = useState(true);
//// aqui termina el api load 



  

  return (  

    <>
    <div className="content">
    <SaldosdelCliente saldos={saldos}/>

      <Row>
        <Col xs="12">
          <Grafica />
        </Col>
      </Row> 


      <div className="grid-dashboard-template">

        <div className="grid-dash-element-1">
        <ProductosDeInversion apiload={apiload} ApiLoad={ApiLoad} />
        </div>
        

        

        <div className="grid-dash-element-2">
        <UltimosMov apiload={apiload} ApiLoad={ApiLoad} />
        </div>
        

        

      </div>



      {/* <Row className=" justify-content-center ">
      
      
        
        </Row>  */}
    </div>
  </>

  );
}
 
export default Dashboard;