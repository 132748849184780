import React, { useState, useEffect} from 'react'
import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 
import {ReactComponent as Logo} from '../../assets/scss/black-dashboard-react/panther/logo.svg'; 
import {ReactComponent as Wa} from '../../whatsaap.svg'; 
import superbg from '../../assets/Landing/Danni_Panther01.jpg'
import NumberFormat from 'react-number-format';
import { Link } from 'react-router-dom';


// export const add = () => {
//     fbq('init', '1031912887741734');
//     fbq('track', 'PageView');
// }


const LandingIndex = () => {
    

    

    // useEffect(()=>{
    //     add()
        


    // },[]);

    
    const [calculartext, SetCalcularText] = useState(false);



    const [pasos, guardarCuestionario] = useState({
        paso:'monto',
        resultado: false,
    })


    const PasoTiempo = () => {
        guardarCuestionario({
            ...pasos,
            paso:'tiempo'
        })
    }

    const PasoMonto = () => {
        guardarCuestionario({
            ...pasos,
            paso:'monto'
        })
    }



    const [tiempo, guardarTiempo] =useState({
            meses:6,
    })


    const [usuariologin, guardarUsuario] = useState({
        email:'',
        password: '',
        amount:'',
    });  

    
    const [resultado, guardarResultado] = useState(0);
    const [resultadosuma, guardarResultadoSuma] = useState(0);

    const [animation, setAnimation]= useState(false)



    const ActivarTiempoMes1 = () => {
        
        guardarTiempo({
            ...tiempo,
            meses:1
        })
    }

    
    const ActivarTiempoMes2 = () => {
        guardarTiempo({
            ...tiempo,
            meses:2
        })
    }
    
    const ActivarTiempoMes3 = (e) => {
        e.preventDefault();
        guardarTiempo({
            ...tiempo,
            meses:3
        })
        {resultadosuma !== 0 ? SetCalcularText(true):SetCalcularText(false)}
    }
    
    const ActivarTiempoMes4 = () => {
        guardarTiempo({
            ...tiempo,
            meses:4
        })
    }
    
    const ActivarTiempoMes5 = () => {
        guardarTiempo({
            ...tiempo,
            meses:5
        })
    }

        
    const ActivarTiempoMes6 = (e) => {
        e.preventDefault();
        guardarTiempo({
            ...tiempo,
            meses:6
        })
        {resultadosuma !== 0 ? SetCalcularText(true):SetCalcularText(false)}
    }

    const ActivarTiempoMes7 = () => {
        guardarTiempo({
            ...tiempo,
            meses:7
        })
    }


    const ActivarTiempoMes8 = () => {
        guardarTiempo({
            ...tiempo,
            meses:8
        })
    }

    const ActivarTiempoMes9 = () => {
        guardarTiempo({
            ...tiempo,
            meses:9
        })
    }

    const ActivarTiempoMes10 = () => {
        guardarTiempo({
            ...tiempo,
            meses:10
        })
    }

    const ActivarTiempoMes11 = () => {
        guardarTiempo({
            ...tiempo,
            meses:11
        })
    }

    const ActivarTiempoMes12 = (e) => {
        e.preventDefault();
        guardarTiempo({
            ...tiempo,
            meses:12
        })
        {resultadosuma !== 0 ? SetCalcularText(true):SetCalcularText(false)}
    }




    
  useEffect(() => { 

        if(usuariologin.amount === "" || usuariologin.amount === 0 ){
            guardarResultado(0);
            

            guardarUsuario({
                email:'',
                password: '',
                amount:'',
            })
        }

        if (animation === true ) {
            setTimeout(() => {
                setAnimation(false);
        },100);



            
        }

        guardarResultadoSuma(parseFloat(resultado)+parseFloat(usuariologin.amount))
  

    
    
  }, [usuariologin.amount,animation,tiempo, resultado,calculartext]) 

    
     

    const onChange = e => {

        guardarUsuario({
            ...usuariologin,
            [e.target.name] : e.target.value

        })
    }





    const onSubmit = e => {
        
        e.preventDefault(); 
        setAnimation(true);
        SetCalcularText(false)
        // // elGuardarCargando(true)
        var suma = parseFloat(usuariologin.amount) * 0.00033333333;
        var total=0;
        switch(parseInt(tiempo.meses))
        {
        
         case 1:
               total=suma*30;
            break;
             case 2:
                 total=suma*60;
                break;
                case 3:
                    total=suma*90;
                    break;
                    case 4:
                        total=suma*120;
                        break;
                        case 5:
                             total=suma*150;
                            break;
                            case 6:
                                 total=suma*180;
                                break;
                                case 7:
                                     total=suma*210;
                                    break;
                                    case 8:
                                         total=suma*240;
                                        break;
                                        case 9:
                                             total=suma*270;
                                            break;
                                            case 10:
                                                 total=suma*300;
                                                break;
                                                case 11:
                                                     total=suma*330;
                                                    break;
                                                case 12:
                                                     total=suma*360;
                                                    break;
                                                    default:
                                                        total=suma*30;
                                                    break;
                                                }

        // guardarResultado(Math.ceil(total))
        guardarResultado(total);

        
        

    }




    return (  

        <>

        <section className="content-view">

            

            <div className="panther-nav-temp ">

            <a href="https://www.panther7.com" className="Hovermaster" data-tooltipblank="ir a Panther7">

                

                
                    <div className=" box-logo-white" >
                    <img src={LogoWhite} className=" logo-size-2" />
                    </div>
                    
                    

                        

                    

                    
                    </a> 
                    
                    <p>Plaforma Global de Pagos e Inversiones</p>

            </div>

            <a href="https://api.whatsapp.com/send?phone=50762740755" rel="noopener noreferrer" target="_blank" >
            <h3 className="good">Contáctenos 
                
                <Wa className=" logo-size-2" />
                
            </h3>
            </a>

            

            

        <div className="box-landing" >

            


        <div className="elemento-1">

            <h2>
                ¿Quieres <br/>  Iniciarte en el mundo <br/>  de las <br/>  inversiones?

            </h2>

            <p>
                Somos tus aliados, ven y consulta, <br/>
                hoy mismo!
            </p>

            

        </div>

        <div className="elemento-2 suave-2 trans">

        <div className={` btn-panther-cuenta-calculo ${resultado !== 0 ? 'd-grid' :'d-none'}`} >
            <Link to={"/nueva-cuenta"} className="">
                    ¡Crear su cuenta ahora!
                    </Link> 
            </div>

            <form onSubmit={onSubmit} >

            <Logo className="suaev-2 logo-size"/>
                                    <div >

                                    
                                     

                                       <div className={`${pasos.paso === 'monto' ? 'd-grid' :'d-none'}`}> 

                                       

                                       <h3 className="askme">¿Cuánto quiere invertir?</h3>

                                       <div className="input-yeah">

                                       <NumberFormat className={` height-10 ${usuariologin.amount !== '' ? 'd-grid' :'d-none'}`} value={usuariologin.amount}   displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/>
                                    <h5 className="height-11">USD</h5>
                                       <label className="BoxLabelAlert">Escriba el monto de su inversión : 
                                       </label>

                                            
                                            <input type="number"    
                                                    //    placeholder="USD"
                                                      id="amount"
                                                      name="amount"
                                                      min={50}
                                                      
                                                      onChange={onChange}
                                                      
                                                      value={usuariologin.amount} 
                                                      required                                                      
                                                      />


                                        </div>

                                      
                                
                                        
                                        </div> 
                                        {usuariologin.amount !== ""?
                                        
                                            <>
                                            </> : <>
                                            <span className='LabelAlert'>*****Coloque solamente números ( $$$ ), sin asteriscos o simbolos especiales****</span></>
                                    }

                                        <div className={`suave trans ${usuariologin.amount !== '' ? 'd-grid ' :'d-none'}`}> 
                                      
                                        <label className="">Indique el plazo de su inversión </label>

                                                <div className="box-seleccion-tiempo">
                                                    
                                                    {/* <span onClick={ActivarTiempoMes1} className={`${tiempo.meses === 1 ? 'active-land' :''}`}>1</span>
                                                    <span onClick={ActivarTiempoMes2} className={`${tiempo.meses === 2 ? 'active-land' :''}`}>2</span> */}
                                                    <button onClick={ActivarTiempoMes3} className={`${tiempo.meses === 3 ? 'active-land' :''}`}>3 Meses</button>
                                                    {/* <span onClick={ActivarTiempoMes4} className={`${tiempo.meses === 4 ? 'active-land' :''}`}>4</span>
                                                    <span onClick={ActivarTiempoMes5} className={`${tiempo.meses === 5 ? 'active-land' :''}`}>5</span> */}
                                                    <button onClick={ActivarTiempoMes6} className={`${tiempo.meses === 6 ? 'active-land' :''}`}>6 Meses</button>
                                                    {/* <span onClick={ActivarTiempoMes7} className={`${tiempo.meses === 7 ? 'active-land' :''}`}>7</span>
                                                    <span onClick={ActivarTiempoMes8} className={`${tiempo.meses === 8 ? 'active-land' :''}`}>8</span>
                                                    <span onClick={ActivarTiempoMes9} className={`${tiempo.meses === 9 ? 'active-land' :''}`}>9</span>
                                                    <span onClick={ActivarTiempoMes10} className={`${tiempo.meses === 10 ? 'active-land' :''}`}>10</span>
                                                    <span onClick={ActivarTiempoMes11} className={`${tiempo.meses === 11 ? 'active-land' :''}`}>11</span> */}
                                                    <button onClick={ActivarTiempoMes12} className={`${tiempo.meses === 12 ? 'active-land' :''}`}>1 Año</button>

                                                </div>
                                        </div> 

                                        <div className="amount-space d-none ">
                                            {usuariologin.amount !== "" ? <><NumberFormat value={usuariologin.amount}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/></>: <><p>Escriba su monto a invertir</p></>}
                                        </div>

                                        {/* <div>
                                            <span onClick={PasoTiempo} className="btn btn-primario btn-block btn-panther-power btn-login animation-icon-in-cuatro mt-2">
                                                Siguente
                                            </span>
                                        </div> */}

                                        <div className="d-none">
                                            <span onClick={PasoMonto} className="btn btn-primario btn-block btn-panther-power btn-login animation-icon-in-cuatro">
                                                Cambiar Monto
                                            </span>
                                        </div>

                                        <div className="">

                                            {usuariologin.amount === "" ? <>
                                                    <button type="submit" className={`${calculartext === false  ? '':''} btn-resultado my-0`}>        
                                                        Calcular
                                                </button>
                                            </>
                                            : 
                                            <>
                                            <button type="submit" className={`${calculartext === false  ? '':''} btn-resultado my-0`}>

                                                {resultadosuma !== 0 ? <>{calculartext === true ? <>Calcular</> : <>Calculado</>}</> : <>Calular</>}

                                                </button>

                                            </>}

                                            
                                            
                                        </div>

                                        

                                        <div className={` suave-2 pl-2 pr-2 ${usuariologin.amount !== '' ? 'd-grid' :'d-none'}`}> 

                                             
                                                    <p className={` points  ${usuariologin.amount !== "" || usuariologin.amount !== 0 ? 'd-grid' :'d-none'}`}> {resultado !== 0  ? <>Monto calculo: </> :<>
                                                    Usted está escribiendo<little className=""><strong className='anim1'>.</strong><strong className='anim2'>.</strong><strong className='anim3'>.</strong></little>
                                                    
                                                    </>}
                                                     </p>
                                                    
                                                    <NumberFormat className="  text-panther " value={usuariologin.amount}   displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/>

                                                    
                                                
                                                
                                                

                                                {/* <span className={`${usuariologin.amount !== '' ? 'd-flex' :'d-none'}`}>
                                                con un plazo de
                                                <strong className="pl-1 pr-1">{tiempo.meses}</strong>
                                                Meses
                                                </span> */}

                                                

                                        </div>

                                        
                                            <div className={`BoxGanancias ${animation === true  ? 'suave-3' :''} ${usuariologin.amount !== "" ? 'd-grid' :'d-none'}`}>

                                            

                                        <div
                                            className={` ${resultado !== 0  ? 'd-grid' :'d-none'}`}
                                        >

                                             <div className="cuento-titulo-resultado">Su ganancia sería de: </div>


                                                <NumberFormat className="resultado-total-2" value={resultado}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/>

                                                

                                        </div>

                                        <div
                                            className={` ${resultado !== 0  ? 'd-grid' :'d-none'}`}
                                        >

                                             <div className="cuento-titulo-resultado">Su saldo total sería de: </div>


                                                <NumberFormat className="resultado-total" value={resultadosuma}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/>

                                                

                                        </div>

                                        </div>

                                        <div className={` ${animation === true  ? 'suave-3' :''} ${usuariologin.amount !== "" ? 'd-grid' :'d-none'}`}>

                                            

                                            <div
                                                className={` ${resultado !== 0  ? 'd-grid' :'d-none'}`}
                                            >

                                              

                                                    <div className="cuento-resultado-texto">
                                                    Calculo Aproximado obtenido con las rentabilidades medias anuales ponderadas ofrecidas por Panther7 para cada categoría a un plazo de 1 año. Rentabilidades pasadas no garantizan rentabilidades futuras. Las rentabilidades utilizadas para simular son netas <br/> <strong>(no incluye gastos no reflejados en el valor liquidativo)</strong>

                                                    </div>

                                            </div>

                                            </div>


                                    </div> 

            </form>

        </div>

        <div className="panther-style" style={{ background: `linear-gradient(rgba(37, 56, 165, 0), rgb(10, 18, 34)), url(${superbg})` }}>

            </div>

        </div>


        </section>


        


        </>



    );
}
 
export default LandingIndex;