import React, { useState,useContext, useEffect, Fragment } from 'react';
import { Link } from 'react-router-dom';
import {ReactComponent as Logo} from '../logo.svg'; 
import TextSpinner from '../spinners/TextSpinner'



import Alerta from '../Alerta';

import AuthContext from '../../context/autenticacion/authContext.js'


const Login = (props) => { 
    const [ cargando, guardarCargando] = useState(false);
     
  
      // ahora vamos a extraer los valores de registrar usuario del context
  
      const authContext = useContext(AuthContext);
      const { mensaje, autenticado, iniciarSesion,userStatus } = authContext; 
      

      useEffect( () => {
        guardarCargando(true); 

        
        setTimeout( () => {
              
            // guardar Balance
            
                    if(autenticado) {
                        props.history.push('/cliente');
                    } 


              // cambiar el estado de cargando
              guardarCargando(false);
            
            },1000);


          


        


    } , [mensaje, autenticado, props.history]
    );


  


    // estamos creando el state 
    const [usuario, guardarUsuario] = useState({
        email:'',
        password: ''
    });

    // extraer usuario del state

    const {email,password} = usuario;


    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarUsuario({
            ...usuario,
            [e.target.name] : e.target.value

        })
    }


    /// cuando el usuario quiere iniciar seseion
    const onSubmit = e => {
        e.preventDefault(); 

        // validar que no haya campos vacios

        if(email.trim() === '' || password.trim() ==='') {
            
            return

        } 

        if (password.length < 6) {
            
            return
        }

        userStatus({ email,password});
        iniciarSesion({ email,password});

    }



    const cargandoform = ( cargando ) ? <TextSpinner/> :  
                                          
    <Fragment>
    <div>
                       <div className="campo-form">
                                <label htmlFor="email" >Email</label>

                                <input
                                    type="email"
                                    id="email"
                                    name="email"
                                    placeholder="Tu Email"
                                    onChange={onChange}
                                    value={email}
                                    className="campo-form-panther"
                                    />
                        </div>

                        <div className="campo-form">
                                <label htmlFor="password" >Password</label>

                                <input
                                    type="password"
                                    id="password"
                                    name="password"
                                    placeholder="Tu Password"
                                    onChange={onChange}
                                    value={password}
                                    className="campo-form-panther"
                                    />
                        </div>

                       </div>
      </Fragment> 





    return ( 
        <div className="form-usuario"> 

        
            <div className="contenedor-form sombra-dark">

                <Logo/>
                <h1 className="login-font">Iniciar Sesion</h1>     
                {mensaje && <Alerta/>}
                
                    <form onSubmit={onSubmit}>
                       {cargandoform}
                        

                        <div className="campo-form">

                       
                                <input
                                type="submit" className="btn btn-primario btn-block"
                                value="Iniciar Sesion"/>
                                
                                

                        </div>
                        
                    </form>

                    <Link to={"/nueva-cuenta"} className="enlace-cuenta">
                        Crear tu cuenta Ahora!
                    </Link>

            </div>
        </div>

     );
}
 
export default Login;
