import axios from 'axios';

const token = token;

const clienteAxios = axios.create({
    baseURL : process.env.REACT_APP_BACKEND_URL,
    
    
   
   
});


export default clienteAxios;

// esto lo vamos a pasar al state  