import React, { useState , useEffect, useContext, Fragment } from "react";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import ClienteLayout from "layouts/Cliente/Cliente.js";
import ClienteLayoutPen from "layouts/Cliente/ClientePen.js";
import { createBrowserHistory } from "history";
import AuthContext from '../context/autenticacion/authContext'



/// definicion del modal
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import tokenAuth from '../config/token' 

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardText,
  FormGroup,
  Form,
  Input,
  Row,
  Col
} from "reactstrap";





function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));




const Main = (props) => {
 
    const hist = createBrowserHistory();
    const authContext = useContext(AuthContext);
    const { autenticado,usuario,usuarioAutenticado, listDatos,actualizarDatos,listadoContratos, status, CerrarSesion } = authContext;  


    // vamos a colocar el modal 
    const [open, setOpen] =useState(false);

      const classes = useStyles();

      const handleOpen = () => {
          setOpen(true);
      } 

      const handleClose = () => {
          setOpen(false);;
      }

      
      const [mensaje, guardarMensaje] = useState({
        message:""
        })



 



    // extraer informacion de autenticacion

  
  useEffect( ()=> {
   

    
    
    

    if(!autenticado) {
      localStorage.removeItem('token');
      props.history.push('/login');
    } 

    if(status === 0) {
      localStorage.removeItem('token');
      props.history.push('/login');
    }  

     setTimeout(() => {
      
       CerrarSesion();
   },80400000);

   


}, [status,autenticado, props.history]);  



    return (  




            <Fragment>  

              
              
              
              

               { status === 3 || status === 2 ?  
              <ClienteLayout {...props}  />:null
              
              }         


              { status === 1 ?  
              <ClienteLayoutPen {...props}  />:null
              
              }        

              

             
            
            
            </Fragment>
            
    

    );
}
 
export default Main;