import React, { useContext, useState, useEffect, Fragment } from 'react';
import ConoceTuCliente from '../../components/IconsAnimation/ConoceTuCliente';
import clienteAxios from '../../config/axios';
import { makeStyles } from '@material-ui/core/styles'  
import Modal from '@material-ui/core/Modal'


import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import Spinner from '../../components/spinners/Spinner'


// reactstrap components
import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col
  } from "reactstrap"; 



function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}));


const Kyc = ({setValue,setRecargarData,userStatus, guardarAppBar, guardarAppBarKyc, appbar, appbarkyc}) => { 

  const [ cargando, guardarCargando] = useState(false);

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
  <Fragment>
    Enviar documentos
    </Fragment> 



  




    const [ modalStyle ] =useState(getModalStyle);
    const [open, setOpen] =useState(false);

    const classes = useStyles();

    const handleOpen = () => {
        setOpen(true);
    } 

    const handleClose = () => {
        setOpen(false);;
    }




    const [mensaje, guardarMensaje] = useState({
        message:""
    })

    const [kyc, guardarKyc] = useState({
        
        file_id_front:'',
        file_id_reverse:'',
        file_selfie:'',

    });  

    const {file_id_front,
      file_id_reverse,file_selfie,
  
    
    } = kyc;  
 
    const [file, setFile] = useState(undefined);

    const consultarApiKyc = async () => {
        

          const config = {     
            headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
            
            mimeType: 'multipart/form-data',
        } 


        const formData = new FormData();
       

        //form.append("file_selfie", fileInput.files[0], "20151022_204039.jpg");
        
        // formData.append('file_id_front',file.files[0],file_id_front);
        // formData.append('file_id_reverse',file.files[0],file_id_reverse);
        // formData.append('file_selfie',file.files[0],file_selfie);


        //formData.append('file_id_front',file_id_front);
        //formData.append('file_id_reverse',file_id_reverse);
        //formData.append('file_selfie',file_selfie);

// metodo jquery
        formData.append('file_id_front',document.getElementById('file_id_front').files[0],file_id_front);
        formData.append('file_id_reverse',document.getElementById('file_id_reverse').files[0],file_id_reverse);
        formData.append('file_selfie',document.getElementById('file_selfie').files[0],file_selfie);

        
     
        try {
            const respuesta = await clienteAxios.post('/api/client/kyc',formData,config);

                  
                  guardarMensaje(respuesta.data.message);
                    handleOpen();
                    guardarCargando(false)
                    setTimeout(() => {
                        // guardarMensaje(null);
                        guardarKyc({
        
                            file_id_front:'',
                            file_id_reverse:'',
                            file_selfie:'',
                    
                        });
                    },6000);
            
        } catch (error) {
            
            
            guardarMensaje(error.response.data.message);
                    handleOpen();
                    
        }

      
      
      
          
      
      
      
    } 


  
    let formData = new FormData();




    const onChange = e => {

        // voy a colocar guardar usuario en el state

        guardarKyc({
            ...kyc,
            [e.target.name] : e.target.value
            
        }) 
        setFile(e.target.files[0])
    }




    const onSubmit = e => {
      guardarCargando(true)
        e.preventDefault();

        

             if( kyc.file_id_front.trim() === '' || 
                  kyc.file_selfie.trim() === '' || 
                  kyc.file_id_reverse.trim() === '' 
                 ) {
                     return
                } 

        //  if (name.trim() === '') {
        //      mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        //  }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        //  if (password.length < 6) {
        //      mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        //  }

        // // // los 2 password tienen que ser iguales
        //  if (password !== confirmar) {
        
        //    mostrarAlerta('Los password no son iguales','alerta-panther');
        //  }

        // luego lo vamos a pasar al action

        consultarApiKyc({
            file_id_front,
            file_id_reverse,
            file_selfie,
    
        }); 

     
      
        
    

    }




    


    return ( 

        <Col md="8" className="box-perfil-center-fix">
            <Card>
              <CardHeader>
                <h5 className="title display-4">Documentos de verificación</h5>
                <p className="text-success border-success border-bottom pb-4">Por favor cargue los documentos solicitados para verificar su cuenta.</p>
              </CardHeader>
              <CardBody>
                <Form onSubmit={onSubmit}>
                { mensaje ? <Modal
   
        className={classes.modal}
        open={open} 
        mensaje={mensaje}
                         
        onClose={handleClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 900,
        }}
      >
        <Fade in={open}
        timeout={154}
        onClose={handleClose}>
         <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
          <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
          
          <CardBody>
                              <Row className="d-flex justify-content-center">
                                      <Col sm="12" md="6"> 
                                          <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                      </Col>       
                              </Row>
                            </CardBody> 
                            <CardFooter>
             <div className="row">
                  
             <div  className="col-4">
                  </div>
                  <div  className="col-4">
                    <button className=" btn mt-0 btn-block btn-info" onClick={ () => (setValue(2),
                     guardarAppBar({
                      ...appbar,
                      primero:0,
                      segundo:0,
                      tercero:1,
                    }),
                  guardarAppBarKyc({
                    ...appbarkyc,
                    primero:0,
                    segundo:0,
                    tercero:1,
                  }),
                    userStatus(),
                    guardarMensaje(null)
                                                                                
                      )}>
                    Aceptar
                  </button>
                  </div>
                       
             <div  className="col-4">
                  </div>
             </div>
          </CardFooter>
                            
                       
          

        </div>

          </div>

        </div>
         </div>
        </Fade>
      </Modal> : null}

                        
                 
                  <Row className="mr-1">
                    <Col md="8">

                      <FormGroup>
                      <label>Foto de documento de identidad <span className="text-danger">*</span></label>
                      <div className="custom-file-panther">
                      
                      { kyc ? <p className="text-white text-center bg-info">  {kyc.file_id_front}</p> : null}
                      
                        <label className="panther-input-file" htmlFor="file_id_front">  
                        
                            <i className="tim-icons icon-badge p-2" />  Subir foto</label>

                            <input type="file"
                            id="file_id_front"
                            name="file_id_front"
                            onChange={onChange}
                            value={kyc.file_id_front} 
                            required
                            className="custom-file-input-panther" />
                      </div>
                      </FormGroup>

                      <FormGroup>
                        <label>Foto de comprobante de residencia* <span className="text-danger">*</span></label>
                        <div className="custom-file-panther">
                        { kyc ? <p className="text-white text-center bg-info">  {kyc.file_id_reverse}</p> : null}
                        
                        <label className="panther-input-file" htmlFor="file_id_reverse"> 
                         <i className="tim-icons icon-badge p-2" /> Subir comprobante</label>

                        <input type="file"
                           id="file_id_reverse"
                            name="file_id_reverse"
                            onChange={onChange}
                            value={kyc.file_id_reverse} 
                            required
                        className="custom-file-input-panther"/>
                      </div>
                      </FormGroup> 

                      <FormGroup>
                        <label>Tomar Selfie <span className="text-danger">*</span> </label>
                        
                        <div className="custom-file-panther">
                        
                        { kyc ? <p className="text-white text-center bg-info">  {kyc.file_selfie}</p> : null}
                        <label className="panther-input-file" htmlFor="file_selfie"> 
                         <i className="tim-icons icon-camera-18 p-2" /> Subir foto</label>

                        <input type="file"
                            id="file_selfie"
                            name="file_selfie"
                            onChange={onChange}
                            value={kyc.file_selfie} 
                            required
                        className="custom-file-input-panther"/>
                        </div>
                            
                      </FormGroup> 
                    </Col>
                   

                    <Col md="4">

                      <div className="kyc-container">
                        <ConoceTuCliente/>
                      </div>

                    </Col>
                 
                  </Row>
               
                  <Button className="btn-fill btn-block btn-radius-panther" color="primary" onSubmit={onSubmit}>
                  {guardar}
                </Button>
                 
                </Form>
              </CardBody>
              

            </Card>
          </Col> 





     );
}
 
export default Kyc;