import React, { useContext, useState, useEffect, Fragment } from 'react';
import AuthContext from '../../context/autenticacion/authContext'
import clienteAxios from '../../config/axios';

import LogoWhite from '../../assets/scss/black-dashboard-react/panther/logo-white.svg'; 
import LogoWhiteNoAnim from '../../assets/scss/black-dashboard-react/panther/logo-white-none-anim.svg'; 

import LogoBlack from '../../assets/scss/black-dashboard-react/panther/logo.svg'; 
import LogoBlackNoAnim from '../../assets/scss/black-dashboard-react/panther/logoNoAnim.svg'; 

import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner'



const WindowsDatos = ({setCompletar}) => {


    const authContext = useContext(AuthContext);
    const {jimf_theme, usuario} = authContext;


    const [ spin, setSpin ] = useState(false);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);

                    const Cerrar = () => {
                        setCompletar(false)
                      }

  

    const Loader = ( spin ) ? <LineSpinner/> :  
                                          
    <Fragment>
      Actualizar mi información
      </Fragment> 



const [profilechange, guardarProfileChange] = useState(usuario); 


    // const [profilechange, guardarProfileChange] = useState({
    //     id: '',
    //     name:'',
    //     email:'',
    //     client_type:'',
    //     identity:'',
    //     birthday_date:'',
    //     total_rent:'',
    //     status:'',
    //     profession_id:'',
    //     user_id:'',
    //     telephone:'',
    //     representative_name:'',
    //     representative_cpf:'',
    //     company_activity:'',
    //     created_at:'',
    //     updated_at:'',
    //     address_street:'',
    //     address_number:'',
    //     address_city:'',
    //     address_zipcode:'',
    //     address_zone:'',
    //     address_country:'',
    //     is_ofertante:'',
    //     comercial_name:'',
    //     referido:'',
    //     cpf_no_activo:'',
    //     cpf:'',
    //     first_name:'',
    //     last_name:'',
    //     middle_name:'',
   
    // }); 



    const {id,
        name,email,referrer,client_type,identity,
        birthday_date,total_rent,status,
        profession_id,user_id,telephone,
        representative_name,representative_cpf,
        company_activity,created_at,updated_at,
        address_street,address_number,address_city,
        address_zipcode,address_zone,address_country,is_ofertante,
        comercial_name,referido,cpf_no_activo,
        cpf,first_name,last_name,middle_name,direccion_cliente,
        sexo,situacionlaboral,ocupacion,lugartrabajo
        
          
          } = profilechange; 



          const consultarApiUpdate = async () => {


            try {
                const respuesta = await clienteAxios.post('/api/client/update',profilechange)
                    guardarMensaje(respuesta.data.message);
                        guardarMensajeOpen(true);
                        setSpin(false);
                        
        //   setTimeout(() => {
                //   setRecargarData(true);
                //   guardarCargando(false);
                //   guardarEnviarDepositoBtn(false);
                    // handleOpen(); 
        } catch (error) {
            guardarMensajeOpen(true)
            guardarMensaje(error.response.data.message);
            setSpin(false)
            
                    // handleOpen();
                  
        }
    
           
    
          
        //     guardarMensaje(null);
        //     guardarCargando(false);
        // },6000);
          
          
        } 


        const onChange = e => {
      
              // voy a colocar guardar usuario en el state
      
              guardarProfileChange({
                  ...profilechange,
                  [e.target.name] : e.target.value
      
              })
          }
      
          
    

    
    const onSubmit = e => {
        e.preventDefault();
        setSpin(true);

        consultarApiUpdate({
            name,
            comercial_name,
            email,
            
            identity,
            birthday_date,
            telephone,
            address_street,
            address_number,
            address_zipcode,
            address_city,
            sexo,
            situacionlaboral,
            ocupacion,
            lugartrabajo
        });


    } 





    return (  

        <>
        

        <div className="form-data-complete">

            <div className="back-btn-jimf">
                <button
                    onClick={Cerrar}
                >{'<'}</button>


            </div>




            <form  onSubmit={onSubmit} >

                <div className="tittle-box">

                <h2>Actualización de mis datos personales</h2>
                <h3>www<strong>.panther7.</strong>com</h3>


                </div>


                

                <div className="box-data-complete">



                <div className="input-size-jimf">
                    <label>Situación laboral </label>

                    <input
                    
                    type="text"
                    id="situacionlaboral"
                    name="situacionlaboral"
                    onChange={onChange}
                    value={profilechange.situacionlaboral}
                    placeholder={ !usuario.situacionlaboral ?
                        'Sin agregar' 
                         : usuario.situacionlaboral
                        } 
                    />
                </div>                


                <div className="input-size-jimf">
                    <label>Empresa donde labora</label>

                    <input
                    
                    type="text"
                    id="comercial_name"
                    name="comercial_name"
                    onChange={onChange}
                    value={profilechange.comercial_name}
                    placeholder={ !usuario.comercial_name ?
                        'Sin agregar' 
                         : usuario.comercial_name
                        } 
                    />
                </div>

                

                <div className="input-size-jimf">
                    <label>Ocupación laboral </label>

                    <input
                    
                    type="text"
                    id="ocupacion"
                    name="ocupacion"
                    onChange={onChange}
                    value={profilechange.ocupacion}
                    placeholder={ !usuario.ocupacion ?
                        'Sin agregar' 
                         : usuario.ocupacion
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>Nombre y apellido</label>

                    <input
                    
                    type="text"
                    id="name"
                    name="name"
                    onChange={onChange}
                    value={profilechange.name}
                    placeholder={ !usuario.name ?
                        'Sin agregar' 
                         : usuario.name
                        } 

                    />
                </div>

                
                <div className="input-size-jimf">
                    <label>Documento de identidad</label>

                    <input                    
                    type="text"
                    id="identity"
                    name="identity"
                    onChange={onChange}
                    value={profilechange.identity}
                    placeholder={ !usuario.identity ?
                        'Sin agregar' 
                         : usuario.identity
                        } 
                    />
                </div>

                
                <div className="input-size-jimf">
                    <label>Fecha de Nacimiento </label>

                    <input
                    type="date"
                    id="birthday_date"
                    name="birthday_date"
                    onChange={onChange}
                    value={profilechange.birthday_date}
                    placeholder={ !usuario.birthday_date ?
                        'Sin agregar' 
                         : usuario.birthday_date
                        } 
                    />
                </div>


                <div className="input-size-jimf">
                    <label>Sexo </label>

                    <input
                    
                    type="text"
                    id="sexo"
                    name="sexo"
                    onChange={onChange}
                    value={profilechange.sexo}
                    placeholder={ !usuario.sexo ?
                        'Sin agregar' 
                         : usuario.sexo
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>Estado Civil </label>

                    <input
                    
                    type="text"
                    id="estadocivil"
                    name="estadocivil"
                    onChange={onChange}
                    value={profilechange.estadocivil}
                    placeholder={ !usuario.estadocivil ?
                        'Sin agregar' 
                         : usuario.estadocivil
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>País </label>

                    <input
                    
                    type="text"
                    id="address_country"
                    name="address_country"
                    onChange={onChange}
                    value={profilechange.address_country}
                    placeholder={ !usuario.address_country ?
                        'Sin agregar' 
                         : usuario.address_country
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>Provincia </label>

                    <input
                    
                    type="text"
                    id="address_zone"
                    name="address_zone"
                    onChange={onChange}
                    value={profilechange.address_zone}
                    placeholder={ !usuario.address_zone ?
                        'Sin agregar' 
                         : usuario.address_zone
                        } 
                    />
                </div>


                <div className="input-size-jimf">
                    <label>Ciudad </label>

                    <input
                    
                    type="text"
                    id="address_city"
                    name="address_city"
                    onChange={onChange}
                    value={profilechange.address_city}
                    placeholder={ !usuario.address_citye ?
                        'Sin agregar' 
                         : usuario.address_city
                        } 
                    />
                </div>



                <div className="input-size-jimf">
                    <label>Dirección </label>

                    <input
                    
                    type="text"
                    id="address_street"
                    name="address_street"
                    onChange={onChange}
                    value={profilechange.address_street}
                    placeholder={ !usuario.address_street ?
                        'Sin agregar' 
                         : usuario.address_street
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>Dirección complementaria </label>

                    <input
                    
                    type="text"
                    id="address_number"
                    name="address_number"
                    onChange={onChange}
                    value={profilechange.address_number}
                    placeholder={ !usuario.address_number ?
                        'Sin agregar' 
                         : usuario.address_number
                        } 
                    />
                </div>

                <div className="input-size-jimf">
                    <label>Código Postal  </label>

                    <input
                    
                    type="text"
                    id="address_zipcode"
                    name="address_zipcode"
                    onChange={onChange}
                    value={profilechange.address_zipcode}
                    placeholder={ !usuario.address_zipcode ?
                        'Sin agregar' 
                         : usuario.address_zipcode
                        } 
                    />
                </div>



                </div>


                <div className="box-panther7">

                <button  type="submit" className={`submit-jimf ${spin === true ? 'padd-mad' :''}`}>
                                        {Loader}
                    </button>

                </div>



                    


               


                

            </form>





                <div className={`${jimf_theme === "white" ? 'ultradepositowhite':'ultradeposito' }`}>

                <div className={`${jimf_theme === "white" ? 'ultradeposito-son-white':'ultradeposito-son' }`}>
                </div>

                </div>

        </div>

                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                        /> 
                    

                </> : null

                }

</>



    );
}
 
export default WindowsDatos;