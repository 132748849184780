import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';

import CambiarPassword from '../Profile/CambiarPassword'
import AuthContext from '../../context/autenticacion/authContext'



import Modal from '@material-ui/core/Modal'
import ActualizarDatosModal from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles' 

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';


import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import SwipeableViews from 'react-swipeable-views';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';  
import PropTypes from 'prop-types';


/// aqui vamos a colocar el loader
import Spinner from '../../components/spinners/Spinner'


import {
    Button,
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    CardText,
    FormGroup,
    Form,
    Input,
    Row,
    Col,
    Container,

    
  } from "reactstrap"; 

  import styled from 'styled-components'


  /// aqui vamos a poner los estilos para el tap 
function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};


function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}



const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 100,
      width: '100%',
      backgroundColor: '#00ff9d',
    },
  },
})((props) => <Tabs {...props} TabIndicatorProps={{ children: <span /> }} />);








  //// responsive
const Div = styled.div `
padding: 0rem !important;
margin: 0.2rem 0rem 2.0rem 0rem !important;
display:flex !important;
flex-direction:row;
justify-content:center;





@media(min-width:992px) {
  display:none !important;
  justify-content:center;
}



`;

// pantalla grande
const DivModal = styled.div `

display:none !important;
flex-direction:row;





@media(min-width:992px) {
  display:block!important;
  
}



`; 



function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
  };
}

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
})); 




/// aqui van los estilos de los tabs
const StyledTab = withStyles((theme) => ({
  root: {
    textTransform: 'none',
    color: '#fff',
    fontWeight: theme.typography.fontWeightRegular,
    alignItems:'center',
    marginRight: theme.spacing(0),
    borderRadius:'30px',
    minWidth:'200px',
    transition:'all 500ms ease',
    padding:'0px 5px 0px 5px',
     margin:'0px 4px 0px 4px',
     alignItems:'center',
  
    '&:focus': {
      opacity: 0.8,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#005eff',
      borderRadius:'30px'
      
      
    },
    '&:hover': {
      opacity: 0.6,
      color: '#f5f5f5',
      border: 'none',
      boxShadow:'0px 0px 10px #3969f7',
      tramsition:'all 500ms ease',
      background:'#000',
      borderRadius:'30px'
    },

  }
  
  ,
}))((props) => <Tab disableRipple {...props} />);
///// aqui terminan los estilos del tap





   


  
const ActualizarPerfil = ({usuario,setValue,listDatos, guardarAppBar, guardarAppBarKyc, appbar, appbarkyc }) => { 


  
  const theme = useTheme();
  const [valuedostres, setValueDosTres] = React.useState(0);
  
  
  
    const handleChange = (event, newValue) => {
      setValueDosTres(newValue);
    };
  
    const handleChangeIndexTres = (index) => {
      setValueDosTres(index);
    }; 



 const [profilechange, guardarProfileChange] = useState(usuario); 
 
 const [profilechangeahora, guardarProfileChangeAhora] = useState({
     id: '',
     name:'',
     email:'',
     client_type:'',
     identity:'',
     birthday_date:'',
     total_rent:'',
     status:'',
     profession_id:'',
     user_id:'',
     telephone:'',
     representative_name:'',
     representative_cpf:'',
     company_activity:'',
     created_at:'',
     updated_at:'',
     address_street:'',
     address_number:'',
     address_city:'',
     address_zipcode:'',
     address_zone:'',
     address_country:'',
     is_ofertante:'',
     comercial_name:'',
     referido:'',
     cpf_no_activo:'',
     cpf:'',
     first_name:'',
     last_name:'',
     middle_name:'',

 }); 




///// aqui vamos a definir el modal que va a cambiar la configuracion del usuario 
const [openaction, setOpenAction] =useState(false);

const abrirAction = () => {
    setOpenAction(true);
} 

const cerrarAction = () => {
    setOpenAction(false);;
}



    
  


  const [ cargando, guardarCargando] = useState(false);
  

  const guardar = ( cargando ) ? <Spinner/> :  
                                          
                                          <Fragment>
                                            Actualizar
                                            </Fragment> 





    
    const [ modalStyle ] =useState(getModalStyle);
        const [open, setOpen] =useState(false);
    
        const classes = useStyles();
    
        const handleOpen = () => {
            setOpen(true);
        } 
    
        const handleClose = () => {
            setOpen(false);;
        }
    

    


    const authContext = useContext(AuthContext);
    const { actualizarDatos } = authContext; 

    

    const [mensaje, guardarMensaje] = useState('')




    

    
   
      
      const consultarApiUpdate = async () => {
        const {id,
        name,email,referrer,client_type,identity,
        birthday_date,total_rent,status,
        profession_id,user_id,telephone,
        representative_name,representative_cpf,
        company_activity,created_at,updated_at,
        address_street,address_number,address_city,
        address_zipcode,address_zone,address_country,is_ofertante,
        comercial_name,referido,cpf_no_activo,
        cpf,first_name,last_name,middle_name,direccion_cliente
        
          
          } = profilechange; 
    

      const respuesta = await clienteAxios.post('/api/client/update',{id,
        name,email,referrer,client_type,identity,
        birthday_date,total_rent,status,
        profession_id,user_id,telephone,
        representative_name,representative_cpf,
        company_activity,created_at,updated_at,
        address_street,address_number,address_city,
        address_zipcode,address_zone,address_country,is_ofertante,
        comercial_name,referido,cpf_no_activo,
        cpf,first_name,last_name,middle_name,direccion_cliente
        
          
          })
      
      
          
      guardarMensaje(respuesta.data.message);
      handleOpen();
    //   setTimeout(() => {
    //     guardarMensaje(null);
    //     guardarCargando(false);
    // },6000);
      
      
    } 

      /// aqui vamos a crear el manejador de eventos secundario

 

    

    const onChange = e => {

      guardarProfileChangeAhora({
        ...profilechangeahora,
        [e.target.name] : e.target.value

    })

        // voy a colocar guardar usuario en el state

        guardarProfileChange({
            ...profilechange,
            [e.target.name] : e.target.value

        })
    }

    

    const onSubmit = e => {
        e.preventDefault();
        guardarCargando(true);
     

        //     if( name.trim() === '' || 
        //         email.trim() === '' || 
        //         password.trim() === '' || 
        //         confirmar.trim() === ''  ) {
        //             {mensaje}
        //         } 

        // if (name.trim() === '') {
        //     mostrarAlerta('Coloca tu nombre', 'alerta-panther');
        // }
        
        // // vamos a poner que el password minimo debe tener 6 caracteres
        // if (password.length < 6) {
        //     mostrarAlerta('El Password debe ser de al menos 6 caracteres', 'alerta-panther');
        // }

        // // los 2 password tienen que ser iguales
        // if (password !== confirmar) {
        //     mostrarAlerta('Los password no son iguales','alerta-panther');
        // }

        // luego lo vamos a pasar al action

        consultarApiUpdate({
            name,
            comercial_name,
            email,
            
            identity,
            birthday_date,
            telephone,
            address_street,
            address_number,
            address_zipcode,
            address_city,
        });


        
        


    } 






    return (  
        ///////////

        <Fragment> 
          
 

        <Row>
            {/* <Col md="2" className="box-perfil-center-fix">
            <div className="scrolldown-icon"> 
        <h5> <span>Scroll</span> para completar tu perfil </h5>
        <ScrollDown/>

        </div>
        </Col>  */}





            <Col md="6" className="box-perfil-center-fix animation-op">
                <Card>
                    <CardHeader>
                        <h5 className="title display-4">Mis datos personales</h5>
                        <p >Complete su información personal con datos actualizados y fidedignos.</p>
                        <p className="class-black "> Los campos con asteriscos son obligatorios <span className="alerta-texto-profile-green">* </span></p>
                        
                        
                    </CardHeader>
                    <CardBody>
                        
                        
                        
                { mensaje ? <Modal
   
        className={classes.modal}
        open={open} 
              
        mensaje={mensaje}
        listDatos={listDatos}            
        

        guardarAppBar={guardarAppBar} 
        guardarAppBarKyc={guardarAppBarKyc} 
        appbar={appbar} 
        appbarkyc={appbarkyc}

        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 900,
        }}
      >
        <Fade in={open}
        timeout={154}
        onClose={handleClose}>
         <div className="container">
        <div className="row">
          <div className="col-12 d-flex justify-content-center">
          <div  className={`  card ${classes.paper} Modal-inversion modal-power-up`}>
          
          <CardBody >
                              <Row className="d-flex justify-content-center">
                                      <Col sm="12" md="6"> 
                                          <p className="text-center display-4 title font-light-weight-modal"> {mensaje}</p>
                                      </Col>       
                              </Row>
                            </CardBody> 
                            <CardFooter>
             <div className="row">
                  
             <div  className="col-4">
                  </div>
                  <div  className="col-4">
                  <button className=" btn mt-0 btn-block btn-info"  onClick={() => {
                                                                                    setValue(1),
                                                                                      guardarAppBar({
                                                                                        ...appbar,
                                                                                        primero:0,
                                                                                        segundo:1
                                                                                      }),
                                                                                    guardarAppBarKyc({
                                                                                      ...appbarkyc,
                                                                                      primero:0,
                                                                                      segundo:1
                                                                                    }),
                                                                                    guardarMensaje(null),
                                                                                    guardarCargando(false),
                                                                                    handleClose(),
                                                                                    listDatos(),
                                                                                    cerrarAction()
}}>
                      
                      Aceptar
                    </button>
                   
                  </div>
                       
             <div  className="col-4">
                  </div>
             </div>
          </CardFooter>
                            
                       
          

        </div>

          </div>

        </div>
         </div>
        </Fade>
      </Modal> : null}


                        <Row className="profile-fix-input ">
                        
                            <Col md="8">
                            
                                <label>Nombre</label>
                                <p>{usuario.name}</p>
                                
                            
                            
                            </Col> 

                            <Col md="4">
                            
                                <label>TU CÒDIGO DE REFERIDO </label>
                                <p> {usuario.referrer} </p>
                                
                            
                            
                            </Col>


                        </Row> 
                        
                        <span className="text-fix-cuento animate-raya-03">Haga Click en completar informacion</span>
                        <Button className="btn-fill btn-block  btn-primario btn-panther-power btn-radius-panther mt-2" color="primary"
                              onClick={ () => {
                                abrirAction();
                                
                              }


                              }
                        
                          >
                        Completar información
                      </Button> 


                      <Row >
                        
                            <Col md="12">
                            
                                
                                <p>Bienvenido a tu plataforma favorita de inversiones,
                                  porfavor completa los siguientes campos con tus datos 
                                  personales para podre disfrutar de Panther7
                                </p>
                                
                            
                            
                            </Col> 

                            

                        </Row>
                                          
                        
                        
                        
                        
                    </CardBody>
                    
                    </Card>
            </Col>


        <Col md="6" className="animation-op">
            <Card className="card-user ">
              <CardBody>
                <CardText />
                <div className="author">
                  <div className="block block-one" />
                  <div className="block block-two" />
                  <div className="block block-three" />
                  <div className="block block-four" />
                  <a href="#pablo" onClick={e => e.preventDefault()}>
                    <img
                      alt="..."
                      className="avatar"s
                      src={require("assets/img/emilyz.jpg")}
                    />
                    <h5 className="title animation-cuentas-up animation-delay-1">Perfil de Usuario</h5>
                  </a>
                  {usuario ? <p className="description animation-cuentas-up animation-delay-2">BIENVENIDO {usuario.name} </p> : null}
                </div>
                <div className="card-description text-center animation-cuentas-up animation-delay-3">
                 Panther 7 - Plataforma de Crecimiento Financiero
                </div>
              </CardBody>
              {/* <CardFooter>
                <div className="button-container">
                  <Button className="btn-icon btn-round" color="facebook">
                    <i className="fab fa-facebook" />
                  </Button>
                  <Button className="btn-icon btn-round" color="twitter">
                    <i className="fab fa-twitter" />
                  </Button>
                  <Button className="btn-icon btn-round" color="google">
                    <i className="fab fa-google-plus" />
                  </Button>
                </div>
              </CardFooter> */}
              <h3 className="title display-4 text-center mb-0 align-items-center d-flex justify-content-center">Seguridad <i class="tim-icons icon-lock-circle"></i></h3>
              <CambiarPassword className="col-12 " />
            </Card> 
            
        
        </Col>
        </Row> 


        <ActualizarDatosModal
   
        className={`help-scroll ${classes.modal}`}
        
        open={openaction} 
        
                         
        
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 900,
        }}
      >
        <Fade in={openaction}
        timeout={154}
        onClose={cerrarAction}> 

        
        
         <div className="container">
           
           
        <div className="row">
          
          <div className="col-12 d-flex justify-content-center">
            
          <div  className={`  card  Modal-inversion-profile modal-power-up-profile`}> 
          
          <Form onSubmit={onSubmit}> 
          
                      <CardHeader className="text-center">
                      <h2 className="animate-raya-03 mb-2">Actualización de datos personales</h2>
                      <p>Complete su información personal con datos actualizados y fidedignos.</p>
                      
                        
                        
                        </CardHeader> 
                        <div className="container-fluid row d-flex seudo-hermano">
                            
                     
                            <div  className="col-4">
                            <div className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario"  onClick={() => (  
                                                                                              cerrarAction(),
                                                                                              guardarMensaje(null),
                                                                                              guardarCargando(false),
                                                                                              guardarProfileChangeAhora({ 
                                                                                              id: '',
                                                                                              name:'',
                                                                                              email:'',
                                                                                              c:'',
                                                                                              client_type:'',
                                                                                              identity:'',
                                                                                              birthday_date:'',
                                                                                              total_rent:'',
                                                                                              status:'',
                                                                                              profession_id:'',
                                                                                              user_id:'',
                                                                                              telephone:'',
                                                                                              representative_name:'',
                                                                                              representative_cpf:'',
                                                                                              company_activity:'',
                                                                                              created_at:'',
                                                                                              updated_at:'',
                                                                                              address_street:'',
                                                                                              address_number:'',
                                                                                              address_city:'',
                                                                                              address_zipcode:'',
                                                                                              address_zone:'',
                                                                                              address_country:'',
                                                                                              is_ofertante:'',
                                                                                              comercial_name:'',
                                                                                              referido:'',
                                                                                              cpf_no_activo:'',
                                                                                              cpf:'',
                                                                                              first_name:'',
                                                                                              last_name:'',
                                                                                              middle_name:'',
                                                                                            }),
                                                                                            guardarProfileChange(usuario)

                              )}>
                                
                                
                                                          <div className="cancel-btn-panther text-fix-btn"> 
                                                              <i className="tim-icons icon-simple-remove"/>
                                                          </div>
                                                      
                              </div>
                            
                            </div>
                                
                     
                      </div>  
                                      


                  
                    <CardBody> 

                    <Div >
                                    <div className="responsive-width-fix">
                                        
                                                                                                {/* la cuenta de los pasos */}
                                        {/* <Row className="my-2">
                                          <Col md="12" className="appbar-box-panther-dos">
                                        <AppBar position="static" color="default" className="app-panther-bar-dos">
                                          
                                        <StyledTabs value={valuedostres} 
                                                  onChange={handleChange} 
                                                  aria-label="styled tabs example"
                                                  >

                                            
                                            <StyledTab  label="Paso 1" {...a11yProps(0)} />
                                            <StyledTab label="Paso 2" {...a11yProps(1)} />
                                            <StyledTab label="Finalizar" {...a11yProps(2)} />
                                            
                                        </StyledTabs>


                                        </AppBar> 
                                        </Col>
                                      </Row>   */}

                                        <SwipeableViews
                                          axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
                                          index={valuedostres}
                                          onChangeIndexTres={handleChangeIndexTres}
                                         
                                          
                                        > 

                                      <TabPanel value={valuedostres} index={0} dir={theme.direction}>

                                                                    
                                                      <Row > 

                                                                                      
                                                              <Col md="12">
                                                              <FormGroup>
                                                              
                                                                  <label>Empresa donde labora</label> 

                                                                  { !usuario.comercial_name ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}>Aún no tiene información de la empresa </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                                  { profilechangeahora.comercial_name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu información laboral </p> : null} 
                                                                
                                                                  <Input
                                                                  className="placeholder-complete"
                                                                    
                                                                    type="text"
                                                                    id="comercial_name"
                                                                    name="comercial_name"
                                                                    onChange={onChange}
                                                                    value={profilechange.comercial_name}
                                                                    placeholder={usuario.comercial_name}
                                                                
                                                                />
                                                              </FormGroup>

                                                              </Col> 

                                                              <Col md="6">
                                                              <FormGroup >
                                                                  <label>SU CÓDIGO DE REFERIDO   </label> 

                                                                  { usuario.referrer ? <p className="alerta-texto-profile-green">Este es tu código unico de referido </p> : null} 
                                                                  <Input
                                                                  
                                                                  
                                                                  type="referrer"
                                                                  id="referrer"
                                                                  name="referrer"
                                                                  onChange={onChange}
                                                                  value={usuario.referrer}
                                                                  disabled
                                                                  />
                                                              </FormGroup>

                                                              </Col>


                                                              </Row>

                                                              <Row>
                                                      <Col md="7">
                                                      <FormGroup>
                                                      <label className={` ${profilechange.identity ? '' : ''} `}> Nombre y apellido { usuario.name ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                      { profilechangeahora.name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu nombre de usuario </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 

                                                      <Input

                                                      className="placeholder-complete"
                                                      type="text"
                                                      id="name"
                                                      name="name"
                                                      onChange={onChange}
                                                      value={profilechange.name}
                                                      placeholder={usuario.name}
                                                      required

                                                      />
                                                      </FormGroup>
                                                      </Col>


                                                      <Col  md="5">
                                                      <FormGroup>
                                                      <label className={` ${profilechangeahora.name  ? '' : ''} `}>Documento de identidad { usuario.identity ? <span className="alerta-texto-profile-green">* </span> : null}</label>
                                                      { profilechangeahora.identity ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando la informacion de tu ID </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.identity ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                      <Input

                                                      className="placeholder-complete"
                                                      type="text"
                                                      id="identity"
                                                      name="identity"
                                                      onChange={onChange}
                                                      value={profilechange.identity}
                                                      placeholder={usuario.identity}
                                                      required

                                                      />
                                                      </FormGroup>
                                                      </Col>
                                                      </Row>
                                                      </TabPanel> 
                                          <TabPanel value={valuedostres} index={1} dir={theme.direction} >

                                          <Row> 

                                                {usuario.birthday_date ? 
                                                <Col className="pr-md-1" md="6">
                                                  <FormGroup>
                                                      <label>Fecha de Nacimiento { usuario.birthday_date ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                                                      { usuario.birthday_date ? <p className="alerta-texto-profile-green">Fecha de nacimiento </p> : null} 
                                                      <Input
                                                      
                                                      
                                                      className="placeholder-complete"
                                                      type="text"
                                                      id="birthday_date"
                                                      name="birthday_date"
                                                      onChange={onChange}
                                                      value={profilechange.birthday_date}
                                                      placeholder={usuario.birthday_date}
                                                      required
                                                      
                                                      disabled
                                                      
                                                      />
                                                  </FormGroup>
                                                  </Col>:  
                                                  
                                                  <Col className="pr-md-1" md="6">
                                                  <FormGroup>
                                                      <label>Fecha de Nacimiento</label>
                                                      { !usuario.birthday_date ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Aún no ha agregado su fecha de nacimiento </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Agregada correctamente </p>} 
                                                      { profilechangeahora.birthday_date ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando su fecha de nacimiento </p> : null} 
                                                      <Input
                                                      
                                                      
                                                      className="placeholder-complete"
                                                      type="date"
                                                      id="birthday_date"
                                                      name="birthday_date"
                                                      onChange={onChange}
                                                      value={profilechange.birthday_date}
                                                      placeholder={usuario.birthday_date}
                                                      required
                                                      
                                                      
                                                      />
                                                  </FormGroup>
                                                  </Col>



                                                }


                                                
                                                  <Col  md="3">
                                                  <FormGroup>
                                                      <label htmlFor="exampleInputEmail1">
                                                      Correo electrónico  { usuario.email ? <span className="alerta-texto-profile-green">* </span> : null}  
                                                      </label> 

                                                      { usuario.email ? <p className="alerta-texto-profile-green">Correo electrónico </p> : null} 
                                                      <Input placeholder="TuCorreo@email.com" type="email" 
                                                      id="email"
                                                      name="email"
                                                      onChange={onChange}
                                                      value={usuario.email}
                                                      
                                                      required
                                                      disabled
                                                      
                                                      />
                                                  </FormGroup>
                                                  </Col>
                                                  <Col  md="3">
                                                  <FormGroup>
                                                      <label htmlFor="exampleInputEmail1">
                                                      Número de Celular  { usuario.telephone ? <span className="alerta-texto-profile-green">* </span> : null} 
                                                      </label> 

                                                      { !usuario.telephone ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.telephone ? 'd-none' : 'd-block' }`}> Aún sin número </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.telephone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                      { profilechangeahora.telephone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">actualizando # telefono </p> : null} 

                                                      <Input  
                                                          type="text" 
                                                          className="placeholder-complete"
                                                          id="telephone"
                                                          name="telephone"
                                                          onChange={onChange}
                                                          value={profilechange.telephone}
                                                          placeholder={usuario.telephone}
                                                          required
                                                          
                                                      />
                                                  </FormGroup>
                                                  </Col>
                                                </Row>
                                                <Row>
                                                  <Col md="12">
                                                  <FormGroup>
                                                      <label>Dirección { usuario.address_street ? <span className="alerta-texto-profile-green">* </span> : null}  
                                                      </label> 

                                                      { !usuario.address_street ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_street ? 'd-none' : 'd-block' }`}> Aún no ha agregado su dirección </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_street ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                                                      { profilechangeahora.address_street ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando su dirección principal  </p> : null} 

                                                      <Input
                                                      
                                                      className="placeholder-complete"
                                                      type="text"
                                                      id="address_street"
                                                          name="address_street"
                                                          onChange={onChange}
                                                          value={profilechange.address_street}
                                                          placeholder={usuario.address_street}
                                                          required
                                                          
                                                      />
                                                  </FormGroup>
                                                  </Col>
                                                </Row> 

                                            
                                        
                                          </TabPanel>

                                         
                                          <TabPanel value={valuedostres} index={2} dir={theme.direction}>

                                          <Row>
                        <Col md="8">
                        <FormGroup>
                            <label>Información complementaria de la dirección 
                            </label> 

                            { !usuario.address_number ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Aún no ha agregado su dirección complementaria </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_number ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando dirección complementaria  </p> : null} 
                            <Input
                            className="placeholder-complete"
                            type="text"
                            id="address_number"
                                name="address_number"
                                onChange={onChange}
                                value={profilechange.address_number}
                                placeholder={usuario.address_number}
                                
                                
                            />
                        </FormGroup>
                        </Col> 
                        <Col md="4">
                        <FormGroup>
                            <label>Código Postal 

                            </label> 
                            
                            { !usuario.address_zipcode ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Aún no ha agregado su código postal </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_zipcode ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando el código postal  </p> : null} 

                            <Input
                            
                            placeholder="Codigo Postal"
                            type="text"
                            id="address_zipcode"
                            className="placeholder-complete"
                                name="address_zipcode"
                                onChange={onChange}
                                value={profilechange.address_zipcode}
                                placeholder={usuario.address_zipcode}
                                
                                
                            />
                        </FormGroup>
                        </Col>
                        
                    </Row>
                    <Row> 
                    <Col md="4">
                        <FormGroup>
                            <label>País  { usuario.address_country ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 
                            { !usuario.address_country ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Aún no ha agregado su país </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_country ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando país  </p> : null} 
                            <Input
                            
                            placeholder="Country"
                            type="text"
                            id="address_country"
                            name="address_country"
                            className="placeholder-complete"
                            onChange={onChange}
                            value={profilechange.address_country}
                            placeholder={usuario.address_country}
                            required
                            
                            />
                        </FormGroup>
                        </Col>
                        
                        <Col className="pl-md-1" md="4">
                        <FormGroup>
                            <label>Provincia   { usuario.address_zone ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 

                            { !usuario.address_zone ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Aún no ha agregado su provincia </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_zone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando provincia  </p> : null} 
                            <Input placeholder="PROVINCIA" type="text"
                            
                            id="address_zone"
                            name="address_zone"
                            className="placeholder-complete"
                            onChange={onChange}
                            value={profilechange.address_zone}
                            placeholder={usuario.address_zone}
                            required
                            
                            
                            />
                        </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                        <FormGroup>
                            <label>Ciudad   { usuario.address_city ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 

                            { !usuario.address_city ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Aún no ha agregado su ciudad </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_city ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando ciudad  </p> : null} 
                            <Input
                            defaultValue="Ciudad de Panamá"
                            className="placeholder-complete"
                            
                            type="text"
                            id="address_city"
                            placeholder={usuario.address_city}
                                name="address_city"
                                onChange={onChange}
                                value={profilechange.address_city}
                                required
                                
                            />
                        </FormGroup>
                        </Col>

                        
                    
                    </Row>  

                     
                                          </TabPanel>
                                    
                                        </SwipeableViews>


                                        {/* <Row className="my-2">
                                          <Col md="12" className="appbar-box-panther-dos">
                                        <AppBar position="static" color="default" className="app-panther-bar-dos">
                                          
                                        <StyledTabs value={valuedostres} 
                                                  onChange={handleChange} 
                                                  aria-label="styled tabs example"
                                                  >

                                            
                                         
                                            <StyledTab label="Finalizar" {...a11yProps(2)} />
                                            
                                        </StyledTabs>


                                        </AppBar> 
                                        </Col>
                                      </Row>   */}


                                          
                                          <Row className="justify-content-center modal-botones-fix">
                                          <div  className="col-5">

                                            { valuedostres === 1 || valuedostres === 2 ? 
                                             <p className=" btn mt-0 btn-block btn-info btn-panther-power-sm btn-primario" onClick={ () => {
                                                                      
                                              setValueDosTres(valuedostres - 1)
                                                                      
                                                                      
                                                                      
                                                                        }
                                                                        }>
                                                
                                                Atrás
                                              
                                              </p> :  

                                              <p className=" btn mt-0 btn-block btn-info btn-panther-power-sm btn-primario" disabled >
                                                  
                                                  Atrás
                                                
                                                </p> 

                                            }
                                           
                                            
                                            </div>

                                        <div  className="col-5">

                                        { valuedostres === 0 || valuedostres === 1   ? 
                                            <p className=" btn mt-0 btn-block btn-info btn-panther-power-sm btn-primario"
                                            onClick={ () => {
                                                                      
                                              setValueDosTres(valuedostres + 1)
                                              
                                              
                                              
                                                }
                                                }
                                            
                                            
                                            >
                                                
                                                Siguiente
                                              
                                              </p> : 

                                                <p className=" btn mt-0 btn-block btn-info btn-panther-power-sm btn-primario" disabled >
                                                                                                  
                                                  Siguiente

                                                </p>  }
                                            
                                            </div>
                                                          


                        

                      
                                          </Row>

                                      </div>      

                                    

                                             


                                  </Div>

                      <DivModal>

                    

                    
                    <Row > 

                        
                        <Col md="6">
                        <FormGroup>
                         
                            <label>Empresa donde labora</label> 

                            { !usuario.comercial_name ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}>Aún no tiene información de la empresa </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.comercial_name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.comercial_name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu información laboral </p> : null} 
                           
                            <Input
                                className="placeholder-complete"
                                
                                type="text"
                                id="comercial_name"
                                name="comercial_name"
                                onChange={onChange}
                                value={profilechange.comercial_name}
                                placeholder={usuario.comercial_name}
                            
                            />
                        </FormGroup>
                        
                        </Col> 

                        <Col md="6">
                        <FormGroup >
                            <label>SU CÓDIGO DE REFERIDO { usuario.referrer ? <span className="alerta-texto-profile-green">* </span> : null}  </label> 

                            { usuario.referrer ? <p className="alerta-texto-profile-green">Este es tu código unico de referido </p> : null} 
                            <Input
                            
                            
                            type="referrer"
                            id="referrer"
                            name="referrer"
                            onChange={onChange}
                            value={usuario.referrer}
                            disabled
                            />
                        </FormGroup>
                        
                        </Col>


                    </Row>
                    
                    <Row>
                        <Col md="7">
                        <FormGroup>
                            <label className={` ${profilechange.identity ? '' : ''} `}> Nombre y apellido { usuario.name ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                            { profilechangeahora.name ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando tu nombre de usuario </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.name ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
  
                            <Input
                            
                                className="placeholder-complete"
                                type="text"
                                id="name"
                                name="name"
                                onChange={onChange}
                                value={profilechange.name}
                                placeholder={usuario.name}
                                required
                                
                            />
                        </FormGroup>
                        </Col>
                    

                        <Col  md="5">
                        <FormGroup>
                        <label className={` ${profilechangeahora.name  ? '' : ''} `}>Documento de identidad { usuario.identity ? <span className="alerta-texto-profile-green">* </span> : null}</label>
                            { profilechangeahora.identity ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando la informacion de tu ID </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.identity ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            <Input
                            
                            className="placeholder-complete"
                            type="text"
                            id="identity"
                                name="identity"
                                onChange={onChange}
                                value={profilechange.identity}
                                placeholder={usuario.identity}
                                required
                                
                            />
                        </FormGroup>
                        </Col>
                    </Row>



                    <Row> 

                      {usuario.birthday_date ? 
                       <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Fecha de Nacimiento { usuario.birthday_date ? <span className="alerta-texto-profile-green">* </span> : null} </label>
                            { usuario.birthday_date ? <p className="alerta-texto-profile-green">Fecha de nacimiento </p> : null} 
                            <Input
                            
                            
                            className="placeholder-complete"
                            type="text"
                            id="birthday_date"
                            name="birthday_date"
                            onChange={onChange}
                            value={profilechange.birthday_date}
                            placeholder={usuario.birthday_date}
                            required
                            
                            disabled
                            
                            />
                        </FormGroup>
                        </Col>:  
                        
                        <Col className="pr-md-1" md="6">
                        <FormGroup>
                            <label>Fecha de Nacimiento</label>
                            { !usuario.birthday_date ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Aún no ha agregado su fecha de nacimiento </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.birthday_date ? 'd-none' : 'd-block' }`}> Agregada correctamente </p>} 
                            { profilechangeahora.birthday_date ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Estás actualizando su fecha de nacimiento </p> : null} 
                            <Input
                            
                            
                            className="placeholder-complete"
                            type="date"
                            id="birthday_date"
                            name="birthday_date"
                            onChange={onChange}
                            value={profilechange.birthday_date}
                            placeholder={usuario.birthday_date}
                            required
                            
                            
                            />
                        </FormGroup>
                        </Col>
                    


                      }


                       
                        <Col  md="3">
                        <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                            Correo electrónico  { usuario.email ? <span className="alerta-texto-profile-green">* </span> : null}  
                            </label> 

                            { usuario.email ? <p className="alerta-texto-profile-green">Correo electrónico </p> : null} 
                            <Input placeholder="TuCorreo@email.com" type="email" 
                            id="email"
                            name="email"
                            onChange={onChange}
                            value={usuario.email}
                            
                            required
                            disabled
                            
                            />
                        </FormGroup>
                        </Col>
                        <Col  md="3">
                        <FormGroup>
                            <label htmlFor="exampleInputEmail1">
                            Número de Celular  { usuario.telephone ? <span className="alerta-texto-profile-green">* </span> : null} 
                            </label> 

                            { !usuario.telephone ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.telephone ? 'd-none' : 'd-block' }`}> Aún sin número </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.telephone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.telephone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">actualizando # telefono </p> : null} 

                            <Input  
                                type="text" 
                                className="placeholder-complete"
                                id="telephone"
                                name="telephone"
                                onChange={onChange}
                                value={profilechange.telephone}
                                placeholder={usuario.telephone}
                                required
                                
                            />
                        </FormGroup>
                        </Col>
                    </Row>
                    <Row>
                        <Col md="12">
                        <FormGroup>
                            <label>Dirección { usuario.address_street ? <span className="alerta-texto-profile-green">* </span> : null}  
                            </label> 

                            { !usuario.address_street ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_street ? 'd-none' : 'd-block' }`}> Aún no ha agregado su dirección </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_street ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_street ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando su dirección principal  </p> : null} 

                            <Input
                            
                            className="placeholder-complete"
                            type="text"
                            id="address_street"
                                name="address_street"
                                onChange={onChange}
                                value={profilechange.address_street}
                                placeholder={usuario.address_street}
                                required
                                
                            />
                        </FormGroup>
                        </Col>
                    </Row> 





                    <Row>
                        <Col md="8">
                        <FormGroup>
                            <label>Información complementaria de la dirección 
                            </label> 

                            { !usuario.address_number ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Aún no ha agregado su dirección complementaria </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_number ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_number ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando dirección complementaria  </p> : null} 
                            <Input
                            className="placeholder-complete"
                            type="text"
                            id="address_number"
                                name="address_number"
                                onChange={onChange}
                                value={profilechange.address_number}
                                placeholder={usuario.address_number}
                                
                                
                            />
                        </FormGroup>
                        </Col> 
                        <Col md="4">
                        <FormGroup>
                            <label>Código Postal 

                            </label> 
                            
                            { !usuario.address_zipcode ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Aún no ha agregado su código postal </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zipcode ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_zipcode ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando el código postal  </p> : null} 

                            <Input
                            
                            placeholder="Codigo Postal"
                            type="text"
                            id="address_zipcode"
                            className="placeholder-complete"
                                name="address_zipcode"
                                onChange={onChange}
                                value={profilechange.address_zipcode}
                                placeholder={usuario.address_zipcode}
                                
                                
                            />
                        </FormGroup>
                        </Col>
                        
                    </Row>
                    <Row> 
                    <Col md="4">
                        <FormGroup>
                            <label>País  { usuario.address_country ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 


                            { !usuario.address_country ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Aún no ha agregado su país </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_country ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_country ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando país  </p> : null} 
                            <Input
                            defaultValue="Panamá"
                            placeholder="Country"
                            type="text"
                            id="address_country"
                            name="address_country"
                            className="placeholder-complete"
                            onChange={onChange}
                            value={profilechange.address_country}
                            placeholder={usuario.address_country}
                            required
                            
                            />
                        </FormGroup>
                        </Col>
                        
                        <Col className="pl-md-1" md="4">
                        <FormGroup>
                            <label>Provincia   { usuario.address_zone ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 

                            { !usuario.address_zone ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Aún no ha agregado su provincia </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_zone ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_zone ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando provincia  </p> : null} 
                            <Input placeholder="PROVINCIA" type="text"
                            
                            id="address_zone"
                            name="address_zone"
                            className="placeholder-complete"
                            onChange={onChange}
                            value={profilechange.address_zone}
                            placeholder={usuario.address_zone}
                            required
                            
                            
                            />
                        </FormGroup>
                        </Col>

                        <Col className="pr-md-1" md="4">
                        <FormGroup>
                            <label>Ciudad   { usuario.address_city ? <span className="alerta-texto-profile-green">* </span> : null}  

                            </label> 

                            { !usuario.address_city ? <p className={` alerta-texto-profile animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Aún no ha agregado su ciudad </p> : <p className={` alerta-texto-profile-blu animation-icon-in-cuatro ${profilechangeahora.address_city ? 'd-none' : 'd-block' }`}> Agregado correctamente </p>} 
                            { profilechangeahora.address_city ? <p className="alerta-texto-profile-green  animation-icon-in-cuatro">Actualizando ciudad  </p> : null} 
                            <Input
                            defaultValue="Ciudad de Panamá"
                            className="placeholder-complete"
                            
                            type="text"
                            id="address_city"
                            placeholder={usuario.address_city}
                                name="address_city"
                                onChange={onChange}
                                value={profilechange.address_city}
                                required
                                
                            />
                        </FormGroup>
                        </Col>

                        
                    
                    </Row>  

                     

                      </DivModal>

                    </CardBody> 
                    
                    <CardFooter>


                      <div className={`row justify-content-center d-flex
                                                          ${profilechange.address_city === "" || profilechange.address_zone === "" || profilechange.address_country === "" || profilechange.name === "" || profilechange.identity === "" || profilechange.address_country === "" || profilechange.birthday_date === "" || profilechange.telephone === "" || profilechange.address_street === "" || profilechange.address_city === "" || usuario.address_zone === ""  || usuario.address_country === "" ? 'd-block' : 'd-block' } 
                                                          `}>  

                        <div  className="col-4">
                            <button className=" btn mt-0 btn-block btn-info btn-panther-power btn-primario" type="submit">
                                
                                {guardar}
                              
                              </button>
                            
                            </div>
                                                          
                        

                      </div>

                      
                    </CardFooter> 

                    </Form>
                            
                       
                    

                  </div>

                    </div>

                  </div>
                  </div>
        </Fade>
      </ActualizarDatosModal>

    </Fragment>
    );
}
 
export default ActualizarPerfil;