import { createContext } from "react";

// export const backgroundColorsdos = {
//   primary: "primary",
//   blue: "blue",
//   green: "green",
// };

// export const BackgroundColorContextdos = createContext({
//   color: backgroundColors.blue,
//   changeColor: (color) => {},
// }); 


export const backgroundColors = {
    primary: "primary",
    blue: "blue",
    green: "green",
  };
  
  export const BackgroundColorContext = createContext({
      backgroundColor: backgroundColors.blue,
    handleBgClick: (backgroundColor) => {},
  });