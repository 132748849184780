import React from 'react';
import './Spinner.css'

const Spinner = () => {
    return ( 

        <div className="spinner-box">
            <div className="spinner"></div>
        </div>
            

     );
}
 
export default Spinner;