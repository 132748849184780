import {
    SALDOS_DASH,
    SALDOS_DASH_ERROR
} from '../../types'; 





export default (state, action) => {
            switch(action.type){
                    case SALDOS_DASH:
                        return {
                            ...state,
                            saldos:action.payload,
                        }

                        case SALDOS_DASH_ERROR:
                        return {
                            
                            saldos:null,
                        }




                default:
                    return state;
            }
}