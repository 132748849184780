
import React, { useContext, useState, useEffect, Fragment } from 'react';
import AuthContext from '../../context/autenticacion/authContext'
import NumberFormat from 'react-number-format';
import clienteAxios from '../../config/axios';
import Pagination from '../../views/MicroComponents/Pagination'

const TodosMisDepositos = () => {


    
  const authContext = useContext(AuthContext);
  const { depositos_cantidad,depositos,TodosDepositos, filename, demo_status, retiros, jimf_theme } = authContext;  
    // const [ listadepositos , guardarListaDeposito ]= useState([{
    //     id:'',
    //     fecha:'',
    //     note:'',
    //     currency_tag: '',
    //     amount:'',
    //     estado:'',
      
    //   }]);


    // const Comparacion = (a, b) => {
    //   return a - b
    // }

    // const OrdenarMonto = () => {
    //   const sortedListMonto = [...listadepositos].sort(
    //     Comparacion
    //     )
    //   guardarListaDeposito(sortedListMonto) 
    // }

    

    const OrdenarMonto = () => {
      const sortedListMonto = [...listadepositos].sort((a, b) => (a.monto > b.monto ? 1 : a.monto < b.monto ? -1 : 0))
      guardarListaDeposito(sortedListMonto) 
    }

      const [ listadepositos , guardarListaDeposito ]= useState(depositos);
      const [term, setTerm ] = useState("");

      const [pagina, setPagina] = useState(1)
      const [porPagina, setPorPagina] = useState(10)

      const maximo = listadepositos.length / porPagina

      function searchingTerm(term){
        return function(x){
          return ((x.monto.includes(term)) || 
                  (x.fecha.includes(term)) || 
                  (x.currency_tag.toLowerCase().includes(term)) || 
                  (x.tipo.toLowerCase().includes(term)) || 
                  (x.estado.toLowerCase().includes(term)) || (!term)  
                  )
        }
      }






      //// animacion de fila 
      const [animation, setAnimation] = useState(false);

      
      const [cantidad, guardarCantidades ] = useState(depositos_cantidad);



      
      /////////// busqueda antigua ////////////
      // const  [busqueda  , setBusqueda ] = useState(null);
      
      // const handleChange=e=> {
      //   setBusqueda(e.target.value);
      //     filtrar(e.target.value);
        
      // } 

      // const filtrar = (terminoBusqueda) => {
      //   var resultadoBusqueda=listadepositos.filter((elemento)=>{
      //       if(elemento.estado.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      //       || elemento.fecha.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      //       || elemento.currency_tag.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      //       || elemento.monto.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
      //       || elemento.tipo.toString().toLowerCase().includes(terminoBusqueda.toLowerCase())
            
      //       ){
      //         return elemento;
      //       }
            
      //     });
      //     guardarListaDeposito(resultadoBusqueda);
      // } 

      /////////// busqueda antigua ////////////

      useEffect((e) => {
        
        if(animation === true ) {
          setTimeout(() => {
            setAnimation(false);
        },50);
        }
        
      }, [animation])

      ////
      
    return (  
            <>          

            <div
            className={`${jimf_theme === "white" ? 'busqueda-filter-box-white':'busqueda-filter-box' }`}
            >
              <input
                type="text"
                placeholder="Busqueda por Fecha, Tipo o Monto su movimiento"
                onChange={ e => setTerm(e.target.value)}
                maxLength="20"

              
              />

              <span>
                
                <Pagination
                  pagina={pagina}
                  setPagina={setPagina}
                  maximo={maximo}
                  setAnimation={setAnimation}
                  jimf_theme={jimf_theme}
                />

              </span>

            </div>

                                              <div className="ultra-depositos-todos-head" >
                                                                          
                                                              <div className="fix-responsive-size" > 

                                                              <strong >
                                                              Fecha
                                                              </strong>

                                                              </div>
                                                              <div className=" fix-responsive-size" > 

                                                              <strong className="text-left">
                                                              Información
                                                              </strong>

                                                              </div>
                                                              <div className="hide-responsive" >

                                                              <strong className="line-fix ">
                                                              Moneda
                                                              </strong>

                                                              </div>
                                                              <div className="" > 
                                                              <strong className="line-fix align-items-flex-end" onClick={OrdenarMonto}>
                                                              Monto
                                                              </strong>

                                                              </div>
                                                              <div className="" >

                                                              <strong className="line-fix">
                                                              Estado 
                                                              </strong>

                                                              </div> 
                                              </div>


                                            <div className="scroll-window-jimf-retiro">

                                            


            {listadepositos
            .slice( (pagina -1)* porPagina, (pagina - 1) * porPagina + porPagina)
            .filter(searchingTerm(term))
            .map(listadeposito => (
                                                                                              <>
                                                          

                                                                        <div className={` ${jimf_theme === "white" ? 'ultra-depositos-todos-white':'ultra-depositos-todos' } ${animation === true ? 'animacion-maestra':''}`} 
                                                                          style={{animationDuration:`${listadepositos[listadepositos.length -1 ]}ms`}} 
                                                                        >
                                                                          

                                                                        {/* <div >
                                                                                                  <strong >
                                                                                                  {listadeposito.id}
                                                                                                  </strong>
                                                                                                  </div> */}
                                                                                                  <div className="fix-responsive-size" > 
                                                                                                  
                                                                                                  <strong >
                                                                                                  {listadeposito.fecha}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="text-left fix-responsive-size" > 

                                                                                                  <strong className="">
                                                                                                  {listadeposito.tipo}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="hide-responsive" >

                                                                                                  <strong className="line-fix ">
                                                                                                  {listadeposito.currency_tag}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="" > 
                                                                                                  <strong className="line-fix align-items-flex-end">
                                                                                                  <NumberFormat value={listadeposito.monto}  displayType={'text'} fixedDecimalScale={true} thousandSeparator={true} prefix={'$'} decimalScale={2} toFixed={2}/>
                                                                                                  
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div>
                                                                                                  <div className="text-center" >

                                                                                                  <strong className={` ${listadeposito.estado === 'APROBADO' ? 'green-panther' : ''} ${listadeposito.estado === 'RECHAZADO' ? 'red-panther' : ''} ${listadeposito.estado === 'EN PROCESO' ? 'war-panther' : ''}`}>
                                                                                                  {listadeposito.estado}
                                                                                                  </strong>
                                                                                                  
                                                                                                  </div> 
                                                                            

                                                                        </div>

                                                                                              
                                                                                                    
                                                                                            
                                                 

                                          </>
                                                                                  ) )}

            </div>
            </>



    );
}
 
export default TodosMisDepositos;
