import React, { useContext, useEffect, useState } from "react";
import { Route, Switch, Redirect, useLocation } from "react-router-dom";

// javascript plugin used to create scrollbars on windows
// import PerfectScrollbar from "perfect-scrollbar";

// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import Footer from "components/Footer/Footer.js";
import Sidebar from "components/Sidebar/Sidebar.js";
import UltraSidebar from "components/Sidebar/UltraSidebar.js";
import FixedPlugin from "components/FixedPlugin/FixedPlugin.js";

import routes from "routes.js";
import routespen from "routespen.js";


import logo from "assets/img/react-logo.png";
import { BackgroundColorContext } from "../../context/BackgroundColorContext"; 


import Modal from '@material-ui/core/Modal'
import ModalClick from '@material-ui/core/Modal'
import { makeStyles, withStyles, useTheme } from '@material-ui/core/styles'  

import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade'; 

/// vamos a importar la dependencia de tawkto
import tawkTo from "tawkto-react";


/// aqui vamos a importar el token
import AuthContext from  '../../context/autenticacion/authContext'  

import SaldosContext from  '../../context/saldos/SaldosContext'  


import { 
  Row,
  Col,
  CardBody,
  CardHeader,
  CardFooter,
} from "reactstrap"; 


function getModalStyle() {
  const top = 50 ;
  const left = 50;

  return {
    top: `${top}%`,
    left: `${left}%`,
    transform: `translate(-${top}%, -${left}%)`,
    
    
  };
}


const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'hidden',
 
  background: 'rgba(0, 47, 96, 0.78)',
    
  },
  paper: {
    
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    
  
  },
}));

const useStylesClick = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    overflow:'hidden',
  height:'100%',
  
  background: '#0000005c',
    
  },
  paper: {
    background: '#001c52',
    border: '2px solid #04a1ff',
    boxShadow: theme.shadows[5],
    
    
  },
}));







var ps;

function Cliente(props) { 

  // ahora vamos a llamar al context de los saldos


  
  const saldosContext = useContext(SaldosContext);
   const { saldos, consultarApiSaldo } = saldosContext;    



  // extraer la informacion de autenticacion 
  const authContext = useContext(AuthContext);
    const { usuarioAutenticado, status, CerrarSesion, demo_status, collapse } = authContext;    


    const [ mensaje, guardarMensaje ] = React.useState('');

    const [ modalStyle ] =React.useState(getModalStyle);
    const [open, setOpen] =React.useState(false);
  
    const classes = useStyles();
    
   
    const handleOpen = () => {
        setOpen(true);
    } 
   
    const handleClose = () => {
        setOpen(false);
        guardarMensaje(null);
    }
   


  const location = useLocation();
  const mainPanel = React.useRef(null);
  const [sidebarOpened, setsidebarOpened] = React.useState(
    document.documentElement.className.indexOf("nav-open") !== -1
  ); 




  const handleOpenClick = () => {
    setOpenClick(true);
} 

const handleCloseClick = () => {
    setOpenClick(false);
    
} 

const [openclick, setOpenClick] =useState(true);
const classesclick = useStylesClick();

const [mensajeclick, guardarMensajeClick] =useState(``);






  document.oncontextmenu = function() {
    return false
 }
 function right(e) {
  var msg = "¡Hola aquí no puedes usar Click derecho!";
  if (navigator.appName == 'Netscape' && e.which == 3) {
      handleOpenClick() //- Si no quieres asustar a tu usuario entonces quita esta linea...
      guardarMensajeClick(msg)
     return false;
  }
  else if (navigator.appName == 'Microsoft Internet Explorer' && e.button==2) {
      handleOpenClick() //- Si no quieres asustar al usuario que utiliza IE,  entonces quita esta linea...
                    //- Aunque realmente se lo merezca...
      
      guardarMensajeClick(msg)
  return false;
  }
      return true;
  }
  document.onmousedown = right;  







  // const tawkToPropertyId = '60a52d16185beb22b30ec256'

  // const tawkToKey = '943a9989d8f69ce46aa2305aad6b89d83af0c36e'


 React.useEffect(()=> { 


  // tawkTo(tawkToPropertyId, tawkToKey)

      usuarioAutenticado(); 
      consultarApiSaldo();

      if(status === 1) {
        handleOpen()
      }   

      if(status === 0) {
        localStorage.removeItem('token');
        props.history.push('/login');
      }   

      setTimeout(() => {
      
        CerrarSesion();
    },80400000);
    
  
   }, [status,props.history])


  // React.useEffect(() => { 

    

  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //     });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.classList.add("perfect-scrollbar-off");
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   };
  // });

  ////////////////
  //   React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     document.documentElement.className += " perfect-scrollbar-on";
  //     document.documentElement.classList.remove("perfect-scrollbar-off");
  //     ps = new PerfectScrollbar(mainPanel.current, {
  //       suppressScrollX: true,
  //     });
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   // Specify how to clean up after this effect:
  //   return function cleanup() {
  //     if (navigator.platform.indexOf("Win") > -1) {
  //       ps.destroy();
  //       document.documentElement.classList.add("perfect-scrollbar-off");
  //       document.documentElement.classList.remove("perfect-scrollbar-on");
  //     }
  //   };
  // });

  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }


////////////////////

  // React.useEffect(() => {
  //   if (navigator.platform.indexOf("Win") > -1) {
  //     let tables = document.querySelectorAll(".table-responsive");
  //     for (let i = 0; i < tables.length; i++) {
  //       ps = new PerfectScrollbar(tables[i]);
  //     }
  //   }
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   if (mainPanel.current) {
  //     mainPanel.current.scrollTop = 0;
  //   }
  // }, [location]);

  /////////////////

  // this function opens and closes the sidebar on small devices
  const toggleSidebar = () => {
    (window.scrollTo(0,0))
    document.documentElement.classList.toggle("nav-open");

    setsidebarOpened(!sidebarOpened);
  };


  const toggleSidebarDos = () => {
    (window.scrollTo(0,0))
  };


  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === "/cliente") {
        return (
          <Route
            path={prop.layout + prop.path}
            component={prop.component}
            key={key}
          />
        );
      } else {
        return null;
      }
    });
  };
  const getBrandText = (path) => {
    for (let i = 0; i < routes.length; i++) {
      if (location.pathname.indexOf(routes[i].layout + routes[i].path) !== -1) {
        return routes[i].name;
      }
    }
    return "Brand";
  }; 


  return (
    <BackgroundColorContext.Consumer>
      {({ color, changeColor, backgroundColor, handleBgClick, ...props }) => (
        <React.Fragment>

          
          <div className="wrapper"> 

         
          
          { status === 1 ||    status === 2 ?  <span
                        className={`${collapse === true ? 'd-none' :''}`}
                        > 
                              <Sidebar
              
                              routes={routespen}
                              bgColor={color}
                              logo={{
                                outterLink: "https://www.panther7.com/",
                                walletLink: "http://34.219.66.47/wallet",
                                text: "Panther 7 ",
                                imgSrc: logo
                              }}
                              toggleSidebar={toggleSidebar}
                              {...props}
                            />  </span>:null
                              
                              }  

          { status === 3 ?

                      <span
                      className={`${collapse === true ? 'd-none' :''}`}
                      > 
                              <Sidebar
              
                              routes={routes}
                              bgColor={color}
                              logo={{
                                outterLink: "https://www.panther7.com/",
                                walletLink: "http://34.219.66.47/wallet",
                                text: "Panther 7 ",
                                imgSrc: logo
                              }}
                              toggleSidebar={toggleSidebar}
                              {...props}
                            />
                            
                            </span>:null
                              
                              }  



           
                              
            <div className={ `main-panel main-panther-bg  ${collapse === true ? 'expandnav ' :''} ${demo_status === "active" ? 'main-panther-bg-demo' :'main-panther-bg'}`} ref={mainPanel} data={color}>

              
        
              
              <AdminNavbar
                
                brandText={getBrandText(location.pathname)}
                toggleSidebar={toggleSidebar}
                sidebarOpened={sidebarOpened}
                {...props}
              />

              <div className={`${collapse === true ? 'display-nav ' :'hidde-nav'}`}>


              { status === 3 ?

                   
                            <UltraSidebar
                  
                              routes={routes}
                              bgColor={color}
                              logo={{
                                outterLink: "https://www.panther7.com/",
                                walletLink: "http://34.219.66.47/wallet",
                                text: "Panther 7 ",
                                imgSrc: logo
                              }}
                              toggleSidebarDos={toggleSidebarDos}
                              {...props}
                              />
                                      
                          :null
                            
                            }  


                      { status === 1 ||  status === 2 ? 

                      
                              <UltraSidebar

                                routes={routespen}
                                bgColor={color}
                                logo={{
                                  outterLink: "https://www.panther7.com/",
                                  walletLink: "http://34.219.66.47/wallet",
                                  text: "Panther 7 ",
                                  imgSrc: logo
                                }}
                                toggleSidebarDos={toggleSidebarDos}
                                {...props}
                                />
                                        
                            :null
                              
                              }  





                

                  </div>



                { status === 1 || status === 2 ?  
                             <Switch>
                             {getRoutes(routes)} 
                             <Redirect from="*" to="/cliente/user-profile" />
                           </Switch>:null
                              
                              }  

              
                { status === 3  ?  
                             <Switch>
                             {getRoutes(routes)} 
                             <Redirect from="*" to="/cliente/dashboard" />
                           </Switch>:null
                              
                              }     
              
              {
                // we don't want the Footer to be rendered on map page
                location.pathname === "/admin/maps" ? null : <Footer fluid />
              }
            </div>
          </div>
          
          <FixedPlugin bgColor={color} handleBgClick={changeColor} />

              
        <Modal
                    
                    className={classes.modal}
                    open={open} 
                    mensaje={mensaje}
                    disableScrollLock={true}
                    
                    
                    closeAfterTransition
                    BackdropComponent={Backdrop}
                    BackdropProps={{
                        timeout: 900,
                    }}
                    >
                    <Fade in={open}
                    timeout={154}
                    >
                        <div className="container">
                    <div className="row">
                        <div className="col-12 d-flex justify-content-center">
                        <div  className={`  card ${classes.paper}Modal-inversion-profile modal-power-up-profile`}> 

                        <CardHeader> 
                              
                              <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                              <p className="text-center">¡Esta muy cerca de completar el registro de su cuenta!</p>
                              
                              <hr class="my-1 border-info"/> 
                              
                            </CardHeader>
                        
                        <CardBody className="terminos-modal-fix overflow-hidden">
                                            <Row className="d-flex justify-content-center">
                                                    <Col sm="12" md="11" className=" static-height"> 
                                                    <h2 className="text-center mb-1">Su usuario ha sido creado con éxito</h2>
                                                    <h1 className="text-center mb-1">Bienvenido a su plataforma preferida de inversiones.</h1>
                                                    <h3 className="text-center mb-1 font-weight-900">Por favor complete su información personal para acceder a todas las funcionalidades que le ofrece Panther 7.</h3>
                                                        

                                                    
                                                 
                                                    </Col>       
                                            </Row>
                                        </CardBody> 
                                        <CardFooter>
                            <div className="row">
                                
                            <div  className="col-4">
                                </div>
                                <div  className="col-4">
                                <button className=" btn mt-0  btn-primario btn-block btn-panther-power" onClick={handleClose}>
                                Continuar
                                </button>
                                </div>
                                    
                            <div  className="col-4">
                                </div>
                            </div>
                        </CardFooter>
                                        
                                    
                        

                    </div>

                        </div>

                    </div>
                        </div>
                    </Fade>
                    </Modal> 

                    
            {mensajeclick ?  
                                            
                                            <ModalClick
                                                                        
                                                                        className={classesclick.modal}
                                                                        open={openclick} 
                                                                        mensaje={mensajeclick}
                                                                        disableScrollLock={true}
                                                                        
                                                                        closeAfterTransition
                                                                        BackdropComponent={Backdrop}
                                                                        BackdropProps={{
                                                                            timeout: 900,
                                                                        }}
                                                                        >
                                                                        <Fade in={openclick}
                                                                        timeout={154}
                                                                        onClose={handleCloseClick}>
                                                                            <div className="container">
                                                                        <div className="row">
                                                                        <div className="col-12 d-flex justify-content-center">
                                                                        <div  className={`  card ${classesclick.paper} Modal-inversion modal-power-up`}> 
                                                                        {/*                     
                                                                            <CardHeader> 
                                                                                
                                                                                <h3 className="title display-4 mb-0 text-center">BIENVENIDO </h3>
                                                                                <p className="text-center">¡Estas muy cerca de finalizar el registro de tu cuenta!</p>
                                                                                
                                                                                <hr class="my-1 border-info"/> 
                                                                                
                                                                                </CardHeader> */}
                                                                            
                                                                            <CardBody>
                                                                                                <Row className="d-flex justify-content-center">
                                                                                                        <Col sm="12" md="11" className=" static-height"> 
                                                                                                        
                                                                                                            
                            
                                                                                                            
                                                                                                            <hr className="bg-info"/>
                            
                                                                                                        <h1 className="text-center">{mensajeclick}</h1>
                                                                            
                                                                                                        </Col>       
                                                                                                </Row>
                                                                                            </CardBody> 
                                                                                            <CardFooter className="p-0 pb-4">
                                                                                <div className="row justify-content-center">
                                                                                    
                                                                               
                                                                                    <div  className="col-6">
                                                                                    <button className=" btn mt-0 btn-block btn-primario btn-info btn-panther-power" onClick={ ()=>(
                                                                                            handleCloseClick()
                                                                                            
                                                                                            
                                                                                    )}>
                                                                                    ¡Muchas gracias!, ya no lo vuelvo a hacer!
                                                                                    </button>
                                                                                    </div>
                                                                                        
                                                                              
                                                                                </div>
                                                                            </CardFooter>
                                                                                            
                                                                                        
                                                                            
                            
                                                                        </div>
                            
                                                                            </div>
                            
                                                                        </div>
                                                                            </div>
                                                                        </Fade>
                                                                        </ModalClick>            
                                        : null 
                            
                                        }


        </React.Fragment>
      )}
    </BackgroundColorContext.Consumer>
  );
}

export default Cliente;