import React, { useContext, useState, useEffect, Fragment } from 'react';
import clienteAxios from '../../config/axios';
import NumberFormat from 'react-number-format';
import Mensaje from 'components/Alertas/Mensaje';
import LineSpinner from 'components/spinners/LineSpinner';
import ConoceTuCliente from '../../components/IconsAnimation/ConoceTuCliente';

const CentroVerificacion = ({jimf_theme}) => {

    useEffect(() => {
        window.scrollTo(0, 0)
      }, []) 


                    const [ spin, setSpin ] = useState(false);

                    const [mensaje, guardarMensaje] = useState('')

                    // const { message } = mensaje;  

                    const [mensajeopen, guardarMensajeOpen] = useState(false);


                    const [kyc, guardarKyc] = useState({
        
                        file_id_front:'',
                        file_id_reverse:'',
                        file_selfie:'',
                
                    });  

                    const {file_id_front,
                        file_id_reverse,file_selfie,
                    
                      
                      } = kyc;  
                 
                    const [file, setFile] = useState(undefined);
                
                    const consultarApiKyc = async () => {
                        
                
                          const config = {     
                            headers: { 'content-type': 'multipart/form-data','Accept': 'application/json' },
                            
                            mimeType: 'multipart/form-data',
                        } 
                
                
                        const formData = new FormData();
                       
                
                        formData.append('file_id_front',document.getElementById('file_id_front').files[0],file_id_front);
                        formData.append('file_id_reverse',document.getElementById('file_id_reverse').files[0],file_id_reverse);
                        formData.append('file_selfie',document.getElementById('file_selfie').files[0],file_selfie);
                
                        
                     
                        try {
                            const respuesta = await clienteAxios.post('/api/client/kyc',formData,config);
                
                                  
                                  guardarMensaje(respuesta.data.message);
                                  guardarMensajeOpen(true);
                                  setSpin(false)
                                    setTimeout(() => {
                                        
                                        guardarKyc({
                        
                                            file_id_front:'',
                                            file_id_reverse:'',
                                            file_selfie:'',
                                    
                                        });
                                    },6000);
                            
                        } catch (error) {
                            guardarMensaje(error.response.data.message);
                            guardarMensajeOpen(true);
                            setSpin(false);
                                    
                        }
                
                      
                      
                      
                          
                      
                      
                      
                    } 
                
                


                const onChange = e => {  
                    guardarKyc({
                        ...kyc,
                        [e.target.name] : e.target.value
                        
                    }) 
            
                    setFile(e.target.files[0])
                } 

                const onSubmit = e => {
                    e.preventDefault();
                    setSpin(true)

                    

                            if( kyc.file_id_front.trim() === '' || 
                            kyc.file_selfie.trim() === '' || 
                            kyc.file_id_reverse.trim() === '' 
                            ) {
                                return
                        } 



                        consultarApiKyc({
                            file_id_front,
                            file_id_reverse,
                            file_selfie,
                    
                        }); 
                
                     
                } 


                const Loader = ( spin ) ? <LineSpinner/> :  
                                          
      <Fragment>
        Enviar documentos
        </Fragment> 
  
  


    return ( 

        <>
        
        <div className="window-jimf-retiro box-dual">

                <div className="cuento-deposito">
                        <div className="box-cuento-deposito g-jimf-100">
                        
                       
                        <h1>¿Qué es KYC y para que sirve?</h1>
                        <p>KYC es el nombre que se le da al proceso de verificación de la identidad de un cliente. 
                            Este término, que se está convirtiendo en una expresión común, proviene del sector bancario. 
                            Las instituciones financieras siempre deben poder identificar a sus clientes cuando abren una nueva cuenta.
                            
                        </p> 

                        </div>
                        <div className="box-cuento-deposito cool-left">

                        

                        <h1>Documentos de verificación</h1>

                        <p>         
                            Los objetivos de las directrices de KYC son evitar que los bancos o entidades financieras sean utilizados,
                            intencionalmente o no, por elementos criminales para actividades de lavado de dinero. 
                            Los procedimientos relacionados también permiten a los bancos entender 
                            mejor a sus clientes y sus transacciones financieras.
                        </p>


                        </div>
                        
                </div>

                <div className="form-deposito form-fix">
                

                    <form form onSubmit={onSubmit}>

                    <div className="input-simples-jimf-file">    
                                        { file !== '' ?
                                            <label >
                                            Foto de documento de identidad Cargado <span className="verde-asterisco">*</span>
                                            </label>:<label >
                                            Foto de documento de identidad <span className="red-asterisco">*</span>
                                            </label>

                                        }
                                            <div className="box-class-file">
                                            <input 
                                                    type="file"
                                                    id="file_id_front"
                                                    name="file_id_front"
                                                    onChange={onChange}
                                                    value={kyc.file_id_front} 
                                                    required
                                                    /> 
                                            </div>
                                            
                                            {/* <span>
                                                Haga click para cargar el comprobante bancario. 
                                            </span> */}

                                        </div>

                            <div className="input-simples-jimf-file">    
                                        { file !== '' ?
                                            <label >
                                            Foto de comprobante de residencia Cargado <span className="verde-asterisco">*</span>
                                            </label>:
                                            <label>
                                            Foto de comprobante de residencia <span className="red-asterisco">*</span>
                                            </label>

                                        }
                                            <div className="box-class-file">
                                            <input 
                                                        type="file"
                                                        id="file_id_reverse"
                                                        name="file_id_reverse"
                                                        onChange={onChange}
                                                        value={kyc.file_id_reverse} 
                                                        required
                                                    /> 
                                            </div>

                            </div>

                            <div className="input-simples-jimf-file">    
                                        { file !== '' ?
                                            <label >
                                            Tomar Selfie Cargado <span className="verde-asterisco">*</span>
                                            </label>:
                                            <label>
                                            Tomar Selfie <span className="red-asterisco">*</span>
                                            </label>

                                        }
                                            <div className="box-class-file">
                                            <input 
                                                    type="file"
                                                    id="file_selfie"
                                                    name="file_selfie"
                                                    onChange={onChange}
                                                    value={kyc.file_selfie} 
                                                    required
                                                    /> 
                                            </div>

                            </div>
                           

                                        <button  type="submit" className={`submit-jimf ${spin === true ? 'padd-mad' :''}`}>
                                        {Loader}
                                            </button>

                    


                    </form>

                    <div className="figure-power">
                                <ConoceTuCliente/>

                            </div>

                </div>


                </div>

                <div className="window-select-tipe">
                <h1>Centro de verificación KYC  <i class="tim-icons icon-lock-circle"></i> </h1>
                </div>


                {mensajeopen ? <> 
                    <Mensaje
                            open={true}
                            mensaje={mensaje} 
                            guardarMensajeOpen={guardarMensajeOpen}
                            guardarMensaje={guardarMensaje}
                        /> 
                    
                
                </> : null
                
                }

        </>


     );
}
 
export default CentroVerificacion;